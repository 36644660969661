import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled/macro";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@mui/material";

// icon import
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function Broadcast() {
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const [scheduleDateTime, setScheduleDateTime] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageCaption, setImageCaption] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");

  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4FD1C5", // Change this to your desired focused border color
    },
  }));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSend = () => {
    // console.log("Sending broadcast message:", broadcastMessage);
    {
      /*Console Log Removed Call API for Broadcast message */
    }
    // Call API to send broadcast message
  };

  // const handleSchedule = () => {
  //   setShowModal(true);
  // };

  const handleDateChange = (e) => {
    setScheduleDateTime(e.target.value);
  };

  const handleScheduleSubmit = () => {
    // console.log("Scheduled broadcast message at:", scheduleDateTime);
    // Call API to schedule broadcast message
    setShowModal(false);
  };

  const handleImageChange = (e) => {
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleCaptionChange = (e) => {
    setImageCaption(e.target.value);
  };

  const handleClearImage = () => {
    setSelectedImage(null);
    setImageCaption("");
    document.getElementById("broadcastImage").value = "";
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  const handleSchedule = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginBottom: "0px" }} className="broadcastSection">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="outlined-filter-type"
            select
            label="Filter"
            placeholder="Select Filter"
            defaultValue="MS"
            multiline
            maxRows={4}
            helperText="Please select the filter"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <LockIcon /> */}
                </InputAdornment>
              ),
            }}
            mb={2}
          >
            <MenuItem value="age">Age</MenuItem>
            <MenuItem value="gender">Gender</MenuItem>
            <MenuItem value="country">Country</MenuItem>
          </CustomTextField>
        </Grid>
        <Grid item xs={12} lg={8}>
          <CustomTextField
            id="broadcastMsg"
            name="broadcastMsg"
            label="Broadcast Message"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <PersonIcon /> */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* Filter Section */}
        <Grid item xs={4}>
          {/* <FormControl fullWidth>
            <InputLabel id="filterLabel">Filter</InputLabel>
            <Select
              labelId="filterLabel"
              id="filterSelect"
              label="Filter"
              fullWidth
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="service">Service</MenuItem>
              <MenuItem value="communityService">Community Service</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>

        {/* Broadcast Message Section */}
        <Grid item xs={12}>
          {/* <TextField
            id="broadcastMessage"
            name="broadcastMessage"
            label="Broadcast Message"
            variant="outlined"
            placeholder="Type here..."
            fullWidth
            multiline
            rows={3}
            value={broadcastMessage}
            onChange={(e) => setBroadcastMessage(e.target.value)}
          /> */}
        </Grid>

        {/* Image Upload Section */}
        <Grid item xs={12} lg={4}>
          <FormControl fullWidth>
            {selectedImage && (
              <div style={{ marginTop: 10 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{
                        maxWidth: "-webkit-fill-available",
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: 10 }}>
                      <CustomTextField
                        id="imageCaption"
                        name="imageCaption"
                        label="Image Caption"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                        value={imageCaption}
                        onChange={handleCaptionChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <PersonIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        mb={2}
                      />
                      <Button
                        variant="outlined"
                        onClick={handleClearImage}
                        style={{ marginBottom: 10 }}
                        className="text-capitalize clear-button mt-20px"
                      >
                        Clear
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
            <input
              accept="image/*"
              id="broadcastImage"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <label htmlFor="broadcastImage">
              <Button
                component="span"
                variant="outlined"
                fullWidth
                className="text-capitialize outline-button"
              >
                Upload Image
              </Button>
            </label>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={8}></Grid>

        {/* Buttons Section */}
        <Grid item xs={12} lg={8}></Grid>
        <Grid item xs={6} lg={2}>
          <Button
            variant="outlined"
            onClick={handleSchedule}
            fullWidth
            className="scheduleBtn"
          >
            Schedule
          </Button>
        </Grid>
        <Grid item xs={6} lg={2}>
          <Button
            variant="contained"
            onClick={handleSend}
            fullWidth
            className="blue-greenBtn"
          >
            Send
          </Button>
        </Grid>
      </Grid>

      {/* Schedule Modal */}

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Schedule
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="p-30px">
          <CustomTextField
            id="scheduleDateTime"
            name="scheduleDateTime"
            label="Select Date and Time"
            type="datetime-local"
            value={scheduleDateTime}
            onChange={handleDateChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleScheduleSubmit}
            style={{ padding: "5px" }}
            className="blue-greenBtn"
          >
            Schedule
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowModal(false)}
            style={{ padding: "5px" }}
            className="secondaryButton"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Broadcast;
