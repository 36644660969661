import PersistentDrawerLeft from "../components/drawer";
import PrimaryMenuTabs from "./PrimaryMenuTabs/PrimaryMenuTabs";
import ChatBot from "./ChatBot/ChatBot";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../redux/features/user/userDetails";
import { decryptData } from "../encrypt/encrypt";

function HomePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const storedEncryptedUserDetails = localStorage.getItem("userDetails");
    const decryptedUserDetails = decryptData(storedEncryptedUserDetails);
    dispatch(setUserDetails(decryptedUserDetails));
  }, []);

  return (
    <>
      <div className="homePageSection">
        <PersistentDrawerLeft />

        <div style={{ marginTop: "65px" }} className="mainSectiondiv">
          {/* <DrawerHeader></DrawerHeader> */}
          <PrimaryMenuTabs></PrimaryMenuTabs>
          {/* bot bottom */}
          <ChatBot></ChatBot>
        </div>
      </div>
    </>
  );
}

export default HomePage;
