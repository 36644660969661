import * as React from "react";

import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
  Switch,
  FormControlLabel,
  CardContent,
  Grid,
  Card,
} from "@mui/material";



// tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
};

// add information dialog style
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
// custom textfield style
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

const validationSchema = Yup.object({
  questionName: Yup.string().required("Question Name is Required"),
  questionType: Yup.string().required("Question Type is Required"),

});


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AddRegQuestions = () => {
 
  const [valueLang,setValueLang]=React.useState(0)
  const handleLangChange = (event, newValue) => {
    setValueLang(newValue);
  };
  const handleAddPreviewClose = () => {
    formikEnglish.resetForm()
};
  const formikEnglish = useFormik({
    initialValues: {
        questionName:'',
        questionType:'',
        questionLang:'English',  
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        // console.log(`Values Entered in Add Questions are
        //   question name: ${values.questionName}
        //   question type: ${values.questionType}
        //   question language:${values.questionLang}`)
        handleAddPreviewClose();
    },
});
  return (
    <Card className="formDiv" style={{ minHeight: "598px" }}>
                    <CardContent>
                      <div className="modalLangTabs">
                        <Typography className="formLabel mt-10px">
                          Add Questions
                        </Typography>
                        <Box sx={{ width: "100%" }}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              margin: "20px 0px 30px 0px",
                            }}
                          >
                            <Tabs
                              value={valueLang}
                              onChange={handleLangChange}
                              aria-label="basic tabs example"
                              className="subTabsLangModal d-flex justify-content-between"
                            >
                              <Tab label="English(US)" {...a11yProps(0)} />
                              {/* <Tab label="Russian" {...a11yProps(1)} />
                              <Tab label="Tajik" {...a11yProps(2)} />
                              <Tab label="Persian" {...a11yProps(3)} /> */}
                            </Tabs>
                          </Box>
                          <form onSubmit={formikEnglish.handleSubmit}>
                          <CustomTabPanel value={valueLang} index={0} >
                            
                            <div language="English">
                              <CustomTextField
                                id="questionName"
                                name="questionName"
                                label="Question Name"
                                
                                placeholder="Enter Question Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={formikEnglish.values.questionName}
                                onChange={formikEnglish.handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                                className="mt-10px"
                              />
                              <CustomTextField
                                id="questionType"
                                name="questionType"
                                select
                                label="Question Type"
                                placeholder="Select Question Type"
                                defaultValue=""
                                helperText="Please select the Question type"
                                className="mt-30px"
                                required
                                value={formikEnglish.values.questionType}
                                onChange={formikEnglish.handleChange}
                             
                            
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                              >
                                <MenuItem value="type1">CheckBox</MenuItem>
                                <MenuItem value="type2">Radio</MenuItem>
                                <MenuItem value="type3">Text</MenuItem>
                              </CustomTextField>

                              
                              
                            </div>
                            
                          </CustomTabPanel>
                          <CustomTabPanel value={valueLang} index={1}>
                            <div>
                              <CustomTextField
                                id="questionAddRussianName"
                                label="Question Name"
                                placeholder="Enter Question Name"
                                variant="outlined"
                                fullWidth
                                required
                          
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                                className="mt-10px"
                              />
                              <CustomTextField
                                id="outlined-Add-Russian-type"
                                select
                                label="Question Type"
                                placeholder="Select Question Type"
                                defaultValue=""
                                helperText="Please select the Question type"
                                className="mt-30px"
                 
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                              >
                                <MenuItem value="type1">CheckBox</MenuItem>
                                <MenuItem value="type2">Radio</MenuItem>
                                <MenuItem value="type3">Text</MenuItem>
                              </CustomTextField>
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={valueLang} index={2}>
                            <div>
                              <CustomTextField
                                id="questionAddTajikName"
                                label="Question Name"
                                placeholder="Enter Question Name"
                                variant="outlined"
                                fullWidth
                                required

                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                                className="mt-10px"
                              />
                              <CustomTextField
                                id="outlined-add-Tajik-type"
                                select
                                label="Question Type"
                                placeholder="Select Question Type"
                                defaultValue=""
                                helperText="Please select the Question type"
                                className="mt-30px"
                                required

                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                              >
                                <MenuItem value="type1">CheckBox</MenuItem>
                                <MenuItem value="type2">Radio</MenuItem>
                                <MenuItem value="type3">Text</MenuItem>
                              </CustomTextField>
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={valueLang} index={3}>
                            <div>
                              <CustomTextField
                                id="questionPersianName"
                                label="Question Name"
                                placeholder="Enter Question Name"
                                variant="outlined"
                                fullWidth
                                required

                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                                className="mt-10px"
                              />
                              <CustomTextField
                                id="outlined-Add-Persian-type"
                                select
                                label="Question Type"
                                placeholder="Select Question Type"
                                defaultValue=""
                                helperText="Please select the Question type"
                                className="mt-30px"
                                required
            
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {/* <LockIcon /> */}
                                    </InputAdornment>
                                  ),
                                }}
                                mb={2}
                              >
                                <MenuItem value="type1">CheckBox</MenuItem>
                                <MenuItem value="type2">Radio</MenuItem>
                                <MenuItem value="type3">Text</MenuItem>
                              </CustomTextField>
                            </div>
                          </CustomTabPanel>
                          <div className="mt-30px switchContent">
                            <FormControlLabel
                              className="switchLabel"
                              required
                              control={<Switch />}
                              label="Mandatory"
                            />
                          </div>
                          <div className="d-flex justify-content-end align-items-center mt-30px">
                            <Button
                              variant="contained"
                              className="secondaryButton"
                              style={{ marginRight: "10px" }}
                            >
                              Cancel
                            </Button>
                            <Button autoFocus
                            type="submit"
                             className="blue-greenBtn">
                              Add
                            </Button>
                          </div>
                          </form>
                        </Box>
                      </div>
                    </CardContent>
                  </Card>
  )
}

export default AddRegQuestions
