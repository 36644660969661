export const SampleJson = [
    {
        "Survey Name": "Diabetes Management",
        "Survey Sections": [
            {
                "Survey Section": "General Information",
                "Survey Questions": [
                    {
                        "question": "",
                        "label": "Have you been diagnosed with diabetes?",
                        "type": "Radio",
                        "options": ["Yes", "No"]
                    }
                ]
            }
        ]
    }
]