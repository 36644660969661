const database_config = [
  { id: "db_name", label: "Db name" },
  { id: "connection_string", label: "Connection String" },
  // {"id": "special_regions", "label" : "Special Region"},
  { id: "min_pool_conn", label: "Min Pool Connection" },
  { id: "max_pool_conn", label: "Max pool Connection" },
];

const model_config = [
  { id: "llm_model", label: "LLM Model" },
  { id: "hfembedding_model", label: "Hugging Face embedding model" },
  { id: "sentence_transformer_model", label: "Sentence transformer model" },
];

const embedding_config = [
  { id: "hfembedding_device", label: "HF embedding Device" },
  {
    id: "embedding_node_retrieve_device_usage",
    label: "Embedding node retrieve device",
  },
  {
    id: "embedding_node_retrieve_count",
    label: "Embedding node retrieval count",
  },
  {
    id: "embedding_retriever_similarity_top",
    label: "Embedding retrieval similarit top ",
  },
];

const chunk_config = [
  { id: "chunk_size", label: "Chunk Size" },
  { id: "chunk_overlap", label: "Chunk Overlap" },
  { id: "max_batch_size", label: "Max batch size" },
];

const temperature_details = [
  { id: "rag_temp", label: "RAG temperature" },
  { id: "type_temp", label: "Type checking temperature" },
  { id: "sql_temp", label: "SQL temperature" },
  { id: "translation_temp", label: "Translation temperrature" },
];

const token_details = [
  { id: "info_call", label: "Info call token" },
  { id: "generate_new_questions", label: "Generate new question token" },
  { id: "chat_history_query", label: "Chat history question token" },
  { id: "string_translator", label: "String translation token" },
];

const system_details = [
  { id: "llm_url_dev", label: "LLM Url Dev" },
  { id: "llm_url_prod", label: "LLM Url Production" },
  { id: "external_folder", label: "Bot folder name" },
  { id: "collection", label: "Embedding collection name" },
  { id: "envir", label: "Environment" },
];

const initialValues = {
  db_name: "cdic_latest",
  connection_string: "postgresql://postgres:root@localhost:5432/",
  special_regions: [],
  min_pool_conn: 1,
  max_pool_conn: 100,
  rag_temp: 0.2,
  type_temp: 0.2,
  sql_temp: 0.01,
  translation_temp: 0.01,
  VllmServer: 200,
  info_call: 5000,
  generate_new_questions: 1000,
  chat_history_query: 1000,
  string_translator: 1000,
  chunk_size: 150,
  chunk_overlap: 20,
  max_batch_size: 20,
  embedding_node_retrieve_count: 10,
  embedding_node_retrieve_device_usage: "cpu",
  "embedding_retriever_similarity_top ": 20,
  hfembedding_device: "cpu",
  llm_model: "meta-llama/Meta-Llama-3-8B-Instruct",
  hfembedding_model: "BAAI/bge-base-en-v1.5",
  sentence_transformer_model: "cross-encoder/ms-marco-MiniLM-L-2-v2",
  llm_url_dev: "https://llm.imonitorplus.com/llm/v1/completions",
  llm_url_prod: "http://localhost:8000/v1/completions/",
  external_folder: "file_upload",
  collection: "cdic_doc",
  envir: "settings.dev",
};

const config = {
  database_config: database_config,
  model_config: model_config,
  chunk_config: chunk_config,
  token_details: token_details,
  temperature_details: temperature_details,
  initialValues: initialValues,
  system_details: system_details,
  embedding_config: embedding_config,
};

// src/configs/ChatbotConfig.js
const Chatconfig = {
  botName: "MyChatbot", // Specify your chatbot's name
  initialMessages: [
    { type: "text", content: "Hello! How can I assist you today?" },
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#376B7E",
    },
  },
};

export default config;
