export const defaultFields = [
    {
        data: {
            question_name: 'Age',
            language_id: "1",
            mandatory: true,
            question_type: 'Radio',
            question_option: [
                {"options_names": "0 - 15"},
                {"options_names": "15 - 18"},
                {"options_names": "> 18"}
            ],
          }
    },

    {
        data: {
            question_name: 'Gender',
            language_id: "1",
            mandatory: true,
            question_type: 'Radio',
            question_option: [
                {"options_names": "Male"},
                {"options_names": "Female"},
                {"options_names": "Other"}
            ],
          }
    },

    {
        data: {
            question_name: 'Location',
            language_id: "1",
            mandatory: true,
            question_type: 'Radio',
            question_option: [
                {"options_names": "Value1"},
                {"options_names": "Value2"},
                {"options_names": "Value3"}
            ],
          }
    }
]