import React from "react";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Button } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import imgUrl from "../img/imgUrl"; // Adjust the import as needed
import Swal from "sweetalert2";
import { post } from "../apiServices/api";
import axios from "axios";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

function Signup() {
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setEmailError(!validateEmail(emailValue));
  };

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  function registerUser() {
    const apiUrl =
      "https://cb8a-103-252-7-235.ngrok-free.app/service/v1.0/user/registeruser";
    const requestData = {
      userName: "Vijay",
      email: "vijay@mailinator.com",
    };

    axios
      .post(apiUrl, requestData)
      .then((response) => {
        // console.log('Registration successful:', response.data);
        {
          /*Insert Swal Alert */
        }
        // Handle successful registration here, if needed
      })
      .catch((error) => {
        // console.error("Registration failed:", error);
        {
          /*Insert Swal Alert */
        }
        // Handle error
      });
  }

  const submitSignUp = async (loginDetails) => {
    try {
      const response = await post(`user/registeruser`, loginDetails);
      if (response.status === 200) {
        Swal.fire({
          title: "",
          text: response?.data?.status,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      } else {
        Swal.fire("Error", "Failed to fetch data", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const handleSubmit = () => {
    // Check if email is valid before submitting
    if (!username || !validateEmail(email)) {
      setEmailError(true);
      return;
    }
    // registerUser()
    submitSignUp({
      userName: username,
      email: email,
    });
    setEmailError(false);
    // Handle your submit logic, e.g., navigate to the next page
    // navigate("/homepage");
  };

  return (
    // main page section sign up
    <div className="loginpage">
      <Grid container spacing={2} className="m-0">
        <Grid item xs={1} sm={0} lg={0} md={0} className="pl-0px"></Grid>
        <Grid item xs={12} sm={4} lg={4} md={4} className="pl-0px">
          <div className="logincard">
            <Grid container spacing={2} className="m-0">
              <Grid item xs={12} className="pl-0px pt-0px">
                <p className="text-center mt-0px welcomeLabel mb-10px">
                  <img
                    src={imgUrl.smartIcon}
                    className="mx-4px logo-main"
                    alt="Smart Icon"
                  />
                  Welcome to Vital Assistance{" "}
                </p>
                <p className="logincardtitle text-center">Sign Up</p>
                <div>
                  <div>
                    <CustomTextField
                      id="username"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={username}
                      onChange={handleUsernameChange}
                      InputProps={{
                        startAdornment: <AccountCircleOutlinedIcon />,
                      }}
                      required
                    />
                  </div>
                  <div className="mt-30px">
                    <CustomTextField
                      id="email"
                      label="Email Address"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={handleEmailChange}
                      error={emailError}
                      helperText={
                        emailError ? "Enter a valid email address" : ""
                      }
                      InputProps={{
                        startAdornment: <EmailOutlinedIcon />,
                      }}
                      required
                    />
                  </div>

                  <div>
                    {/* {emailError && (
                      <p className="error-message" style={{ color: "red" }}>
                        Enter a valid email address
                      </p>
                    )} */}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className="loginbtn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                  <div>
                    <p className="mb-0 fa-12px color-primary-black">
                      Already Have an Account?
                      <span
                        className="main-color cursor-pointer"
                        onClick={() => navigate("/")}
                      >
                        {" "}
                        Sign In
                      </span>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={0} sm={6} lg={8} md={6}>
          {/* <div className="loginpgimg"></div> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default Signup;
