// src/axiosService.js

import axios from "axios";

// const API_BASE_URL_DJANGO = "http://127.0.0.1:8000/";
const API_BASE_URL_DJANGO = "https://llm.imonitorplus.com/vitalai/";
const API_BASE_URL = "https://apivital.imonitorplus.com/service/v1.0/";
const API_BASE_URL_STRAPI = "https://cmsvital.imonitorplus.com/api"

const AUTH_TOKEN = "7cddf608e4805303cd1e601ac5d1a5d97a1a4832";

// Create an instance of axios with default settings
const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Replace with your API's base URL
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Token ${AUTH_TOKEN}`,
    // Add any other default headers here
  },
});

// Function to handle GET requests
export const get = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

// Function to handle POST requests
export const post = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

// Function to handle PUT requests
export const put = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

// Function to handle DELETE requests
export const del = async (url, params = {}) => {
  try {
    const response = await axiosInstance.delete(url, { params });
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};

// Function to handle form data POST requests
export const postFormData = async (url, formData) => {
  try {
    const response = await axiosInstance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("POST form data request error:", error);
    throw error;
  }
};

const axiosInstanceDjango = axios.create({
  baseURL: API_BASE_URL_DJANGO, // Replace with your API's base URL
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${AUTH_TOKEN}`,
    // Add any other default headers here
  },
});

// Function to handle GET requests
export const getDjango = async (url, params = {}) => {
  try {
    const response = await axiosInstanceDjango.get(url, { params });
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

// Function to handle POST requests
export const postDjango = async (url, data) => {
  try {
    const response = await axiosInstanceDjango.post(url, data);
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

// Function to handle PUT requests
export const putDjango = async (url, data) => {
  try {
    const response = await axiosInstanceDjango.put(url, data);
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

// Function to handle DELETE requests
export const delDjango = async (url, params = {}) => {
  try {
    const response = await axiosInstanceDjango.delete(url, { params });
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};

// Function to handle form data POST requests
export const postFormDataDjango = async (url, formData) => {
  try {
    const response = await axiosInstanceDjango.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("POST form data request error:", error);
    throw error;
  }
};

const axiosInstanceStrapi = axios.create({
  baseURL: API_BASE_URL_STRAPI, // Replace with your API's base URL
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiaWF0IjoxNzIzMzk2MTcxLCJleHAiOjE3MjU5ODgxNzF9.0TDGNiiUWS2NN4PA927_Fq9nvlrk3logNRXlflFZzC0`,
    // Add any other default headers here
  },
});

// Function to handle GET requests
export const getStrapi = async (url, params = {}) => {
  try {
    const response = await axiosInstanceStrapi.get(url, { params });
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

// Function to handle POST requests
export const postStrapi = async (url, data) => {
  try {
    const response = await axiosInstanceStrapi.post(url, data);
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

// Function to handle PUT requests
export const putStrapi = async (url, data) => {
  try {
    const response = await axiosInstanceStrapi.put(url, data);
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

// Function to handle DELETE requests
export const delStrapi = async (url, params = {}) => {
  try {
    const response = await axiosInstanceStrapi.delete(url);
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};

// Function to handle form data POST requests
export const postFormDataStrapi = async (url, formData) => {
  try {
    const response = await axiosInstanceStrapi.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("POST form data request error:", error);
    throw error;
  }
};
