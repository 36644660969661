import * as React from "react";
import styled from "@emotion/styled/macro";
import {
  Box,
  InputAdornment,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";
import DatabaseTableList from "../DatabaseSteps/DatabaseTableList/DatabaseTableList";
import imgUrl from "../../../img/imgUrl";
// Custom textfield UI
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Review & Confirm",
  "Finish",
];

export default function DatabaseSteps() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => step === 1; // Only the second step is optional

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Content for each step
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Typography className="mt-30px">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <CustomTextField
                  name="DataSet Name"
                  id="datasetName"
                  label="DataSet Name"
                  variant="outlined"
                  placeholder="Enter DataSet Name"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomTextField
                  name="dataSet_type"
                  id="dataSet_type"
                  select
                  label="DataSet Type"
                  placeholder="Select DataSet Type"
                  defaultValue=""
                  helperText="Please select the dataSet type"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <EmailIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                >
                  <MenuItem value="1">PostGress</MenuItem>
                  <MenuItem value="2">SQL</MenuItem>
                </CustomTextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-10px">
              <Grid item xs={12} lg={12}>
                <CustomTextField
                  name="url"
                  id="url"
                  label="URL"
                  variant="outlined"
                  placeholder="URL"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-10px">
              <Grid item xs={12} lg={6}>
                <CustomTextField
                  name="username"
                  id="username"
                  label="Username"
                  variant="outlined"
                  placeholder="Enter Username"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomTextField
                  name="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  placeholder="Enter Password"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
              </Grid>
            </Grid>
            <div className="mt-20px d-flex justify-content-end">
              <Button variant="contained" className="blue-greenBtn">
                Host
              </Button>
            </div>
          </Typography>
        );
      case 1:
        return (
          <Typography>
            <div>
              <DatabaseTableList />
            </div>
            <div className="mt-20px d-flex justify-content-end">
              <Button variant="contained" className="blue-greenBtn">
                Submit
              </Button>
            </div>
          </Typography>
        );
      case 2:
        return (
          <Typography className="stepThree">
            <div className="metaDataTable">
              <Grid container className="tableHead">
                <Grid item xs={5}>
                  Label Head
                </Grid>
                <Grid item xs={5}>
                  TextField Head
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <div className="bgMainColorData">
                <div className="">
                  <div className="d-flex justify-content-start align-items-center bg-color">
                    <div className="left-grid">
                      <p>Label</p>
                    </div>
                    <div className="right-grid">
                      <CustomTextField
                        label=""
                        placeholder=""
                        multiline
                        rows={2} // Number of visible text lines
                        variant="outlined" // You can also use "filled" or "standard"
                        fullWidth // Optional: makes the text field take up the full width of its container
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-start align-items-center bg-color">
                    <div className="left-grid">
                      <p>Label</p>
                    </div>
                    <div className="right-grid">
                      <CustomTextField
                        label=""
                        placeholder=""
                        multiline
                        rows={2} // Number of visible text lines
                        variant="outlined" // You can also use "filled" or "standard"
                        fullWidth // Optional: makes the text field take up the full width of its container
                      />
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div className="d-flex justify-content-start align-items-center bg-color">
                    <div className="left-grid">
                      <p>Label</p>
                    </div>
                    <div className="right-grid">
                      <CustomTextField
                        label=""
                        placeholder=""
                        multiline
                        rows={2} // Number of visible text lines
                        variant="outlined" // You can also use "filled" or "standard"
                        fullWidth // Optional: makes the text field take up the full width of its container
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-start align-items-center bg-color">
                    <div className="left-grid">
                      <p>Label</p>
                    </div>
                    <div className="right-grid">
                      <CustomTextField
                        label=""
                        placeholder=""
                        multiline
                        rows={2} // Number of visible text lines
                        variant="outlined" // You can also use "filled" or "standard"
                        fullWidth // Optional: makes the text field take up the full width of its container
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </Typography>
        );
      case 3:
        return (
          <Typography className="mt-30px verifySection">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={2}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Grid item xs={12} lg={10}>
                    <CustomTextField
                      name="Enter Question"
                      id="questionData"
                      label="Enter Question"
                      variant="outlined"
                      placeholder="Enter Question"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <PersonIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                      mb={2}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      variant="contained"
                      className="blue-greenBtn sendBtn"
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-20px">
                  <Grid item xs={12} lg={10}>
                    <CustomTextField
                      name="responseTemplate_type"
                      id="responseTemplate_type"
                      select
                      label="Response Template"
                      placeholder="Select Response Template Type"
                      defaultValue="1"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <EmailIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                      mb={2}
                    >
                      <MenuItem value="1" disabled className="placeholderItem">
                        <span className="placeholderItem">
                          {" "}
                          Please Select Response Template
                        </span>
                      </MenuItem>
                      <MenuItem value="2">Text (Default)</MenuItem>
                      <MenuItem value="3">JSON</MenuItem>
                      <MenuItem value="4">Tabular (CSV)</MenuItem>
                      <MenuItem value="5">User-Defined</MenuItem>
                    </CustomTextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-20px">
                  <Grid item xs={12} lg={10}>
                    <CustomTextField
                      label=""
                      placeholder="Define Template"
                      multiline
                      rows={2}
                      variant="outlined"
                      fullWidth
                      className=""
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="responseField mt-20px d-flex justify-content-start align-items-center"
                >
                  <Grid item xs={12} lg={10}>
                    <CustomTextField
                      label=""
                      placeholder="Response"
                      multiline
                      rows={2}
                      variant="outlined"
                      fullWidth
                      className=""
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      variant="contained"
                      className="blue-greenBtn noBgColor"
                    >
                      <img src={imgUrl.verifyIcon} alt="verify icon" />
                      Verified
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
          </Typography>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Box sx={{ width: "100%" }} className="">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset} className="stepperMainButton">
              Reset
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext} className="stepperMainButton">
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
