import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CmsNearMeFileTable from "./cmsnearmefiletable";
import { post } from "../../apiServices/api";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import NearMeMap from "./NearMeMap";
// Custom TextField styled component
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
  latitude: Yup.number()
    .required("Latitude is required")
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),
  longitude: Yup.number()
    .required("Longitude is required")
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
});

const CMSNearMe = () => {
  const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);
  const botId = useSelector((state) => state?.bot?.botDetails?.botid);
  const [allNearMes, setAllNearMes] = React.useState([]);
  const [formEditState, setFormEditState] = React.useState(false);
  const initVal = {
    name: "",
    type: "",
    latitude: "",
    longitude: "",
    country: "",
    state: "",
    id: null,
  };
  const [formikInitialValues, setFormikInitialValues] = React.useState(initVal);
  React.useEffect(() => {
    fetchAllNearMeData();
  }, [botId, userId]);

  React.useEffect(() => {
    // console.log(`In CMS Near Me inital values are `,formikInitialValues)
  }, [formikInitialValues]);

  const fetchAllNearMeData = async () => {
    //&filters[is_header][$eq]=true
    try {
      const response = await post("cms/getCms", {
        url: `/near-mes?populate=*&filters[bot_id][$eq]=${botId}`,
      });
      if (response.data) {
        // console.log(`All Response is `,response.data)
        setAllNearMes(response.data);
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };
  const addNearMeLocation = async (values) => {
    // console.log(`Location Details are `,values)
    const payload = {
      data: {
        name: values.name,
        type: values.type,
        latitude: values.latitude,
        longitude: values.longitude,
        country: values.country,
        state: values.state,
        user_id: userId.toString(),
        bot_id: botId.toString(),
      },
    };
    try {
      let response;
      let payloadJson = {
        url: formEditState ? `/near-mes/${values?.id}` : `/near-mes`,
        payload: payload,
      };
      {
        formEditState
          ? (response = await post(`/cms/putCms`, payloadJson))
          : (response = await post(`/cms/postCms`, payloadJson));
      }
      if (response.data != null) {
        // console.log("response ",response)
        fetchAllNearMeData();
        {
          formEditState
            ? Swal.fire("", "Near Me Edited Successfully", "success")
            : Swal.fire("", "Near Me Added Successfully", "success");
        }
      } else {
        //   console.log("else", response);
        Swal.fire("Error", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
    //After Add or Edit Reset the Initial Formik State to initVal and Clear the form
    setFormikInitialValues(initVal);
    setFormEditState(false);
  };
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      addNearMeLocation(values);
      formik.resetForm();
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle file drop
    },
  });

  return (
    <div>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed grey",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
        className="dragSection"
      >
        <input {...getInputProps()} />
        <CloudUploadIcon
          sx={{
            fontSize: 50,
            color: "grey",
          }}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Drag & Drop files here
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          or
        </Typography>
        <Button
          variant="contained"
          component="span"
          sx={{ mt: 2 }}
          className="blue-greenBtn text-capitalize"
        >
          Browse Files
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <Card className="mt-20px" style={{ minHeight: "100%" }}>
            <CardContent style={{ overflow: "hidden" }}>
              <form onSubmit={formik.handleSubmit}>
                <CustomTextField
                  name="name"
                  id="name"
                  label="Name"
                  variant="outlined"
                  placeholder="Enter Name"
                  fullWidth
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  className="mt-30px"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
                <CustomTextField
                  name="type"
                  id="type"
                  label="Type"
                  variant="outlined"
                  placeholder="Enter Type"
                  fullWidth
                  required
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                  className="mt-30px"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
                <CustomTextField
                  name="latitude"
                  id="latitude"
                  label="Latitude"
                  variant="outlined"
                  placeholder="Enter Latitude"
                  fullWidth
                  required
                  value={formik.values.latitude}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.latitude && Boolean(formik.errors.latitude)
                  }
                  helperText={formik.touched.latitude && formik.errors.latitude}
                  className="mt-30px"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
                <CustomTextField
                  name="longitude"
                  id="longitude"
                  label="Longitude"
                  variant="outlined"
                  placeholder="Enter Longitude"
                  fullWidth
                  required
                  value={formik.values.longitude}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.longitude && Boolean(formik.errors.longitude)
                  }
                  helperText={
                    formik.touched.longitude && formik.errors.longitude
                  }
                  className="mt-30px"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
                <CustomTextField
                  name="country"
                  id="country"
                  label="Country"
                  variant="outlined"
                  placeholder="Enter Country"
                  fullWidth
                  required
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                  className="mt-30px"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
                <CustomTextField
                  name="state"
                  id="state"
                  label="State"
                  variant="outlined"
                  placeholder="Enter State"
                  fullWidth
                  required
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  className="mt-30px"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
                <div className="d-flex justify-content-end align-items-center mt-30px">
                  <Button autoFocus className="blue-greenBtn" type="submit">
                    {formEditState ? "Edit" : "Add"}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Card className="mt-20px" style={{ minHeight: "100%" }}>
            <CardContent style={{ overflow: "hidden" }}>
              <CmsNearMeFileTable
                allNearMes={allNearMes}
                fetchAllNearMeData={fetchAllNearMeData}
                setFormikInitialValues={setFormikInitialValues}
                setFormEditState={setFormEditState}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-10px">
        <Grid item xs={12} lg={12}>
          <Card className="mt-20px">
            <CardContent>
              <div className="">
                <NearMeMap></NearMeMap>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CMSNearMe;
