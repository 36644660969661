import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import { Edit, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreviewBotID,
  clearPreviewBotID,
} from "../../../redux/features/create_bot/botDetails";
//Custom Radio Group Cell

const RadioButtonRenderer = ({ id, selectedValue, handleChange }) => (
  <Radio
    checked={selectedValue === id}
    onChange={() => handleChange(id)}
    value={id}
    name="radio-button-demo"
    inputProps={{ "aria-label": id }}
  />
);

// const rows = [
//   { id: 1, TypeofBot: "Snow", botName: "Jon", botdescription: 35 },
//   { id: 2, TypeofBot: "Lannister", botName: "Cersei", botdescription: 42 },
//   { id: 3, TypeofBot: "Lannister", botName: "Jaime", botdescription: 45 },
//   { id: 4, TypeofBot: "Lannister", botName: "Jaime", botdescription: 45 },
// ];

export default function PreviewPageTable() {
  const [rows, setRows] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const dispatch = useDispatch();
  const handleRadioChange = (id) => {
    setSelectedValue(id);
  };
  // console.log(`Selected Value is ${selectedValue}`);
  let allRows = useSelector((state) => state?.bot?.allBotDetailList);

  React.useEffect(() => {
    dispatch(clearPreviewBotID());

    // Optionally, you can reset on unmount as well
    return () => {
      dispatch(clearPreviewBotID());
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(setPreviewBotID(selectedValue));
    let tempRows = allRows.map((r) => ({
      id: r.botid,
      TypeofBot: r.type,
      botdescription: r.description,
      botName: r.name,
    }));
    setRows(tempRows);
  }, [allRows, selectedValue]);

  const columns = [
    //Custom Radio Button Coloumn
    {
      field: "radio",
      headerName: "Select",
      width: 100,
      renderCell: (params) => (
        <RadioButtonRenderer
          id={params.row.id}
          selectedValue={selectedValue}
          handleChange={handleRadioChange}
        />
      ),
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "botName", headerName: "Bot name", width: 210 },
    { field: "TypeofBot", headerName: "Type of Bot", width: 210 },
    {
      field: "botdescription",
      headerName: "Bot Description",
      width: 210,
    },
    {
      field: "BotIcon",
      headerName: "Bot Icon",
      sortable: false,
      width: 280,
      valueGetter: (value, row) =>
        `${row.botName || ""} ${row.TypeofBot || ""}`,
    },
    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   sortable: false,
    //   width: 150,
    //   renderCell: (params) => (
    //     <div className="d-flex">
    //       <Edit
    //         style={{
    //           cursor: "pointer",
    //           marginRight: 10,
    //           color: "#5ecec1",

    //           backgroundColor: "#dcf5f2",
    //           borderRadius: "50px",
    //           padding: "6px",
    //           fontSize: "30px",
    //         }}
    //       />
    //       <Delete
    //         style={{
    //           cursor: "pointer",
    //           color: "#5ecec1",

    //           backgroundColor: "#dcf5f2",
    //           borderRadius: "50px",
    //           padding: "6px",
    //           fontSize: "30px",
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];
  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="botCreationTable"
    >
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight={false}
          pageSize={5}
          style={{ width: "100%" }}
          sx={{
            "& .MuiDataGrid-root": {
              backgroundColor: "#ffffff",
              // Prevents scrolling on the entire grid, only the body scrolls
              "& .MuiDataGrid-viewport": {
                overflow: "auto",
              },
              // Ensures pagination is always visible
              "& .MuiDataGrid-footer": {
                position: "sticky",
                bottom: 0,
                zIndex: 1,
              },
            },
          }} // Override background color for DataGrid
        />
      </div>
    </div>
  );
}
