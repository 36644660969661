import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete, Refresh } from "@mui/icons-material";
import Swal from "sweetalert2";
import { postDjango } from "../../apiServices/api";

export default function CmsUploadFileTable({ rows, fetchData, bot_id }) {
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {field:"bot_id",headerName:"Bot ID",width:70},
    {
      field: "fileName",
      headerName: "File Name",
      width: 150,
      renderCell: (params) => <div style={cellStyle}>{params.value}</div>,
    },
    {
      field: "fileDescription",
      headerName: "File Description",
      width: 150,
      renderCell: (params) => <div style={cellStyle}>{params.value}</div>,
    },
    {
      field: "fileType",
      headerName: "File Type",
      width: 150,
      renderCell: (params) => <div style={cellStyle}>{params.value}</div>,
    },
    {
      field: "JobId",
      headerName: "Job ID",
      // sortable: false,
      width: 130,
      renderCell: (params) => <div style={cellStyle}>{params.value}</div>,
      // valueGetter: (value, row) => `${row.botName || ""} ${row.TypeofBot || ""}`,
    },
    {
      field: "Status",
      headerName: "Status",
      // sortable: false,
      width: 130,
      renderCell: (params) => <div style={cellStyle}>{params.value}</div>,
      // valueGetter: (value, row) => `${row.botName || ""} ${row.TypeofBot || ""}`,
    },
    {
      field: "CreatedAt",
      headerName: "Created At",
      // sortable: false,
      width: 140,
      renderCell: (params) => <div style={cellStyle}>{params.value}</div>,
    },
    {
      field: "UpdatedAt",
      headerName: "Updated At",
      // sortable: false,
      width: 140,
      renderCell: (params) => <div style={cellStyle}>{params.value}</div>,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        const onDelete = async () => {
          // console.log("delete paramas", params);
          try {
            const response = await postDjango(`deleteuploadedentry/`, {
              id: params.id,
              bot_id: "Bot" + bot_id,
            });
            if (response.status === 200) {
              fetchData();

              Swal.fire({
                title: "",
                text: "Embedding deleted successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire("Error", "Failed to fetch data", "error");
            }
          } catch (error) {
            Swal.fire("Error", error.message, "error");
          }
        };

        const onRefresh = async () => {
          fetchData();
        };

        return (
          <div className="d-flex">
            <Refresh
              onClick={onRefresh}
              style={{
                cursor: "pointer",
                marginRight: 10,
                color: "#5ecec1",
                backgroundColor: "#dcf5f2",
                borderRadius: "50px",
                padding: "6px",
                fontSize: "30px",
              }}
            />
            {params?.row?.Status == "processed" && (
              <Delete
                onClick={onDelete}
                style={{
                  cursor: "pointer",
                  color: "#5ecec1",
                  backgroundColor: "#dcf5f2",
                  borderRadius: "50px",
                  padding: "6px",
                  fontSize: "30px",
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const cellStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  };
  console.log(`CMS UPLOAD TABLE EMBEDDINGS are`,rows);
  const cmsRow = rows.map((r) => ({
    bot_id:r.fields.bot_id,
    id: r.pk,
    fileName: r.fields.file_name,
    fileDescription: r.fields.description,
    fileType: r.fields.file_type,
    JobId: r.fields.job_id,
    Status: r.fields.status,
    CreatedAt: r.fields.created_at,
    UpdatedAt: r.fields.updated_at,
  }));

  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="botCreationTable"
    >
      <DataGrid
       initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        }}}
        rows={cmsRow}
        columns={columns}
        autoHeight
        pageSize={5}
        // checkboxSelection
        style={{ width: "100%" }}
        sx={{ "& .MuiDataGrid-root": { backgroundColor: "#ffffff" } }} // Override background color for DataGrid
      />
    </div>
  );
}
