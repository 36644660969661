import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import imgUrl from '../img/imgUrl';
import { AccountCircle } from '@mui/icons-material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useSelector, useDispatch } from 'react-redux';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true); // Initialize open to true
  const { logout } = useContext(AuthContext);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const matches = useMediaQuery('(max-width:1200px)');
  const userName = useSelector((state) => state?.user?.userDetails?.user?.username);
  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <span
            onClick={() => navigate('/')}
            className="d-flex justify-contents-start align-items-center"
          >
            <img src={imgUrl.smartIcon} className="mx-4px logo-main"></img>
            <Typography className="mainHeading">Vital Assistance</Typography>
          </span>
          <div className="ml-auto d-flex align-items-center">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleMenu}
              color="inherit"
              className="profileButton cursor-pointer"
            >
              <AccountCircle />
              <span className="profileUser">{userName}</span>
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current logout"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                logout();
              }}
              color="inherit"
              className="profileButton cursor-pointer pr-0px"
            >
              <LogoutOutlinedIcon className="cursor-pointer"></LogoutOutlinedIcon>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      
    </>
  );
}
