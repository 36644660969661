import * as React from "react";
import { styled } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  InputLabel,
  TableContainer,
  Tab,
  Tabs,
  Radio,
  RadioGroup,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Link from "@mui/material/Link";

// icons import
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

// files import
import imgUrl from "../../img/imgUrl";
import SecondaryMenuTabs from "../SecondaryMenuTabs/SecondaryMenuTabs";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PrimaryMenuTabs() {
  // custom textfield theming
  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4FD1C5", // Change this to your desired focused border color
    },
  }));

  // const [selectedValue, setSelectedValue] = React.useState("a");
  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // radio buttons selector
  const [selectedValue, setSelectedValue] = useState("");
  // Handler for radio button clicks
  const handleRadioChange = (event) => {
    setSelectedValue(
      event.target.value === selectedValue ? "" : event.target.value
    );
  };

  return (
    <Box sx={{ width: "100%" }} className="mainTabsForm">
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginRight: "26px" }}
        className="boxDivider"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={<span className="TabLabel">Dashboard</span>}
            icon={<DashboardOutlinedIcon className={"IconSize"} />}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            className="flex-direction-row"
            {...a11yProps(0)} // Assuming a11yProps(0) provides the necessary props for accessibility
          />
          <Tab
            label={<span className="TabLabel">SetUp</span>}
            {...a11yProps(1)}
            className="flex-direction-row"
            icon={<PsychologyOutlinedIcon className={"IconSize"} />}
          />
        </Tabs>
      </Box>
      <div className="text-left customSummaryTabs">
        {/* first tab section */}
        <CustomTabPanel value={value} index={0}>
          <div className="summarySection">
            <div className="summaryCard">
              <Box mt={2} mx="auto" className="summaryBox">
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8} className="pl-0px">
                    <div className="botTable">
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead className="bg-light-blue">
                            <TableRow className="tableHeading">
                              <TableCell className="w-50 d-flex align-items-center">
                                <InputLabel className="d-flex justify-content-start align-items-center">
                                  <SmartToyOutlinedIcon></SmartToyOutlinedIcon>
                                  <span className="ml-8px">
                                    Name of the Bot{" "}
                                  </span>
                                </InputLabel>
                              </TableCell>
                              <TableCell className="w-50 d-flex justify-content-start align-items-center">
                                <InputLabel className="d-flex justify-content-start align-items-center">
                                  <DateRangeOutlinedIcon></DateRangeOutlinedIcon>
                                  <span className="ml-8px d-flex justify-content-start align-items-center">
                                    Created Date
                                  </span>
                                </InputLabel>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow className="bg-white">
                              <TableCell>
                                <FormControlLabel
                                  value="amr"
                                  control={<Radio />}
                                  label="AMR ChatBot"
                                  checked={selectedValue === "amr"}
                                  onChange={handleRadioChange}
                                />
                              </TableCell>
                              <TableCell>30/06/2024</TableCell>
                            </TableRow>
                            <TableRow className="bg-striped-gray">
                              <TableCell>
                                <FormControlLabel
                                  value="tb"
                                  control={<Radio />}
                                  label="TB ChatBot"
                                  checked={selectedValue === "tb"}
                                  onChange={handleRadioChange}
                                />
                              </TableCell>
                              <TableCell>30/06/2024</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="cardsDashboardSummary">
                      <Grid container spacing={2} className="mt-10px">
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card>
                            <CardContent>
                              <Typography className="main-Heading">
                                Total Users Onboarded
                              </Typography>
                              <Typography>7</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card className="bg-card-blue-even">
                            <CardContent>
                              <Typography className="main-Heading">
                                Registered Users for Current Month
                              </Typography>
                              <Typography>0</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card>
                            <CardContent>
                              <Typography className="main-Heading">
                                Users Not Registered
                              </Typography>
                              <Typography>7</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card className="bg-card-blue-even">
                            <CardContent>
                              <Typography className="main-Heading">
                                Users Registered
                              </Typography>
                              <Typography>6</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="mt-10px">
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card>
                            <CardContent>
                              <Typography className="main-Heading">
                                Whatsapp User Count
                              </Typography>
                              <Typography>7</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card className="bg-card-blue-even">
                            <CardContent>
                              <Typography className="main-Heading">
                                App User Count
                              </Typography>
                              <Typography>0</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card>
                            <CardContent>
                              <Typography className="main-Heading">
                                User Activity
                              </Typography>
                              <Typography>266</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3} sm={6}>
                          <Card className="bg-card-blue-even">
                            <CardContent>
                              <Typography className="main-Heading">
                                User Queries
                              </Typography>
                              <Typography>89</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    className="position-relative pl-0px"
                  >
                    <div className="botSummaryGifImg">
                      <img
                        src={imgUrl.summaryBotImg}
                        className="summaryBotImg"
                      ></img>
                    </div>
                    <img src={imgUrl.summaryGif} className="w-100 h-95"></img>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="">
                  <Grid item xs={12} className="pt-0px">
                    <div className="mt-10px">
                      <img src={imgUrl.amrDashboard} className="w-100"></img>
                    </div>
                    <div className="">
                      <img src={imgUrl.amrDashboardImg} className="w-100"></img>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </CustomTabPanel>
        {/* second tab section */}
        <CustomTabPanel value={value} index={1}>
          <div className="main-container">
            <SecondaryMenuTabs></SecondaryMenuTabs>
          </div>
        </CustomTabPanel>
      </div>
    </Box>
  );
}
