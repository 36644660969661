const transformData = (data) => {
    const map = new Map();

    // Initialize the map
    data.forEach(item => {
        map.set(item.id, { ...item, children: [] });
    });

    // Build the nested structure
    data.forEach(item => {
        if (item.attributes.map_parent_topic.data) {
            const parentId = item.attributes.map_parent_topic.data.id;
            map.get(parentId).children.push(map.get(item.id));
        }
    });

    // Get the root items
    return Array.from(map.values()).filter(item => !item.attributes.map_parent_topic.data);
};

export const connectDataUtils = {
    transformData: transformData
}