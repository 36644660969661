import React from "react";
import AccordionItem from "./AccordionItem";

const NestedAccordion = ({items, fetchHeaderData }) => {
    
    return (
        items.map((item, index) => (
            <AccordionItem key={index} item={item} 
                fetchHeaderData={fetchHeaderData}
            />
        ))
    )
}

export default NestedAccordion