import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFormikContext, FieldArray } from 'formik';
import { Button, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, TextField, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import EmbeddingDetails from "../uploadedFilesDetails";

const MAX_FILES = 20;
const MAX_FILE_SIZE_MB = 100;

const FileUpload = () => {
    const columnWidths = [50, 200, 180, 120, 100, 150, 100, 180, 180, 120]; // Example widths in pixels
    const { values, setFieldValue } = useFormikContext();

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [embeddingDetails, setEmbeddingDetails] = useState([]);
    const API_BASE_URL = 'http://127.0.0.1:8000';
    const AUTH_TOKEN = '7cddf608e4805303cd1e601ac5d1a5d97a1a4832';

    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            'Authorization': `Token ${AUTH_TOKEN}`,
            'Content-Type': 'multipart/form-data',
        }
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/getallembeddingdetails`);
            if (response.status === 200) {
                setEmbeddingDetails(response.data.response);
            } else {
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const checkFileNameExists = (fileName) => {
        for (const item of embeddingDetails) {
            if (item.fields && item.fields.file_name === fileName) {
                return true;
            }
        }
        return false;
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        const totalFiles = values.files ? values.files.length + newFiles.length : newFiles.length;

        if (totalFiles > MAX_FILES) {
            alert(`Cannot upload more than ${MAX_FILES} files.`);
            return;
        }

        const validFiles = newFiles.filter(file => {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                alert(`File "${file.name}" exceeds the size limit of ${MAX_FILE_SIZE_MB}MB and was not added.`);
                return false;
            }

            const fileName = file.name.replace(/ /g, "_");
            if (checkFileNameExists(fileName)) {
                alert(`File "${file.name}" already exists.`);
                file.replace = false;
                setSubmitDisabled(true);
                return true; // Add the file anyway, but mark it for replacement
            }

            return true;
        }).map(file => ({
            file,
            title: '',
            theme: ''
        }));

        validFiles.forEach(fileData => {
            setFieldValue('files', [...(values.files || []), fileData]);
        });
    };

    const handleAddMore = () => {
        document.getElementById('file-input').click();
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = [...values.files];
        updatedFiles.splice(index, 1);
        setFieldValue('files', updatedFiles);
    };

    const handleReplaceFile = (index) => {
        const updatedFiles = [...values.files];
        updatedFiles[index] = { ...updatedFiles[index], replace: true };
        setFieldValue('files', updatedFiles);
        setSubmitDisabled(false);
    };

    const handleInputChange = (index, field, value) => {
        const updatedFiles = [...values.files];
        updatedFiles[index][field] = value;
        setFieldValue('files', updatedFiles);
    };

    const handleSubmit = async () => {
        setSubmitDisabled(true);

        const formData = new FormData();
        values.files.forEach((fileData, index) => {
            formData.append(`files[${index}][file]`, fileData.file);
            formData.append(`files[${index}][title]`, fileData.title);
            formData.append(`files[${index}][theme]`, fileData.theme);
            if (fileData.replace) {
                formData.append(`files[${index}][replace]`, true);
            } else {
                formData.append(`files[${index}][replace]`, false);
            }
        });

        try {
            const response = await axiosInstance.post('/reactupload/', formData);
            alert('Files uploaded successfully!');
            setFieldValue('files', []); // Clear files list after successful upload
        } catch (error) {
            console.error('Error uploading files:', error);
            alert('Failed to upload files.');
        } finally {
            setSubmitDisabled(false);
        }
    };

    return (
        <>
        
        <div>
            <input
                id="file-input"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<UploadFileIcon />}
                onClick={handleAddMore}
                style={{ backgroundColor: 'rgb(0, 45, 103)', color: '#fff', marginBottom: '1rem' }}
            >
                Add More Files
            </Button>
            <List>
                {values.files && values.files.map((fileData, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={`${fileData.file.name} (${(fileData.file.size / (1024 * 1024)).toFixed(2)} MB)`}
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => handleRemoveFile(index)} color="secondary">
                                <DeleteIcon />
                            </IconButton>
                            {checkFileNameExists(fileData.file.name.replace(/ /g, "_")) && (
                                <IconButton onClick={() => handleReplaceFile(index)} color="primary">
                                    <SwapHorizIcon />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            {values.files && values.files.map((fileData, index) => (
                <div key={index} style={{ marginBottom: '1rem' }}>
                    <Typography variant="h6">{fileData.file.name}</Typography>
                    <TextField
                        label="Title"
                        variant="outlined"
                        fullWidth
                        value={fileData.title}
                        onChange={e => handleInputChange(index, 'title', e.target.value)}
                        style={{ marginBottom: '0.5rem' }}
                    />
                    <TextField
                        label="Theme"
                        variant="outlined"
                        fullWidth
                        value={fileData.theme}
                        onChange={e => handleInputChange(index, 'theme', e.target.value)}
                    />
                </div>
            ))}
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={submitDisabled}
                style={{ backgroundColor: 'rgb(0, 45, 103)', color: '#fff', marginTop: '1rem' }}
            >
                Upload Files
            </Button>
        </div>
            {/* <EmbeddingDetails embeddingDetails={embeddingDetails} columnWidths={columnWidths}/> */}
        </>
    );
};

export default FileUpload;
