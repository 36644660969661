import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled/macro";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";

// files import
import imgUrl from "../../img/imgUrl";
import CMSTabs from "../SecondaryMenuTabs/CMSTabs/CMSTabs";
import AdditionalConfigTabs from "../SecondaryMenuTabs/AdditionalConfigTabs/additionalconfigtabs";
import DataManagementTabs from "./DataManagementTabs/DataManagementTabs";
// import PreviewPageTable from "../SecondaryMenuTabs/PreviewPageTable/previewpagetable";
// import PreviewSecondTable from "../../components/Preview/verifyNumberTable";

import BotCreationForm from "../../components/FormComponents/bot_creation_form";
import Preview from "../../components/Preview/preview";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
// import CmsNearMeFileTable from "./CMSTabs/CMSNearMeFileTable/cmsnearmefiletable";
import CMSOptions from "./CMSTabs/CMSOptions/CMSOptions";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SecondaryMenuTabs() {
  const [value, setValue] = React.useState(0);
  const botId = useSelector((state) => state?.bot?.botDetails?.botid);

  const handleChange = (event, newValue) => {
    if (newValue == 1 && botId == undefined) {
      Swal.fire("", "Please select a bot first then upload ", "warning");
      return;
    }
    setValue(newValue);
  };

  // custom textfield ui
  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4FD1C5", // Change this to your desired focused border color
    },
  }));

  // publish switch
  const label = {
    inputProps: {
      "aria-label": "Switch demo",
    },
  };
  return (
    <Box sx={{ width: "100%" }} className="formTabs">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <Tab
            label={<Typography variant="body1">Vital Assistance</Typography>}
            {...a11yProps(0)}
          />
          <Tab
            label={<Typography variant="body1">Content Management</Typography>}
          />
          <Tab
            label={<Typography variant="body1">Data Management</Typography>}
          />
          <Tab label={<Typography variant="body1">Preview</Typography>} />
          <Tab label={<Typography variant="body1">Publish</Typography>} />
          <Tab
            label={
              <Typography variant="body1">Additional Configuration</Typography>
            }
          />
        </Tabs>
      </Box>
      {/* first tab section */}
      <CustomTabPanel value={value} index={0} className="custom-mainTabs">
        <BotCreationForm setActiveTab={setValue} />
      </CustomTabPanel>
      {/* second tab section */}
      <CustomTabPanel value={value} index={1} className="custom-mainTabs">
        <Box className="container-setTabs cms-tabs" mx="auto">
          <div className="">
            <CMSOptions></CMSOptions>
            <CMSTabs></CMSTabs>
          </div>
        </Box>
      </CustomTabPanel>
      {/* third tab section */}
      <CustomTabPanel value={value} index={2} className="custom-mainTabs">
        <Box className="container-setTabs cms-tabs" mx="auto">
          <div className="">
            <DataManagementTabs></DataManagementTabs>
          </div>
        </Box>
      </CustomTabPanel>
      {/* fourth tab section */}
      <CustomTabPanel value={value} index={3} className="custom-mainTabs">
        <Box className="container-setTabs cms-tabs preview-tabs" mx="auto">
          <div className="">
            <Preview />
            {/* <div>
              <div
                className="d-flex justify-content-end"
                style={{ marginRight: "31px" }}
              >
                <Button
                  variant="contained"
                  className="blue-greenBtn mt-20px"
                  // onClick={handleQRClickOpen}
                >
                  Submit
                </Button>
              </div>
            </div> */}
          </div>
        </Box>
      </CustomTabPanel>
      {/* fifth tab section */}
      <CustomTabPanel value={value} index={4} className="custom-mainTabs">
        <Box className="container-setTabs cms-tabs" mx="auto">
          <div className="">
            <div className="">
              <Grid container spacing={2} className="m-10">
                <Grid item xs={12} sm={6}>
                  <div className="">
                    <p className="cardtitlesmall d-flex align-items-center publishLabel">
                      <img src={imgUrl.whatsappIcon}></img>
                      <span className="channelLabel">Whatsapp </span>
                    </p>
                    <div className="">
                      <div className="mt-20px singlequestiondiv mb-0 w-100">
                        <CustomTextField
                          id="WhatsappNumber"
                          name="WhatsappNumber"
                          label="Whatsapp Number"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {/* <PersonIcon /> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <div className="mt-20px">
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="">
                    <p className="cardtitlesmall d-flex align-items-center publishLabel">
                      <img src={imgUrl.fbIcon}></img>
                      <span className="channelLabel">Facebook</span>
                    </p>
                    <div className="">
                      <div className="mt-20px singlequestiondiv mb-0 w-100">
                        <CustomTextField
                          id="FacebookId"
                          name="FacebookID"
                          label="Facebook ID"
                          variant="outlined"
                          fullWidth
                          value=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {/* <PersonIcon /> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <div className="mt-20px">
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </CustomTabPanel>
      {/* sixth tab section */}
      <CustomTabPanel value={value} index={5} className="custom-mainTabs">
        <Box className="container-setTabs cms-tabs" mx="auto">
          <div className="">
            <AdditionalConfigTabs></AdditionalConfigTabs>
          </div>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
