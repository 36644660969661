const transformData = (data) => {
    const map = new Map();

    // Initialize the map
    data.forEach(item => {
        map.set(item.id, { ...item, children: [] });
    });

    // Build the nested structure
    data.forEach(item => {
        const parentSurvey = item.attributes.map_parent_survey?.data;  // Safely access parent survey data
        if (parentSurvey) {
            const parentId = parentSurvey.id;
            if (map.has(parentId)) {
                map.get(parentId).children.push(map.get(item.id));
            }
        }
    });

    // Get the root items (those without a parent)
    return Array.from(map.values()).filter(item => !item.attributes.map_parent_survey?.data);
};



export const connectDataUtils = {
    transformData: transformData
}