import React from "react";
import AccordionItem from "./AccordionItem";

const NestedAccordion = ({items, fetchHeaderData , formikInitialValues,allQuestion}) => {
    
    return (
        items.map((item, index) => (
            <AccordionItem key={index} item={item} 
                fetchHeaderData={fetchHeaderData}
                formikInitialValues={formikInitialValues}
                allQuestion={allQuestion}
            />
        ))
    )
}

export default NestedAccordion