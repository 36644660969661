import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";

// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddNewBotUser from "./addNewBotUser";
import { useSelector } from "react-redux";
import { post } from "../../apiServices/api";

// dialog add ui
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// custom textfield ui
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData("91", 159, "India", 24, 4.0),
//   createData("81", 237, "Zambia", 37, 4.3),
//   createData("10", 262, "Australia", 24, 6.0),
//   createData("91", 305, "Brazil", 67, 4.3),
//   createData("01", 356, "Afghanistan", 49, 3.9),
//   createData("01", 356, "Afghanistan", 49, 3.9),
//   createData("91", 305, "Brazil", 67, 4.3),
//   createData("91", 305, "Brazil", 67, 4.3),
//   createData("01", 356, "Afghanistan", 49, 3.9),
// ];


export default function PreviewSecondTable({fetchQR}) {
  // custom dialog add
  const [openAddPreview, setOpenAddPreview] = React.useState(false);
  const [botUserDetailsList, setBotUserDetailsList] = React.useState([]);
  const handleAddPreviewClickOpen = () => {
    setOpenAddPreview(true);
  };
  const handleAddPreviewClose = () => {
    setOpenAddPreview(false);
  };
  const handleDeleteUser=async (row)=>{
    try {
      // console.log("To be deleted ",row.userId,selectedBotIDPreview)
      const response = await post(`botconfig/deleteBotuserById`, {
        botId:selectedBotIDPreview,
        userId:row.userId

      });
      if (response.status === 200) {
        // fetchBotDetailsList();
        fetchBotUserDetails(selectedBotIDPreview);
        // console.log(response)
        Swal.fire({
          title: "",
          text: "User deleted successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire("Error", "Failed to fetch data", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  }
  const selectedBotIDPreview = useSelector(
    (state) => state?.bot?.previewBotIDSelected
  );
  // console.log(
  //   `Inside the Second Preview Table Selected Value is ${selectedBotIDPreview}`
  // );
  React.useEffect(() => {
    fetchBotUserDetails(selectedBotIDPreview);
  }, [selectedBotIDPreview]);
  
  const fetchBotUserDetails = React.useCallback(
    async (selectedBotIDPreview) => {
      try {
        if (selectedBotIDPreview != null) {
          const response = await post(`botconfig/get-bot-user-details`, {
            bot_id: selectedBotIDPreview,
            roleId: 6,
          });
          if (response.status === 200) {
            // console.log("Second TABLE response data is ", response?.data);
            if(response.data.length===1){
              // console.log(`First UR Generate QR code`)
              fetchQR(selectedBotIDPreview)
            }
            // console.log("Second Table", response?.data)
            const temp=response?.data.map((item) => ({
              userName: item.username,
              countryCode: item.countrycode,
              userNumber: item.socialuid,
              userId:item.userid,
            }));
            setBotUserDetailsList(temp)

          } else {
            Swal.fire("Error", response.message, "error");
            // console.log("Else  Error is ", response.message, response.status);
          }
        }
      } catch (error) {
        Swal.fire("Error", error.message, "error");
        // console.log("Caught Error is ", error.message);
      }
    },
    []
  );
  return (
    <TableContainer
      component={Paper}
      className="previewTable verifyPreviewTable"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-light-blue">
          <TableRow className="tableHeading">
            <TableCell className="d-flex justify-content-start align-items-center">
              Country Code
            </TableCell>
            <TableCell className="d-flex justify-content-start align-items-center">
              Name
            </TableCell>

            <TableCell className="w-50 d-flex align-items-center">
              Number
            </TableCell>

            <TableCell className="d-flex justify-content-start align-items-center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {botUserDetailsList.map((row) => (
            <TableRow
              className="bg-white"
              key={row.userNumber}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.countryCode}
              </TableCell>
              <TableCell>{row.userName}</TableCell>
              <TableCell>{row.userNumber}</TableCell>
              <TableCell>
                <div className="d-flex justify-content-start align-items-center">
                  <EditIcon
                    style={{
                      cursor: "pointer",
                      marginRight: 10,
                      color: "#5ecec1",

                      backgroundColor: "#dcf5f2",
                      borderRadius: "50px",
                      padding: "6px",
                      fontSize: "30px",
                    }}
                  ></EditIcon>
                  <DeleteIcon
                    // className="ml-15px"
                    
                    onClick={()=>handleDeleteUser(row)}
                    style={{
                      cursor: "pointer",
                      marginRight: 10,
                      color: "#5ecec1",

                      backgroundColor: "#dcf5f2",
                      borderRadius: "50px",
                      padding: "6px",
                      fontSize: "30px",
                    }}
                  ></DeleteIcon>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {selectedBotIDPreview != null ? (
          <AddBoxIcon
            className="mt-15px ml-10px cursor-pointer fill-green"
            onClick={handleAddPreviewClickOpen}
          ></AddBoxIcon>
        ) : (
          <div className="text-center">Select a Bot </div>
        )}

        <AddNewBotUser
          openAddPreview={openAddPreview}
          setOpenAddPreview={setOpenAddPreview}
          fetchBotUserDetails = {fetchBotUserDetails}
        />
      </Table>
    </TableContainer>
  );
}
