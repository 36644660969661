import * as React from "react";

import {
  Tabs,
  Tab,
  Box,
  InputAdornment,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Grid,
  Card,
  IconButton,
  CardContent,
  MenuItem,
} from "@mui/material";
import imgUrl from "../../../img/imgUrl";
import styled from "@emotion/styled/macro";
// icons import
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DataSetsTable from "./DataSetsTable/DataSetsTable";

import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

const steps = ["Select And Upload Excel/CSV Files", "Review & Confirm", "Finish"];

export default function ExcelDataTab() {
  const [files, setFiles] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  // const isStepOptional = (step) => step === 1; // Only the second step is optional

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ width: "100%" }}>
            <div>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed grey",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className="dragSection"
              >
                <input {...getInputProps()} />
                <CloudUploadIcon
                  sx={{
                    fontSize: 50,
                    color: "grey",
                  }}
                />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Drag & Drop files here
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  or
                </Typography>
                <Button
                  variant="contained"
                  component="span"
                  sx={{ mt: 2 }}
                  className="browseBtn text-capitalize"
                >
                  Browse Files
                </Button>
              </Box>
            </div>
          </Box>
        );

      case 1:
        return (
          <Typography className="stepThree">
  <div className="metaDataTable">
    
    <Grid container className="tableHead"
     style={{ backgroundColor: '#e0f7f9', textAlign: 'center' ,
      height: '3.5rem',alignItems: 'center'}}>

      <Grid item xs={2}>
        <p >Label Head</p>
      </Grid>
      <Grid item xs={5}>
        <p >TextField Head</p>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>

    <div className="bgMainColorData">
      <Grid container spacing={0} className="bg-color">
        <Grid item xs={2} className="left-grid">
          <p>Label</p>
        </Grid>
        <Grid item xs={5} className="right-grid">
          <CustomTextField
            label=""
            placeholder=""
            multiline
            rows={2}
            variant="outlined"
            fullWidth
            style={{
              padding: '8px', // Optional: Adjust padding as needed
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid> {/* To maintain alignment */}
      </Grid>

      <Grid container spacing={0} className="bg-color">
        <Grid item xs={2} className="left-grid">
          <p>Label</p>
        </Grid>
        <Grid item xs={5} className="right-grid">
          <CustomTextField
            label=""
            placeholder=""
            multiline
            rows={2}
            variant="outlined"
            fullWidth
            style={{
              padding: '8px', // Optional: Adjust padding as needed
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid> {/* To maintain alignment */}
      </Grid>
    </div>
  </div>
</Typography>
        );
      case 2:
        return (
          <Typography className="mt-30px verifySection">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={2}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Grid item xs={12} lg={10}>
                    <CustomTextField
                      name="Enter Question"
                      id="questionData"
                      label="Enter Question"
                      variant="outlined"
                      placeholder="Enter Question"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <PersonIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                      mb={2}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      variant="contained"
                      className="blue-greenBtn sendBtn"
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className="responseField mt-20px d-flex justify-content-start align-items-center"
                >
                  <Grid item xs={12} lg={10}>
                    <CustomTextField
                      label=""
                      placeholder="Response"
                      multiline
                      rows={2}
                      variant="outlined"
                      fullWidth
                      className=""
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      variant="contained"
                      className="blue-greenBtn noBgColor"
                    >
                      <img src={imgUrl.verifyIcon} alt="verify icon" />
                      Verified
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
          </Typography>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  //File Upload Code

  React.useEffect(() => {
    console.log(`Files Are`, files);
  }, [files]);
  const onDrop = (acceptedFiles) => {
    const newFiles = [];
    const duplicateFiles = [];

    acceptedFiles.forEach((file) => {
      // Check if the file is an Excel file
      if (
        file.name.endsWith(".xlsx") ||
        file.name.endsWith(".xls") ||
        file.name.endsWith(".csv")
      ) {
        // Check if the file already exists in the files state
        if (!files.some((existingFile) => existingFile.name === file.name)) {
          newFiles.push(file);
        } else {
          duplicateFiles.push(file.name);
        }
      }
    });
    if (newFiles.length == 0 && duplicateFiles.length == 0) {
      // console.log("Invalid No Excel CSV Files")
      Swal.fire(
        "Invalid Files",
        "Only Excel and CSV Files can be uploaded",
        "warning"
      );
      return;
    }
    // Update the files state to include new files only
    setFiles([...files, ...newFiles]);

    // Show a Swal warning if there were any duplicate files
    if (duplicateFiles.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "Duplicate Files Detected",
        text: `The following file(s) were already uploaded and have been included only once: ${duplicateFiles.join(
          ", "
        )}`,
      });
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });
  const handleSubmit = () => {
    console.log("Submit");
  };
  const handleRemoveFile = (delIndex) => {
    const filesLeft = files.filter((file, index) => index != delIndex);
    setFiles(filesLeft);
  };
  return (
    <>
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption">Optional</Typography>
          //   );
          // }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset} className="stepperMainButton">
              Reset
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
            <Button onClick={handleNext} className="stepperMainButton">
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
    <Box >
      {activeStep==0&&files.map((fileData,index)=>
      <div
      key={index}
      style={{ marginBottom: "1rem" }}
      className="section-upload"
    >
            <div className="d-flex justify-content-between align-items-center">
           <Typography variant="h6" className="fileLabel">
                    {`${fileData.name} (${(
                      fileData.size /
                      (1024 * 1024)
                    ).toFixed(2)} MB)`}
                  </Typography>
                  <span className="action-button">
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                    </span>
           </div>
           </div>
      )}
          {activeStep==0&&files.length > 0 && (
        <Button
          variant="contained"
          onClick={handleSubmit}
          startIcon={<UploadFileIcon />}
          disabled={submitDisabled}
          className="btn-upload btn blue-greenBtn"
        >
          Upload Files
        </Button>
      )}
      </Box>  
      <Box>
      <DataSetsTable />
        </Box> 
      
    </>
  );
}

{
  /*
  <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset} className="stepperMainButton">
              Reset
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext} className="stepperMainButton">
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
      <Box >
      {files.map((fileData,index)=>
      <div
      key={index}
      style={{ marginBottom: "1rem" }}
      className="section-upload"
    >
            <div className="d-flex justify-content-between align-items-center">
           <Typography variant="h6" className="fileLabel">
                    {`${fileData.name} (${(
                      fileData.size /
                      (1024 * 1024)
                    ).toFixed(2)} MB)`}
                  </Typography>
                  <span className="action-button">
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                    </span>
           </div>
           </div>
      )}
          {files.length > 0 && (
        <Button
          variant="contained"
          onClick={handleSubmit}
          startIcon={<UploadFileIcon />}
          disabled={submitDisabled}
          className="btn-upload btn blue-greenBtn"
        >
          Upload Files
        </Button>
      )}
      </Box>   
    </Box>
     
           <DataSetsTable />
  */
}
