import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

import qrimg from "../img/qrcode.png";
import database_config from "../config/config";
import config from "../config/config";

const useStyles = styled((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function Register({ values, handleChange, handleBlur }) {
  return (
    <div>
      <Grid container spacing={2} className="m-10">
        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Chunk Details</p>
            <div className="formcardbody">
              {config.chunk_config.map((items) => {
                // console.log(
                //   "values['items.id']",
                //   values,
                //   items.id,
                //   values[items.id]
                // );
                return (
                  <div className="mt-20px singlequestiondiv">
                    <TextField
                      id={items.id}
                      name={items.id}
                      label={items.label}
                      variant="outlined"
                      fullWidth
                      value={values[items.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Model Details</p>
            <div className="formcardbody">
              {config.model_config.map((items) => {
                return (
                  <div className="mt-20px singlequestiondiv">
                    <TextField
                      id={items.id}
                      name={items.id}
                      label={items.label}
                      variant="outlined"
                      fullWidth
                      value={values[items.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="m-10 mt-10px">
        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Database Details</p>
            <div className="formcardbody">
              {config.database_config.map((items) => {
                return (
                  <div className="mt-20px singlequestiondiv">
                    <TextField
                      id={items.id}
                      name={items.id}
                      label={items.label}
                      variant="outlined"
                      fullWidth
                      value={values[items.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                      InputLabelProps={{
                        shrink: !!values[items.id] || values[items.id] === 0,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Temperature Details</p>
            <div className="formcardbody">
              {config.temperature_details.map((items) => {
                return (
                  <div className="mt-20px singlequestiondiv">
                    <TextField
                      id={items.id}
                      name={items.id}
                      label={items.label}
                      variant="outlined"
                      fullWidth
                      value={values[items.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="m-10 mt-10px">
        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Embedding Details</p>
            <div className="formcardbody">
              {config.embedding_config.map((items) => {
                return (
                  <div className="mt-20px singlequestiondiv">
                    <TextField
                      id={items.id}
                      name={items.id}
                      label={items.label}
                      variant="outlined"
                      fullWidth
                      value={values[items.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Token Details</p>
            <div className="formcardbody">
              {config.token_details.map((items) => {
                return (
                  <div className="mt-20px singlequestiondiv">
                    <TextField
                      id={items.id}
                      name={items.id}
                      label={items.label}
                      variant="outlined"
                      fullWidth
                      value={values[items.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="m-10 mt-10px">
        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">System Details</p>
            <div className="formcardbody">
              {config.system_details.map((items) => {
                return (
                  <div className="mt-20px singlequestiondiv">
                    <TextField
                      id={items.id}
                      name={items.id}
                      label={items.label}
                      variant="outlined"
                      fullWidth
                      value={values[items.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Register;
