// src/features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  botDetails: {},
  allBotDetailList: [],
  previewBotIDSelected: null,
};

const botSlice = createSlice({
  name: "bot",
  initialState,
  reducers: {
    setBotDetails(state, action) {
      state.botDetails = action.payload;
    },
    clearBotDetails(state) {
      state.botDetails = {};
    },
    setAllBotDetailList(state, action) {
      state.allBotDetailList = action.payload;
    },
    setPreviewBotID(state, action) {
      state.previewBotIDSelected = action.payload;
      // console.log(
      //   ` Inside Redux Current selected Bot ID in Preview Is ${action.payload}`
      // );
    },
    clearPreviewBotID(state, action) {
      state.previewBotIDSelected = null
    }
  },
});

export const {
  setBotDetails,
  clearBotDetails,
  setAllBotDetailList,
  setPreviewBotID,
  clearPreviewBotID
} = botSlice.actions;

export default botSlice.reducer;
