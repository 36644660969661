import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import qrimg from '../img/qrcode.png';

function Labvalues() {
  const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2} className='m-0'>
        <Grid item xs={12}>
          <div className='steppersearchbtnholder'>
            <a href='#' className='dashboardheaderbtn' onClick={handleClickOpen}>
              <SearchOutlinedIcon /> Search
            </a>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            className='searchdialog'
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="formcard">
                  <p className="cardtitlesmall">Search</p>
                  <div className='formcardbody'>
                    <div className='text-center'>
                      <img src={qrimg} className='qrimage' alt="QR Code" />
                    </div>
                    <div className='text-center'>
                      <p className='ortext'>OR</p>
                    </div>
                    <div className='text-center mt-20px'>
                      <div className='contentdiv text-center'>
                        <div className='dashboardsearchbar headertop'>
                          <div className='selectbox'>
                            <FormControl sx={{ minWidth: 120 }}>
                              <Select
                                value={age}
                                onChange={handleChange}
                                displayEmpty
                                sx={{ marginTop: 0 }}
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>All</MenuItem>
                                <MenuItem value={20}>Patient Record List</MenuItem>
                                <MenuItem value={30}>Follow-Up</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className='searchbox'>
                            <FormControl>
                              <Input
                                id="input-with-icon-adornment"
                                placeholder='Search Here'
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchOutlinedIcon />
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Grid container spacing={2} className='mt-20px'>
                      <Grid item xs={6}>
                        <div className='singlequestiondiv mb-10px'>
                          <TextField id="outlined-basic" label="Full Name" variant="outlined" />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className='singlequestiondiv mb-10px'>
                          <TextField id="outlined-basic" label="Gender" variant="outlined" />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className='singlequestiondiv mb-10px'>
                          <TextField id="outlined-basic" label="Date of Birth" variant="outlined" />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className='singlequestiondiv mb-10px'>
                          <TextField id="outlined-basic" label="Unique ID" variant="outlined" />
                        </div>
                      </Grid>
                    </Grid>
                    <div className='mt-20px singlequestiondiv text-center'>
                      <Button variant="contained" color="primary" className='nextButton'>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
      <Grid container spacing={2} className='m-0'>
        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Diabetic Profile</p>
            <div className='formcardbody'>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="Fasting Blood Sugar (mg/dl)" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="Post Prandial Blood Sugar (mg/dl)" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="Random Blood Sugar (mg/dl)" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="Midnight Random Blood Sugar (mg/dl)" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="HBA1C (%)" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="SGOT (U/L)" variant="outlined" />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="formcard">
            <p className="cardtitlesmall">Renal Profile</p>
            <div className='formcardbody'>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="Urea (mg/dl)" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="Creatinine (mg/dl)" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="EGFR" variant="outlined" />
              </div>
              <div className='mt-20px singlequestiondiv'>
                <TextField id="outlined-basic" label="Uric Acid (mg/dl)" variant="outlined" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Labvalues;
