import React from "react";
import styled from "@emotion/styled/macro";
import { Backdrop } from "@mui/material"; 
import CircularProgress from "@mui/material/CircularProgress";

const BlurBackdrop = styled(Backdrop)({
    backdropFilter: "blur(1px)",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    zIndex: 1300, // Ensure it's above other elements
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  });
  
  // Custom CircularProgress with Specific Color
  const StyledCircularProgress = styled(CircularProgress)({
    color: "#4FD1C5",
  });

const Loader = ({ loadingState }) => {
    return (
      <BlurBackdrop open={loadingState}>
        <StyledCircularProgress size={"5rem"} />
      </BlurBackdrop>
    );
  }; 

  export default Loader