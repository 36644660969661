import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled/macro";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Paper,
} from "@mui/material";
import { Edit, Delete, ExpandMore, ExpandLess } from "@mui/icons-material";
import { post } from "../../apiServices/api";
// icons import
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { delStrapi } from "../../apiServices/api";

// Function to structure data into nested format
const structureData = (data) => {
  const map = {};
  const result = [];

  // Map items to their id
  data.forEach((item) => {
    map[item.id] = { ...item, subRows: [] };
    if (item.attributes.map_parent.data) {
      const parentId = item.attributes.map_parent.data.id;
      if (!map[parentId]) {
        map[parentId] = { subRows: [] };
      }
      map[parentId].subRows.push(map[item.id]);
    } else {
      result.push(map[item.id]);
    }
  });

  return result;
};

// Recursive component to handle nested rows
const NestedTableRows = ({
  rows,
  expandedRows,
  handleExpandClick,
  editSurveyQuestion,
  handleDeleteSurveyQuestion,
  level = 0,
}) => {
  return (
    <>
      {rows.map((row) => (
        <React.Fragment key={row.id}>
          <TableRow>
            <TableCell>
              {row.subRows.length > 0 && (
                <IconButton
                  onClick={() => handleExpandClick(row.id)}
                  aria-expanded={!!expandedRows[row.id]}
                  aria-label="expand row"
                >
                  {expandedRows[row.id] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
              {row.attributes.question_name}
            </TableCell>
            <TableCell>
              {row.attributes.map_parent.data?.attributes.question_name ||
                "N/A"}
            </TableCell>
            <TableCell>
              <div className="d-flex registrationButtonIcons">
                <IconButton onClick={() => editSurveyQuestion(row)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDeleteSurveyQuestion(row.id)}>
                  <Delete />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
          {expandedRows[row.id] && row.subRows.length > 0 && (
            <TableRow>
              <TableCell colSpan={3} style={{ padding: 0 }}>
                <Collapse in={expandedRows[row.id]}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <NestedTableRows
                          rows={row.subRows}
                          expandedRows={expandedRows}
                          handleExpandClick={handleExpandClick}
                          editSurveyQuestion={editSurveyQuestion}
                          handleDeleteSurveyQuestion={
                            handleDeleteSurveyQuestion
                          }
                          level={level + 1} // Increase indentation level for child rows
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const RegistrationTable = ({
  allQuestions,
  fetchAllQuestionByBotId,
  setFormikInitialValues,
  setFormEditState,
}) => {
  const [expandedRows, setExpandedRows] = useState({});

  // Function to recursively find and delete all child rows
  const deleteRecordWithChildren = async (parentId, allRows) => {
    // Helper function to find child rows
    const findChildren = (parentId, allRows) => {
      let children = [];
      allRows.forEach((row) => {
        if (row.attributes.map_parent?.data?.id === parentId) {
          children.push(row);
          children = children.concat(findChildren(row.id, allRows)); // Recursively find further children
        }
      });
      return children;
    };

    // Find all child rows
    const children = findChildren(parentId, allRows);

    // Delete each child row
    for (const child of children) {
      try {
        await post("/cms/deleteCms", { url: `/registrations/${child.id}` });
      } catch (error) {
        Swal.fire(
          "Error",
          `Error deleting child with id ${child.id}: ${error.message}`,
          "error"
        );
        return; // Stop further processing if any delete fails
      }
    }

    // Finally, delete the parent row
    try {
      const response = await post("/cms/deleteCms", {
        url: `/registrations/${parentId}`,
      });
      if (response.data != null) {
        fetchAllQuestionByBotId(); // Refresh the data after deletion
        Swal.fire({
          title: "",
          text: "Registration Question and its children deleted successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire("Error", response.message, "error");
      }
    } catch (error) {
      Swal.fire(
        "Error",
        `Error deleting parent with id ${parentId}: ${error.message}`,
        "error"
      );
    }
  };

  // Usage in the component
  const handleDeleteSurveyQuestion = async (id) => {
    deleteRecordWithChildren(id, allQuestions);
  };

  const handleExpandClick = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const editSurveyQuestion = (row) => {
    const attributes = row.attributes;
    const formikObj = {
      questionName: attributes.question_name || "",
      questionType: attributes.question_type || "",
      options: attributes.question_option
        ? attributes.question_option.map((option) => option.options_names)
        : [],
      mandatory: attributes.mandatory || false,
      section: attributes.is_section || false,
      parentQuestion: attributes.map_parent.data
        ? {
            label: attributes.map_parent.data.attributes.question_name || "",
            id: attributes.map_parent.data.id || "",
          }
        : null,
      dependentValues: attributes.dependent_option_value
        ? attributes.dependent_option_value.map((options) => ({
            label: options?.dependent_value,
            id: options?.id,
          }))
        : [],
      id: row.id,
    };
    setFormEditState(true);
    setFormikInitialValues(formikObj);
  };

  const rows = structureData(allQuestions);

  return (
    <Grid item xs={12} lg={7}>
      <Card style={{ minHeight: "100%" }}>
        <CardContent style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table className="registrationTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Question Name</TableCell>
                    <TableCell>Parent Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <NestedTableRows
                    rows={rows}
                    expandedRows={expandedRows}
                    handleExpandClick={handleExpandClick}
                    editSurveyQuestion={editSurveyQuestion}
                    handleDeleteSurveyQuestion={handleDeleteSurveyQuestion}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RegistrationTable;
