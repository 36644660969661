import * as React from "react";
import PropTypes from "prop-types";
import { styled, alpha } from "@mui/material/styles";
import {
  Tabs,
  Tab,
  Box,
  InputBase,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  TextField,
} from "@mui/material";

// icons import
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// files import
import AddBotConfig from "../../addbotconfig";
import TranslationTable from "./TranslationTable/translationtable";
import Broadcast from "./Broadcast";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// style search bar
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
      //   "&:focus": {
      //     width: "40ch",
      //   },
    },
  },
}));
// dialog ui
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
// custom textfield ui
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

export default function AdditionalConfigTabsTabs() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // custom dialog translation
  const [open, setOpen] = React.useState(false);
  const handleTranslationClickOpen = () => {
    setOpen(true);
  };
  const handleTranslationClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="Additional Data" {...a11yProps(0)} />
          <Tab label="Translation" {...a11yProps(1)} />
          <Tab label="BroadCast" {...a11yProps(2)} />
        </Tabs>
      </Box>

      {/* first tab section */}
      <CustomTabPanel value={value} index={0}>
        <div>
          <AddBotConfig></AddBotConfig>
        </div>
      </CustomTabPanel>
      {/* second tab section */}
      <CustomTabPanel value={value} index={1}>
        <div className="translationSection">
          <div className="d-flex justify-content-between align-items-center flex-direction-column-mobile">
            <React.Fragment>
              <Button
                variant="outlined"
                onClick={handleTranslationClickOpen}
                className="translationBtn"
              >
                Add Translation
              </Button>
              <BootstrapDialog
                onClose={handleTranslationClose}
                aria-labelledby="customized-dialog-title"
                open={open}
              >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  Add
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleTranslationClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <div className="mt-10px">
                    <CustomTextField
                      id="label"
                      label="Label"
                      placeholder="Please Enter Label"
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <LockIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                      mb={2}
                    />
                    <CustomTextField
                      id="english"
                      label="English"
                      placeholder="Please Enter Text"
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <LockIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                      mb={2}
                      className="mt-30px"
                    />
                    <CustomTextField
                      id="french"
                      label="French"
                      placeholder="Please Enter Text"
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <LockIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                      mb={2}
                      className="mt-30px"
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" className="secondaryButton">
                    Cancel
                  </Button>
                  <Button
                    autoFocus
                    onClick={handleTranslationClose}
                    className="blue-greenBtn"
                  >
                    Add
                  </Button>
                </DialogActions>
              </BootstrapDialog>
            </React.Fragment>
            <Search className="searchInput d-flex justify-content-end align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            </Search>
          </div>

          <TranslationTable></TranslationTable>
        </div>
      </CustomTabPanel>
      {/* third tab section */}
      <CustomTabPanel value={value} index={2}>
        <div>
          <Broadcast></Broadcast>
        </div>
      </CustomTabPanel>
    </Box>
  );
}
