import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";

import config from "../config/config";
import Register from "../components/register";
import Clinical from "../components/clinical";
import Medical from "../components/medical";
import Labvalues from "../components/labvalues";
import Complications from "../components/complications";
import PersistentDrawerLeft from "../components/drawer";

const initialValues = config.initialValues;

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  dateOfBirth: Yup.string().required("Date of Birth is required"),
  educationLevel: Yup.string().required("Education Level is required"),
  occupation: Yup.string().required("Occupation is required"),
});

const useStyles = styled((theme) => ({
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function AddBotConfig() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ["Step 1", "Step 2", "Step 3"];

  function getStepContent(stepIndex, values, handleChange, handleBlur) {
    switch (stepIndex) {
      case 0:
        return (
          <Register
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
      case 1:
        return (
          <Clinical
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
      case 2:
        return (
          <Labvalues
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
      case 3:
        return <Medical />;
      case 4:
        return <Complications />;
      default:
        return "Unknown stepIndex";
    }
  }

  const handleSubmit = (values, actions) => {
    // console.log("Form submitted:", values);
    actions.setSubmitting(false);
  };

  return (
    <>
      <PersistentDrawerLeft />
      <div className="main-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          style={{ marginRight: "-11px" }}
        >
          {({ values, handleChange, handleBlur, touched, errors }) => (
            <Form
              style={{ marginRight: "-11px" }}
              className="additionalDataSection"
            >
              {/* <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper> */}

              <Grid container spacing={2} className="m-0">
                <Grid item xs={12} className="pl-0px">
                  {/* {console.log("values", values, initialValues)} */}
                  <Typography className={classes.instructions}>
                    {getStepContent(
                      activeStep,
                      values,
                      handleChange,
                      handleBlur
                    )}
                  </Typography>

                  {/* <Grid container spacing={2} className="m-0">
                    <Grid item xs={12} className="pl-0px">
                      <div className="mt-20px singlequestiondiv text-center stepperButton">
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className="button back-button"
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={
                            activeStep === steps.length - 1
                              ? handleSubmit
                              : handleNext
                          }
                          className="button main-stepperButton"
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddBotConfig;
