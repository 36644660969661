import React from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment,
    TextField,
    Button,
} from "@mui/material";
import Swal from "sweetalert2";
import { post } from "../../apiServices/api";

// Move styled components outside of the functional component
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4FD1C5",
    },
}));

const validationSchema = Yup.object({
    countryCode: Yup.string().required("Country code is required"),
    number: Yup.string().matches(/^[0-9]{10}$/, 'Enter a Valid Number ').required("Number is required"),
    name: Yup.string().required("Name is required"),
});

const AddNewBotUser = ({ 
    openAddPreview, 
    setOpenAddPreview,
    fetchBotUserDetails
 }) => {
    const userName = useSelector((state) => state?.user?.userDetails?.user?.username);
    const previewBotId = useSelector((state) => state?.bot?.previewBotIDSelected);
    const handleAddPreviewClose = () => {
        setOpenAddPreview(false);
        formik.resetForm()
    };

    const formik = useFormik({
        initialValues: {
            countryCode: '',
            number: '',
            name: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            addBotUserDetails(values);
            handleAddPreviewClose();
        },
    });

    const mapBotUserToSandBox = async () => {
        let payload = {
            "botId": previewBotId,
            "sandboxId": 1,
            "roleId": 6
        }
        try {
            const response = await post(`botconfig/mapBotToSandbox`, payload);
            if (response.status === 200) {
                fetchBotUserDetails(previewBotId)
            //   fetchBotDetailsList();
            //   dispatch(setBotDetails({ botid: response?.data?.botConfigurationId }));
              Swal.fire({
                title: "",
                text: "User details added",
                icon: "success",
                confirmButtonText: "OK",
              });
            } else {
            //   console.log("else>>>", response);
              Swal.fire("Error", response.message, "error");
            }
          } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    }

    const addBotUserDetails = async (values) => {
        // Handle the form values here
        // console.log("Form values: ", values);
        // Add your logic to handle the form values
        let payload = {
            "country_code": values.countryCode,
            "createdBy": userName,
            "bot_id": previewBotId,
            "roleId": 6,
            "socialuid": values.number,
            "username": values.name,
            "activeflag":true,
        }
        try {
            const response = await post(`botconfig/add-bot-user-details`, payload);
            if (response.status === 200) {
                mapBotUserToSandBox()
            } else {
            //   console.log("else", response);
              Swal.fire("Error", response.message, "error");
            }
          } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    };

    return (
        <BootstrapDialog
            onClose={handleAddPreviewClose}
            aria-labelledby="customized-dialog-title"
            open={openAddPreview}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Add
                <IconButton
                    aria-label="close"
                    onClick={handleAddPreviewClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent dividers>
                    <div className="mt-10px">
                        <CustomTextField
                            id="countryCode"
                            name="countryCode"
                            label="Country Code"
                            placeholder="Please Enter Country code"
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.countryCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            
                            error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                            helperText={formik.touched.countryCode && formik.errors.countryCode}
                        />
                        <CustomTextField
                            id="number"
                            name="number"
                            label="Number"
                            placeholder="Please Enter Number"
                            variant="outlined"
                            fullWidth
                            required
                            type="number"
                            value={formik.values.number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            
                            error={formik.touched.number && Boolean(formik.errors.number)}
                            helperText={formik.touched.number && formik.errors.number}
                            className="mt-30px"
                        />
                        <CustomTextField
                            id="name"
                            name="name"
                            label="Username"
                            placeholder="Please Enter Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            className="mt-30px"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        className="secondaryButton"
                        onClick={handleAddPreviewClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        autoFocus
                        type="submit"
                        className="blue-greenBtn"
                    >
                        Add
                    </Button>
                </DialogActions>
            </form>
        </BootstrapDialog>
    );
};

export default AddNewBotUser;
