import NestedAccordion from "./nestedAccordion";

const ConnectDataAccordian = ({connectDataTransformed, fetchHeaderData, formikInitialValues, allQuestion}) => {
    

    return (
        <NestedAccordion 
            items={connectDataTransformed} 
            fetchHeaderData={fetchHeaderData}
            formikInitialValues={formikInitialValues}
            allQuestion={allQuestion}
        />
    )
}

export default ConnectDataAccordian