import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete, Upload } from "@mui/icons-material";
import Swal from "sweetalert2";
import { post, postDjango } from "../../apiServices/api";
import { useDispatch } from "react-redux";
import { setBotDetails } from "../../redux/features/create_bot/botDetails";

export default function BotCreationTable({
  botDetailList,
  fetchBotDetailsList,
  setFormData,
  setActiveTab,
}) {
  const [rowData, setRowData] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      headerClassName: "header-padding", // Apply CSS class
      renderCell: (params) => (
        <div style={{ paddingLeft: 9 }}>{params.value}</div>
      ),
    },
    { field: "botName", headerName: "Bot name", width: 210 },
    { field: "TypeofBot", headerName: "Type of Bot", width: 210 },
    {
      field: "botdescription",
      headerName: "Bot Description",
      width: 210,
    },
    {
      field: "BotIcon",
      headerName: "Bot Icon",
      sortable: false,
      width: 280,
      valueGetter: (value, row) =>
        `${row.botName || ""} ${row.TypeofBot || ""}`,
    },

    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        const onDelete = async () => {
          // console.log("delete paramas", params)
          try {
            const response = await post(`botconfig/deleteBotConfiguration`, {
              botConfigurationId: [params?.id],
            });
            if (response.status === 200) {
              fetchBotDetailsList();
              deleteCollection(params?.id);
              Swal.fire({
                title: "",
                text: "Bot deleted successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire("Error", "Failed to fetch data", "error");
            }
          } catch (error) {
            Swal.fire("Error", error.message, "error");
          }
        };

        const onEdit = () => {
          const getEditData = filterTransformValueOnEdit(params.id);
          setFormData(getEditData[0]);
        };

        const onUploadClick = () => {
          dispatch(setBotDetails({ botid: params.id }));
          setActiveTab(1);
        };

        return (
          <div className="d-flex">
            <Upload
              onClick={onUploadClick}
              style={{
                cursor: "pointer",
                marginRight: 10,
                color: "#5ecec1",
                backgroundColor: "#dcf5f2",
                borderRadius: "50px",
                padding: "6px",
                fontSize: "30px",
              }}
            />
            <Edit
              onClick={onEdit}
              style={{
                cursor: "pointer",
                marginRight: 10,
                color: "#5ecec1",
                backgroundColor: "#dcf5f2",
                borderRadius: "50px",
                padding: "6px",
                fontSize: "30px",
              }}
            />
            {
              <Delete
                onClick={onDelete}
                style={{
                  cursor: "pointer",
                  color: "#5ecec1",
                  backgroundColor: "#dcf5f2",
                  borderRadius: "50px",
                  padding: "6px",
                  fontSize: "30px",
                }}
              />
            }
          </div>
        );
      },
    },
  ];

  const deleteCollection = async (bot_id) => {
    try {
      const response = await postDjango(`deletealluploadedentry/`, {
        bot_id: "Bot" + bot_id,
      });
      if (response.status === 200) {
        // Swal.fire({
        //   title: "",
        //   text: "Embedding deleted successfully",
        //   icon: "success",
        //   confirmButtonText: "OK",
        // });
        console.log("Embedding deleted successfully");
      } else {
        // Swal.fire("Error", "Failed to fetch data", "error");
      }
    } catch (error) {
      // Swal.fire("Error", error.message, "error");
    }
  };

  const transformBotListData = () => {
    let rowData = botDetailList.map((item) => ({
      id: item.botid,
      TypeofBot: item.type,
      botName: item.name,
      botdescription: item.description,
    }));
    setRowData(rowData);
  };

  const filterTransformValueOnEdit = (id) => {
    const filterData = botDetailList.filter((item) => item.botid == id);
    // console.log("botDetailList", botDetailList, id, filterData)
    return filterData;
  };

  useEffect(() => {
    transformBotListData();
  }, [botDetailList]);

  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="botCreationTable"
    >
      <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        }}}
        rows={rowData}
        columns={columns}
        autoHeight
        pageSize={5}
        // checkboxSelection
        style={{ width: "100%" }}
        sx={{ "& .MuiDataGrid-root": { backgroundColor: "#ffffff" } }} // Override background color for DataGrid
      />
    </div>
  );
}
