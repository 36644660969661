import * as React from "react";
import { useContext } from "react";
import { Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import ConnectDataDialog from "./connectDataDialog";
import NestedAccordion from "./nestedAccordion";

import { post } from "../../apiServices/api";
import Swal from "sweetalert2";
import { FunctionContext } from "./connectData";

const AccordionItem = ({ key, item, fetchHeaderData }) => {
    const {handleFunction, setShowLoader} = useContext(FunctionContext);
    const [open, setOpen] = React.useState(false);
    const [parentId, setParantId] = React.useState("");
    const [itemData, setItemData] = React.useState([]);
    const [isActive, setIsActive] = React.useState(true)

    const addChildData = (parentId) => {
        setItemData([])
        setParantId(parentId.toString())
        setOpen(true)
    }

    const handleEditConnectData = (item) => {
  
        const { attributes } = item;
        // Extract files from the API response
        const files = attributes?.upload_media?.data?.map(file => ({
            ...file.attributes,
            url: `${process.env.REACT_APP_API_URL}${file.attributes.url}`
        })) || [];

        const objectForFormik = {
            "title" : item?.attributes?.title,
            "summary": item?.attributes?.summary,
            "mediaType": item?.attributes?.media_type,
            files: files
        }
        const combinedObj = {
            "objectForFormik": objectForFormik,
            "item": item
        }
        
        setItemData(combinedObj)
        setParantId("")
        setOpen(true)
    }

    const handleDeleteConnectData = async (item) => {
        const deleteItemAndChildren = async (item) => {
            // Recursively delete children first
            setShowLoader(true)
            if (item.children && item.children.length > 0) {
                for (const child of item.children) {
                    await deleteItemAndChildren(child);
                }
            }
            // Delete the item itself
            try {
                let response = await post('/cms/deleteCms', { url: `/connect-datas/${item.id}` });
                if (response) {
                    console.log("Deleted item:", item.id);
                }
            } catch (error) {
                Swal.fire("Error", `Error deleting item with id ${item.id}: ${error.message}`, "error");
            }
        };
    
        try {
            await deleteItemAndChildren(item);
            handleFunction(); // Refresh the UI or data after deletion
            setShowLoader(false)
        } catch (error) {
            Swal.fire("Error", `Failed to delete item and its children: ${error.message}`, "error");
            setShowLoader(false)
        }
    };
    

    const updateStatus = async (item) => {
        const payload = {
            "data" : {"is_active": !item?.attributes?.is_active }
        }
        try {
            let payloadJson = {
                url: `/connect-datas/${item.id}`,
                payload: payload
              }
            let response = await post(`/cms/putCms`, payloadJson);
            
            
            if (response) {
                console.log('Edited Response ',response)
                handleFunction()
                // fetchHeaderData()
                // setConnectData(response.data)
                // setConnectDataTransformed(transformedData)
            }
        } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    }

    return (
        <>
        <Accordion>
            <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                {item?.attributes?.title}
                <div className="ml-auto d-flex justify-contents-end align-items-center actionAccordion">
                    <ArrowUpwardOutlinedIcon></ArrowUpwardOutlinedIcon>
                    <ArrowDownwardOutlinedIcon></ArrowDownwardOutlinedIcon>
                    <EditOutlinedIcon
                        onClick={(event) => {
                            event.stopPropagation();
                            handleEditConnectData(item)
                        }}
                    ></EditOutlinedIcon>
                    <DeleteOutlinedIcon 
                       onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteConnectData(item)
                    }} 
                    />

                    <AddOutlinedIcon 
                        onClick={() =>addChildData(item?.id)}
                    />
                    <Button variant="outlined"
                     className={item?.attributes?.is_active ? "activeBtn" : "inactiveBtn"}
                     onClick={() => updateStatus(item)}
                     >
                        {item?.attributes?.is_active ? 'Active' : 'Inactive'}
                    </Button>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {item?.attributes?.summary}
                {item.children && item.children.length > 0 && (
                    <NestedAccordion items={item.children} />
                )}
            </AccordionDetails>
        </Accordion>

        <ConnectDataDialog 
            open = {open}
            setOpen = {setOpen}
            parentId = {parentId}
            editData = {itemData}
            isActive = {isActive}
            fetchHeaderData= {handleFunction}
            setItemData={setItemData}
        />
        </>
    )
}

export default AccordionItem