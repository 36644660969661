import * as React from "react";
import { useContext } from "react";
import { Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import ConnectDataDialog from "./connectDataDialog";
import NestedAccordion from "./nestedAccordion";

import { post } from "../../apiServices/api";
import Swal from "sweetalert2";
import { FunctionContext } from "./surveyAccordian";

const AccordionItem = ({ key, item, fetchHeaderData, formikInitialValues }) => {
    const { handleFunction, allQuestion, setShowLoader } = useContext(FunctionContext);
    const [open, setOpen] = React.useState(false);
    const [parentId, setParantId] = React.useState("");
    const [itemData, setItemData] = React.useState([]);
    const [isActive, setIsActive] = React.useState(true)

    const addChildData = (parentId) => {
        setItemData([])
        setParantId(parentId.toString())
        setOpen(true)
    }

    const handleEditConnectData = (item) => {

        const { attributes, id } = item;
        const objectForFormik = {
            questionName: attributes.question_name || "",
            questionType: attributes.question_type || "",
            options: attributes.question_option
                ? attributes.question_option.map((option) => {
                    return option.options_names;
                })
                : [],
            mandatory: attributes.mandatory || false,
            section: attributes.is_section || false,
            parentQuestion: attributes.map_parent_survey?.data
                ? {
                    label:
                        attributes.map_parent_survey.data?.attributes?.question_name ||
                        "",
                    id: attributes.map_parent_survey.data.id || "",
                }
                : null,
            dependentValues: attributes.dependent_option_value
                ? attributes.dependent_option_value.map((options) => {
                    return { label: options?.dependent_value, id: options?.id };
                })
                : [],
            id: id,
        }
        const combinedObj = {
            "objectForFormik": objectForFormik,
            "item": item
        }

        setItemData(combinedObj)
        setParantId("")
        setOpen(true)
    }

    const handleDeleteConnectData = async (item) => {
        setShowLoader(true)
        const deletedItems = new Set();
    
        const deleteItemAndChildren = async (item) => {
            // Recursively delete children first
            if (item.children && item.children.length > 0) {
                for (const child of item.children) {
                    await deleteItemAndChildren(child);
                }
            }
    
            // Delete the item itself if it hasn't been deleted already
            if (!deletedItems.has(item.id)) {
                try {
                    let response = await post('/cms/deleteCms', { url: `/surveys/${item.id}` });
                    if (response) {
                        deletedItems.add(item.id);
                    }
                } catch (error) {
                    Swal.fire("Error", `Error deleting item with id ${item.id}: ${error.message}`, "error");
                }
            }
        };
    
        try {
            await deleteItemAndChildren(item);
            handleFunction(); // Refresh the UI or data after deletion
            setShowLoader(false)
        } catch (error) {
            Swal.fire("Error", `Failed to delete item and its children: ${error.message}`, "error");
        }
    };
    


    const updateStatus = async (item) => {
        const payload = {
            "data": { "is_active": !item?.attributes?.is_active }
        }
        try {
            let payloadJson = {
                url: `/surveys/${item.id}`,
                payload: payload
            }
            let response = await post(`/cms/putCms`, payloadJson);


            if (response) {
                handleFunction()
                // fetchHeaderData()
                // setConnectData(response.data)
                // setConnectDataTransformed(transformedData)
            }
        } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    }

    return (
        <>
            <Accordion>
                <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {item?.attributes?.question_name}
                    <div className="ml-auto d-flex justify-contents-end align-items-center actionAccordion">
                        <ArrowUpwardOutlinedIcon></ArrowUpwardOutlinedIcon>
                        <ArrowDownwardOutlinedIcon></ArrowDownwardOutlinedIcon>
                        <EditOutlinedIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                handleEditConnectData(item)
                            }}
                        ></EditOutlinedIcon>
                        <DeleteOutlinedIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteConnectData(item)
                            }}
                        />

                        <AddOutlinedIcon
                            onClick={() => addChildData(item?.id)}
                        />
                        <Button variant="outlined"
                     className={item?.attributes?.is_active ? "activeBtn" : "inactiveBtn"}
                     onClick={() => updateStatus(item)}
                     >
                        {item?.attributes?.is_active ? 'Active' : 'Inactive'}
                    </Button>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {item?.attributes?.summary}
                    {item.children && item.children.length > 0 && (
                        <NestedAccordion items={item.children} />
                    )}
                </AccordionDetails>
            </Accordion>
            <ConnectDataDialog
                open={open}
                setOpen={setOpen}
                parentId={parentId}
                editData={itemData}
                isActive={isActive}
                fetchHeaderData={handleFunction}
                setItemData={setItemData}
                allQuestion={allQuestion}
                setParantId={setParantId}
            />
        </>
    )
}

export default AccordionItem