import NestedAccordion from "./nestedAccordion";

const ConnectDataAccordian = ({connectDataTransformed, fetchHeaderData}) => {
    

    return (
        <NestedAccordion 
            items={connectDataTransformed} 
            fetchHeaderData={fetchHeaderData}
        />
    )
}

export default ConnectDataAccordian