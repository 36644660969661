import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete } from "@mui/icons-material";
import { Grid,Card,CardContent } from "@mui/material";
const columns = [
  //   { field: "id", headerName: "ID", width: 100 },
  { field: "Label", headerName: "Label", width: 550 },

  {
    field: "Actions",
    headerName: "Actions",
    sortable: false,
    width: 330,
    renderCell: (params) => (
      <div className="d-flex">
        <Edit
          style={{
            cursor: "pointer",
            marginRight: 10,
            backgroundColor: "#dcf5f2",
            color: "#5ecec1",
            borderRadius: "50px",
            padding: "6px",
            fontSize: "30px",
          }}
        />
        <Delete
          style={{
            cursor: "pointer",
            backgroundColor: "#dcf5f2",
            color: "#5ecec1",
            borderRadius: "50px",
            padding: "6px",
            fontSize: "30px",
          }}
        />
      </div>
    ),
  },
];

const rows = [
  {
    id: 1,

    Label: "Jon",
  },
  {
    id: 2,

    Label: "Cersei",
  },
  {
    id: 3,

    Label: "Jaime",
  },
  {
    id: 4,
    Label: "Arya",
  },
  {
    id: 5,
    Label: "Daenerys",
  },
  {
    id: 6,
    Label: "Krista",
  },
  {
    id: 7,
    Label: "Ferrara",
  },
  {
    id: 8,
    Label: "Rossini",
  },
  {
    id: 9,
    Label: "Harvey",
  },
];

export default function DataSetsTable() {
  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="botCreationTable"
    >
      <Grid container spacing={2} className="mt-10px">
     <Grid item xs={12}>
       {/* second section table */}
       <Card className="">
         <CardContent>
           <h4 class="dataHeading mt-0px">DataSets</h4>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={5}
        checkboxSelection
        style={{ width: "100%" }}
        sx={{ "& .MuiDataGrid-root": { backgroundColor: "#ffffff" } }} // Override background color for DataGrid
      />
       </CardContent>
       </Card>
     </Grid>
   </Grid>
    </div>
  );
}
