const imgUrl = {
  smartIcon: require("../img/newimages/ai.png"),
  summaryGif: require("../img/newimages/giphy.webp"),
  amrDashboard: require("../img/newimages/amr_dashbaord.jpg"),
  summaryBotImg: require("../img/newimages/summarybot.png"),
  amrDashboardImg: require("../img/newimages/amr_dash2.jpg"),
  botIcon: require("../img/newimages/botchat.svg"),
  ojoIcon: require("../img/newimages/formsimages/Ojo.png"),
  simiIcon: require("../img/newimages/formsimages/simi.png"),
  dhruvIcon: require("../img/newimages/formsimages/dhruv.png"),
  dhristiIcon: require("../img/newimages/formsimages/drsihthi.png"),
  qrCode: require("../img/newimages//botpreviewqrcode.png"),
  whatsappIcon: require("../img/newimages/whatsapp.png"),
  fbIcon: require("../img/newimages/facebook.png"),
  vitalAI: require("../img/newimages/vitalAI.gif"),
  previewDetails: require("../img/newimages/previewdetails.gif"),
  verifyIcon: require("../img/newimages/check-mark.png"),

  uploadFileIcon: require("../img/icons/uploadfile.png"),
  connectDataIcon: require("../img/icons/connectdata.png"),
  registrationIcon: require("../img/icons/registration.png"),
  surveyIcon: require("../img/icons/survey.png"),
  nearMeIcon: require("../img/icons/nearme.png"),
};
export default imgUrl;
