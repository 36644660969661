import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete } from "@mui/icons-material";

const columns = [
  //   { field: "id", headerName: "ID", width: 100 },
  { field: "TableName", headerName: "TableName", width: 330 },
];

const rows = [
  {
    id: 1,

    TableName: "ABC",
  },
  {
    id: 2,

    TableName: "XYZ",
  },
  {
    id: 3,

    TableName: "Jaime",
  },
  {
    id: 4,
    TableName: "Arya",
  },
  {
    id: 5,
    TableName: "Daenerys",
  },
];

export default function DatabaseTableList() {
  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="botCreationTable"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={5}
        checkboxSelection
        style={{ width: "100%" }}
        sx={{ "& .MuiDataGrid-root": { backgroundColor: "#ffffff" } }} // Override background color for DataGrid
      />
    </div>
  );
}
