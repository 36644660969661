import { createSlice } from '@reduxjs/toolkit';
export const testSlice = createSlice({
  name: 'test',
  initialState: {
    count: 0,
  },
  reducers: {
    increment: (state, actions) => {
      state.count = state.count + 1;
    },
    decrement: (state, actions) => {
      state.count = state.count - 1;
    },
  },
});
export const { increment, decrement } = testSlice.actions;
export default testSlice.reducer;
