import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Backdrop,
  IconButton,
  Grid,
  TextField,
  Typography,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { postFormDataDjango, postDjango } from "../../apiServices/api";
import styled from "@emotion/styled/macro";
import CmsUploadFileTable from "./cmsuploadfiletable";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../Loader/Loader";

// //Custom Bakcdrop Loader UI
// const BlurBackdrop = styled(Backdrop)({
//   backdropFilter: 'blur(10px)',
//   backgroundColor: 'rgba(255, 255, 255, 0.5)',
//   zIndex: 1300, // Ensure it's above other elements
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
// });
// //Custom Linear Progress Bar
// const StyledLinearProgress = styled(LinearProgress)(({ color }) => ({
//   width: '80%',
//   height:"2%",

//   marginTop: 16,
//   '& .MuiLinearProgress-bar': {
//     backgroundColor: color === 'error' ? 'red' : 'turquoise',
//   },
// }));

// custom textfield ui
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

const MAX_FILES = 20;
const MAX_FILE_SIZE_MB = 50;

// const UploaderLoader=({loadingState})=>{
//   return(
//     <BlurBackdrop open={loadingState}>
//       <CircularProgress color="inherit" />
//     </BlurBackdrop>
//   )
// }
const BlurBackdrop = styled(Backdrop)({
  backdropFilter: "blur(1px)",
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  zIndex: 1300, // Ensure it's above other elements
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

// Custom CircularProgress with Specific Color
const StyledCircularProgress = styled(CircularProgress)({
  color: "#4FD1C5",
});

const UploaderLoader = ({ loadingState }) => {
  return (
    <BlurBackdrop open={loadingState}>
      <StyledCircularProgress size={"5rem"} />
    </BlurBackdrop>
  );
};
const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [embeddingDetails, setEmbeddingDetails] = useState([]);
  const botId = useSelector((state) => state?.bot?.botDetails?.botid);
  const LowAccuracyVal = "512";
  const MediumAccuracyVal = "256";
  const HighAccuracyVal = "128";
  useEffect(() => {
    fetchData();
  }, [botId]);

  useEffect(() => {
    checkFilesExist();
  }, [files, embeddingDetails]);

  const fetchData = useCallback(async () => {
    try {
      const response = await postDjango(`getallembeddingdetails`, {
        bot_id: "Bot" + botId,
      });
      if (response.status === 200) {
        if (response.response == null) {
          setEmbeddingDetails([]);
          return;
        }
        setEmbeddingDetails(response.response);
        // console.log(`Embeding details are ${response.response}`);
      } else {
        Swal.fire("Error", "Failed to fetch data", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  }, []);

  const checkFileNameExists = (fileName) => {
    for (const item of embeddingDetails) {
      if (item.fields && item.fields.file_name === fileName) {
        return item; // Return the item instead of true
      }
    }
    return null; // Return null if the file name does not exist
  };

  const checkFilesExist = () => {
    const existingFiles = files.filter((fileData) => {
      const existingItem = checkFileNameExists(
        fileData.file.name.replace(/ /g, "_")
      );
      return existingItem && !fileData.replace; // Check if the file exists and does not have the replace flag
    });

    if (existingFiles.length > 0) {
      const fileNames = existingFiles
        .map((fileData) => fileData.file.name)
        .join(", ");
      Swal.fire({
        title: "Warning",
        text: `The following file(s) already exist: ${fileNames}`,
        icon: "warning",
        confirmButtonText: "OK",
      });
    }

    setSubmitDisabled(existingFiles.length > 0);
  };

  const getSelectedFileLength = (files) => {
    let totalSize = 0;
    let calculatedSize = files.map((fileObj) => {
      totalSize += fileObj.file.size;
    });
    return totalSize;
  };

  const onDrop = (acceptedFiles) => {
    const totalFiles = files.length + acceptedFiles.length;

    if (totalFiles > MAX_FILES) {
      Swal.fire(
        "Warning",
        `Cannot upload more than ${MAX_FILES} files.`,
        "warning"
      );
      return;
    }

    const duplicateFiles = acceptedFiles.filter((newFile) =>
      files.some((existingFile) => existingFile.file.name === newFile.name)
    );

    if (duplicateFiles.length > 0) {
      const duplicateFileNames = duplicateFiles
        .map((file) => file.name)
        .join(", ");
      Swal.fire(
        "Warning",
        `The following file(s) are duplicates and were not added: ${duplicateFileNames}`,
        "warning"
      );
      return;
    }

    const validFiles = acceptedFiles
      .filter((file) => {
        const validTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        const fileType = file.type;

        if (!validTypes.includes(fileType)) {
          Swal.fire(
            "Warning",
            `File "${file.name}" is not a PDF or Word document and was not added.`,
            "warning"
          );
          return false;
        }

        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
          Swal.fire(
            "Warning",
            `File "${file.name}" exceeds the size limit of ${MAX_FILE_SIZE_MB}MB and was not added.`,
            "warning"
          );
          return false;
        }

        return true;
      })
      .map((file) => ({
        file,
        title: "",
        theme: "",
        accuracy: MediumAccuracyVal,
      }));

    setFiles([...files, ...validFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: MAX_FILES,
    // maxSize: MAX_FILE_SIZE_MB * 1024 * 1024,
  });

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleReplaceFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles[index] = { ...updatedFiles[index], replace: true };
    setFiles(updatedFiles);
    setSubmitDisabled(false);
  };

  const handleInputChange = (index, field, value) => {
    const updatedFiles = [...files];
    updatedFiles[index][field] = value;
    setFiles(updatedFiles);
  };

  const uploadFileMaxSizeExceed = async (files) => {
    setSubmitDisabled(true);

    try {
      for (const [index, fileData] of files.entries()) {
        const formData = new FormData();
        formData.append(`files[${index}][file]`, fileData.file);
        formData.append(`files[${index}][title]`, fileData.title);
        formData.append(`files[${index}][theme]`, fileData.theme);
        formData.append(`files[${index}][accuracy]`, fileData.accuracy);
        formData.append(`files[${index}][botId]`, "Bot" + botId);
        formData.append(
          `files[${index}][replace]`,
          fileData.replace ? true : false
        );

        const response = await postFormDataDjango("reactupload/", formData);
        if (response.status !== 200) {
          throw new Error(`Failed to upload file: ${fileData.title}`);
        }
      }
      fetchData();
      Swal.fire("Success", "All files uploaded successfully!", "success");
      setFiles([]); // Clear files list after successful upload
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    } finally {
      setSubmitDisabled(false);
    }
  };

  const handleSubmit = async () => {
    // console.log('Handle Submit Clicked')
    setSubmitDisabled(true);
    // console.log(`Show Loader Set to True at Uploading file `)
    setShowLoader(true);
    if (getSelectedFileLength(files) > MAX_FILE_SIZE_MB * 1024 * 1024) {
      uploadFileMaxSizeExceed(files);
      return;
    }
    const formData = new FormData();
    files.forEach((fileData, index) => {
      formData.append(`files[${index}][file]`, fileData.file);
      formData.append(`files[${index}][title]`, fileData.title);
      formData.append(`files[${index}][theme]`, fileData.theme);
      formData.append(`files[${index}][accuracy]`, fileData.accuracy);
      formData.append(`files[${index}][botId]`, "Bot" + botId);
      if (fileData.replace) {
        formData.append(`files[${index}][replace]`, true);
      } else {
        formData.append(`files[${index}][replace]`, false);
      }
    });
    // console.log("Files ",files)

    try {
      const response = await postFormDataDjango("reactupload/", formData);
      if (response.status === 200) {
        fetchData();
        // console.log(`Loader Set False at Success`)
        setShowLoader(false);
        Swal.fire("Success", "Files uploaded successfully!", "success");
        setFiles([]); // Clear files list after successful upload
      }
    } catch (error) {
      // console.log(`Loader Set False at Catch Error`)
      setLoadError(true);
      setShowLoader(false);

      Swal.fire("Error", error.message, "error");
    } finally {
      // console.log(`Loader Set False at Finally Block`)
      setLoadError(false);
      setShowLoader(false);
      setSubmitDisabled(false);
    }
  };

  return (
    <div className="uploadSection">
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed grey",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
        className="dragSection"
      >
        <input {...getInputProps()} />
        <CloudUploadIcon
          sx={{
            fontSize: 50,
            color: "grey",
          }}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Drag & Drop files here
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          or
        </Typography>
        <Button
          variant="contained"
          component="span"
          sx={{ mt: 2 }}
          className="browseBtn text-capitalize"
        >
          Browse Files
        </Button>
      </Box>
      <div className="listFilesSection">
        <div className="uploadFileSection">
          {files.map((fileData, index) => (
            <>
              <div
                key={index}
                style={{ marginBottom: "1rem" }}
                className="section-upload"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <Typography variant="h6" className="fileLabel">
                    {`${fileData.file.name} (${(
                      fileData.file.size /
                      (1024 * 1024)
                    ).toFixed(2)} MB)`}
                  </Typography>

                  <span className="action-button">
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>

                    {checkFileNameExists(
                      fileData.file.name.replace(/ /g, "_")
                    ) && (
                      <IconButton
                        onClick={() => handleReplaceFile(index)}
                        color="primary"
                      >
                        <SwapHorizIcon />
                      </IconButton>
                    )}
                  </span>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <CustomTextField
                      label="Title"
                      variant="outlined"
                      fullWidth
                      value={fileData.title}
                      onChange={(e) =>
                        handleInputChange(index, "title", e.target.value)
                      }
                      style={{
                        marginBottom: "0.5rem",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <CustomTextField
                      label="Theme"
                      variant="outlined"
                      fullWidth
                      value={fileData.theme}
                      onChange={(e) =>
                        handleInputChange(index, "theme", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    {/* <FormControl fullWidth variant="outlined">
                    <InputLabel id={`accuracy-label-${index}`}>
                      Accuracy
                    </InputLabel>
                    <Select
                      labelId={`accuracy-label-${index}`}
                      id={`accuracy-select-${index}`}
                      value={fileData.accuracy}
                      onChange={(e) =>
                        handleInputChange(index, "accuracy", e.target.value)
                      }
                      label="Accuracy"
                      className="select-accuracy"
                    ></Select>
                  </FormControl> */}
                    <CustomTextField
                      labelId={`accuracy-label-${index}`}
                      id={`accuracy-select-${index}`}
                      select
                      value={fileData.accuracy}
                      onChange={(e) =>
                        handleInputChange(index, "accuracy", e.target.value)
                      }
                      label="Accuracy"
                      className="select-accuracy"
                      // defaultValue={"256"}
                    >
                      <MenuItem value={LowAccuracyVal}>Low</MenuItem>
                      <MenuItem value={MediumAccuracyVal}>Medium</MenuItem>
                      <MenuItem value={HighAccuracyVal}>High</MenuItem>
                    </CustomTextField>
                  </Grid>
                </Grid>
              </div>
            </>
          ))}
        </div>
      </div>
      {files.length > 0 && (
        <Button
          variant="contained"
          onClick={handleSubmit}
          startIcon={<UploadFileIcon />}
          disabled={submitDisabled}
          className="btn-upload btn blue-greenBtn"
        >
          Upload Files
        </Button>
      )}
      <CmsUploadFileTable
        rows={embeddingDetails}
        fetchData={fetchData}
        bot_id={botId}
      />
      <Loader loadingState={showLoader} error={loadError} />
    </div>
  );
};

export default FileUpload;
