import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthContext, AuthContextProvider } from './context/AuthContext';
import { preRoutes } from './router/preLoginRoutes';
import { postRoutes } from './router/postLoginRoutes';
import { Provider } from 'react-redux';
import { store } from './redux/store';
// import CmsUploadFile from "./pages/cmsuploadfile";

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div className="App">
      <Router basename="/app">
        {isLoggedIn() == true ? (
          <Routes>
            {postRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        ) : (
          <Routes>
            {preRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthContextProvider>
  </React.StrictMode>
);
