import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from '@mui/system';
import imgUrl from '../img/imgUrl'; // Adjust the import as needed
import Swal from 'sweetalert2';
import { post } from '../apiServices/api';
import { AuthContext } from '../context/AuthContext';
import { encryptData, decryptData } from '../encrypt/encrypt';
import { useSelector, useDispatch } from 'react-redux';
import { setUserDetails, clearUserDetails } from '../redux/features/user/userDetails';
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#4FD1C5', // Change this to your desired focused border color
  },
}));

function Home() {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login } = useContext(AuthContext);
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const submitLogin = async (loginDetails) => {
    try {
      const response = await post(`user/userLogin`, loginDetails);
      if (response.status === 200) {
        login();
        const encryptedUserDetails = encryptData(response.data);
        localStorage.setItem('userDetails', encryptedUserDetails);
      }
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };

  const handleSubmit = () => {
    if (!username || !password) {
      setError('Both fields are required.');
      return;
    } else {
      submitLogin({
        emailId: username,
        password: password,
      });
    }

    setError('');
    //
  };

  return (
    <div className="loginpage">
      <Grid container spacing={2} className="m-0">
        <Grid item xs={1} sm={0} lg={0} md={0} className="pl-0px"></Grid>
        <Grid item xs={12} sm={4} lg={4} md={4} className="pl-0px">
          <div className="logincard">
            <Grid container spacing={2} className="m-0">
              <Grid item xs={12} className="pl-0px pt-0px">
                <p className="text-center mt-0px welcomeLabel mb-10px">
                  <img
                    src={imgUrl.smartIcon}
                    className="mx-4px logo-main"
                    alt="Smart Icon"
                  />
                  Welcome to Vital Assistance{' '}
                </p>
                <p className="logincardtitle text-center">Sign In</p>
                <div>
                  <div>
                    <CustomTextField
                      id="username"
                      label="Email Address"
                      variant="outlined"
                      fullWidth
                      value={username}
                      onChange={handleUsernameChange}
                      InputProps={{
                        startAdornment: <AccountCircleOutlinedIcon />,
                      }}
                      required
                    />
                  </div>
                  <div className="mt-30px">
                    <CustomTextField
                      id="password"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      InputProps={{
                        startAdornment: <LockOutlinedIcon />,
                      }}
                      required
                    />
                    {/*My Code */}
                    {/* <CustomTextField
                      type={showPassword ? "text" : "password"}
                      id="standard-basic"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      // size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                  </div>
                  {error && (
                    <p className="error-message" style={{ color: 'red' }}>
                      {error}
                    </p>
                  )}
                  <div>
                    <Button
                      variant="contained"
                      className="loginbtn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                  <div>
                    <p className="mb-0 fa-12px color-primary-black">
                      Don’t have an account?
                      <span
                        className="main-color cursor-pointer"
                        onClick={() => navigate('/signup')}
                      >
                        {' '}
                        Sign Up
                      </span>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={0} sm={6} lg={8} md={6}>
          {/* <div className="loginpgimg"></div> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
