import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useContext } from "react";
import {
  Tabs,
  Tab,
  Box,
  Button,
  InputAdornment,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Autocomplete,
  Switch,
  FormControlLabel,
  IconButton as MuiIconButton,

} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFormik, FieldArray, FormikProvider, Form, Field } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

import { post, postFormData } from "../../apiServices/api";

// icons import
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FunctionContext } from "./surveyAccordian";
import Loader from "../Loader/Loader";
// Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Dialog styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// Custom TextField styles
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5",
  },
}));

const validationSchema = Yup.object().shape({
  questionName: Yup.string().required("Question Name is required"),
});

const ConnectDataDialog = ({
  open,
  setOpen,
  aiSurveySwitch,
  setAISurveySwitch,
  isHeader = false,
  parentId,
  fetchHeaderData,
  editData = [],
  isActive,
  setItemData,
  formEditState,
  allQuestion,
  formikInitialValues,
  setParantId,
  generateAiSurvey
  
}) => {
  const [valueLang, setValueLang] = useState(0);
  // const [initialValues, setInitialValues] = useState({
  //   title: "",
  //   summary: "",
  //   mediaType: "",
  //   files: [],
  // });
  const handleFunction = useContext(FunctionContext);
  const [iSHeader, setIsHeader] = useState(isHeader);
  const [editParentId, setEditParentId] = useState("");
  const [acceptTypes, setAcceptTypes] = useState([]);
  const [files, setFiles] = useState([]);

  const [parentQuestionList, setParentQuestionList] = React.useState([]);
  const [dependentOptionList, setDependentOptionList] = React.useState([]);

  const [initialValues, setInitialValues] = useState(formikInitialValues || {
    questionName: "",
    questionType: "",
    options: [],
    mandatory: false,
    aiSuggestions:true,
    section: false,
    dependentValues: [],
  })

  const [showLoader, setShowLoader] = useState(false);
  const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);
  const botId = useSelector((state) => state?.bot?.botDetails?.botid);

  // Formik setup
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitSurveyQuestion(values);
    },
  });

  useEffect(() => {
    if (editData.objectForFormik != undefined) {
      setInitialValues(editData.objectForFormik);
    }

    if (editData.length == 0) {
      setInitialValues({
        questionName: "",
        questionType: "",
        options: [],
        mandatory: false,
        aiSuggestions:true,
        section: false,
        dependentValues: [],
      })
    }
  }, [editData.objectForFormik]);

  useEffect(() => {
    if (parentId != undefined) {
      transformDependentOptionList(
        allQuestion
      );
    }
  }, [parentId, allQuestion]);

  const transformOptions = (options) => {
    return options.map((option) => {
      return {
        options_names: option,
      };
    });
  };

  const transformDependentOption = (options) => {
    return options.map((option) => {
      return {
        dependent_value: option.label,
      };
    });
  };

  const transformParentQuestionList = (allQuestion) => {
    const transformedData = allQuestion.map((item) => ({
      label: item.attributes.question_name,
      id: item.id,
    }));

    setParentQuestionList(transformedData);
  };

  const transformDependentOptionList = (data, targetId) => {
    const filteredItem = data?.find((item) => item.id === parseInt(parentId));
    // If the item with the given ID is found
    if (filteredItem) {
      // Extract the question_option array
      const options = filteredItem.attributes.question_option;

      // Transform each option
      const transformedOptions = options.map((option) => ({
        label: option.options_names,
        id: option.id,
      }));

      setDependentOptionList(transformedOptions);
    }
  };

  const submitSurveyQuestion = async (values) => {
    console.log("AI>>>", values)
    
    if(values?.aiSuggestions) {
      generateAiSurvey(values?.questionName)
      handleClose()
      return
    }
    let payload = {
      data: {
        question_name: values?.questionName,
        is_section: values?.section,
        is_survey_header: false,
        user_id: userId.toString(),
        bot_id: botId.toString(),
        mandatory: values?.mandatory,
        is_active: true
      },
    };
    if (values?.questionType != "") {
      payload.data.question_type = values?.questionType;
    }
    if (values?.options.length > 0) {
      payload.data.question_option = transformOptions(values?.options);
    }
    if (parentId) {
      payload.data.map_parent_survey = parentId;
    }
    if (parentId == undefined) {
      payload.data.is_survey_header = true;
    }
    if (values?.dependentValues.length > 0) {
      payload.data.dependent_option_value = transformDependentOption(
        values?.dependentValues
      );
    }
    try {
      let response;
      let payloadJson = {
        url: editData.item != undefined ? `/surveys/${values?.id}` : `/surveys`,
        payload: payload
      }
      editData.item != undefined
        ? (response = await post(`/cms/putCms`, payloadJson))
        : (response = await post(`/cms/postCms`, payloadJson));

      if (response) {
        handleClose()
        fetchHeaderData();

        // fetchAllQuestionByBotId();
        // setConnectData(response.data)
        // setConnectDataTransformed(transformedData)
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const handleLangChange = (event, newValue) => {
    setValueLang(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();

    if (!isHeader) {
      setParantId("")
    }

  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Survey
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="p-0">
          <div className="modalLangTabs">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  margin: "20px 20px 10px 20px",
                }}
              >
                <Tabs
                  value={valueLang}
                  onChange={handleLangChange}
                  aria-label="basic tabs example"
                  className="subTabsLangModal d-flex justify-content-between"
                >
                  <Tab label="English(US)" {...a11yProps(0)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={valueLang} index={0}>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    {/* Question Name */}
                    <CustomTextField
                      id="surveyQuestionName"
                      name="questionName"
                      label={!isHeader ? "Question Name" : "Survey Name"}
                      placeholder={!isHeader ? "Enter Question Name" : "Enter Survey Name"}
                      variant="outlined"
                      fullWidth
                      required
                      onChange={formik.handleChange}
                      value={formik.values.questionName}
                      mb={2}
                      className="mt-10px"
                      error={
                        formik.touched.questionName &&
                        Boolean(formik.errors.questionName)
                      }
                      helperText={
                        formik.touched.questionName &&
                        formik.errors.questionName
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <LockIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                   
                    {isHeader && <FormControlLabel
                    control={
                        <Switch
                          name="aiSuggestions"
                          checked={formik.values.aiSuggestions}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Use AI Assistance"
                      className="mt-10px"
                    />}
                    {/* Mandatory Switch */}
                    {!isHeader && <FormControlLabel
                      control={
                        
                        <Switch
                          name="mandatory"
                          checked={formik.values.mandatory}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Mandatory"
                      className="mt-10px"
                    />}

                    {/* Mandatory Switch */}
                    {!isHeader && <FormControlLabel
                      control={
                        <Switch
                          name="section"
                          checked={formik.values.section}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Section"
                      className="mt-10px"
                    />}

                    {/* Question Type and Options */}
                    {!isHeader && formik.values.section == false && <CustomTextField
                      id="outlined-surveyQuestion-type"
                      name="questionType"
                      select
                      label="Question Type"
                      placeholder="Select Question Type"
                      value={formik.values.questionType}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("options", [""]);
                      }}
                      helperText="Please select the Question type"
                      className="mt-30px"
                      mb={2}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <LockIcon /> */}
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="Checkbox">Checkbox</MenuItem>
                      <MenuItem value="Radio">Radio</MenuItem>
                      <MenuItem value="Text">Text</MenuItem>
                    </CustomTextField>}
                    {(formik.values.questionType === "Checkbox" ||
                      formik.values.questionType === "Radio") && (
                        <FieldArray
                          name="options"
                          render={(arrayHelpers) => (
                            <Box style={{ marginTop: "7px" }}>
                              {" "}
                              {formik.values.options.map((option, index) => (
                                <div key={index}>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="d-flex justify-content-start align-items-center"
                                  >
                                    <Grid item xs={12} lg={10}>
                                      <CustomTextField
                                        id={`option-${index}`}
                                        name={`options.${index}`}
                                        label={`Option ${index + 1}`}
                                        placeholder={`Enter Option ${index +
                                          1}`}
                                        variant="outlined"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={option}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {/* <LockIcon /> */}
                                            </InputAdornment>
                                          ),
                                        }}
                                        mb={2}
                                        className="mt-20px"
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={2}>
                                      <div className="d-flex justify-content-start align-items-center mt-10px">
                                        <Button
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#5ecec1",
                                            backgroundColor: "#dcf5f2",
                                            borderRadius: "50px",
                                            width: "30px",
                                            height: "30px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ fontSize: "20px" }}
                                          ></DeleteIcon>
                                        </Button>
                                        <Button
                                          onClick={() => arrayHelpers.push("")}
                                          className="ml-5px"
                                          style={{
                                            cursor: "pointer",
                                            color: "#5ecec1",
                                            backgroundColor: "#dcf5f2",
                                            borderRadius: "50px",
                                            width: "30px",
                                            height: "30px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                          }}
                                        >
                                          <AddIcon
                                            style={{ fontSize: "20px" }}
                                          ></AddIcon>
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>

                                  {/* <Button type="button" variant="outlined">
                                    Remove
                                  </Button> */}
                                </div>
                              ))}
                              {/* <Button type="button" variant="outlined">
                                Add Option
                              </Button> */}
                            </Box>
                          )}
                        />
                      )}

                    {/* Dependent Values */}
                    {!isHeader && formik.values.section == false && (
                      <Autocomplete
                        id="dependentValues"
                        multiple
                        options={dependentOptionList.filter(
                          (option) => !formik.values.dependentValues.some(
                            (selected) => selected.id === option.id
                          )
                        )}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) => formik.setFieldValue("dependentValues", value)}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            label="Dependent Values"
                            placeholder="Select Dependent Values"
                            variant="outlined"
                            fullWidth
                            className="mt-30px"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                borderColor: params.focused
                                  ? "#1976d2"
                                  : params.error
                                    ? "#f44336"
                                    : "#e0e0e0",
                              },
                            }}
                            mb={2}
                          />
                        )}
                        value={formik.values.dependentValues}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option.label}
                              {...getTagProps({ index })}
                              className="mt-2"
                            />
                          ))
                        }
                      />
                    )}


                    <div className="d-flex justify-content-end align-items-center mt-30px">
                      <Button
                        variant="contained"
                        className="secondaryButton"
                        style={{ marginRight: "10px" }}
                        type="reset"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        className="blue-greenBtn"
                        type="submit"
                      >
                        {editData.item == undefined ? "Add" : "Edit"}
                      </Button>
                    </div>
                  </Form>
                </FormikProvider>
              </CustomTabPanel>
            </Box>
          </div>
        </DialogContent>
      </BootstrapDialog>
      <Loader loadingState={showLoader} />
    </>
  );
};

export default ConnectDataDialog;
