import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete } from "@mui/icons-material";

const columns = [
  //   { field: "id", headerName: "ID", width: 100 },
  { field: "Label", headerName: "Label", width: 330 },
  { field: "English", headerName: "English", width: 330 },
  {
    field: "French",
    headerName: "French",
    width: 330,
  },

  {
    field: "Actions",
    headerName: "Actions",
    sortable: false,
    width: 130,
    renderCell: (params) => (
      <div className="d-flex">
        <Edit
          style={{
            cursor: "pointer",
            marginRight: 10,
            backgroundColor: "#dcf5f2",
            color: "#5ecec1",
            borderRadius: "50px",
            padding: "6px",
            fontSize: "30px",
          }}
        />
        <Delete
          style={{
            cursor: "pointer",
            backgroundColor: "#dcf5f2",
            color: "#5ecec1",
            borderRadius: "50px",
            padding: "6px",
            fontSize: "30px",
          }}
        />
      </div>
    ),
  },
];

const rows = [
  {
    id: 1,

    Label: "Jon",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 2,

    Label: "Cersei",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 3,

    Label: "Jaime",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 4,
    Label: "Arya",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 5,
    Label: "Daenerys",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 6,
    Label: "Krista",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 7,
    Label: "Ferrara",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 8,
    Label: "Rossini",
    English: "SmartBot",
    French: "Robot intelligent",
  },
  {
    id: 9,
    Label: "Harvey",
    English: "SmartBot",
    French: "Robot intelligent",
  },
];

export default function TranslationTable() {
  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="botCreationTable"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={5}
        checkboxSelection
        style={{ width: "100%" }}
        sx={{ "& .MuiDataGrid-root": { backgroundColor: "#ffffff" } }} // Override background color for DataGrid
      />
    </div>
  );
}
