import * as React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Switch,
} from "@mui/material";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";

export default function CMSOptions() {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <List
      dense
      sx={{ width: "100%", bgcolor: "background.paper" }}
      className="pt-15px listCmsOptions"
    >
      <ListItem
        key={0}
        secondaryAction={
          //   <Checkbox
          //     edge="end"
          //     onChange={handleToggle(0)}
          //     checked={checked.indexOf(0) !== -1}
          //     inputProps={{
          //       "aria-labelledby": "checkbox-list-secondary-label-0",
          //     }}
          //   />
          <Switch {...label} defaultChecked />
        }
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon className="ListIcon">
            <CloudUploadOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            id="checkbox-list-secondary-label-0"
            primary="Upload File"
          />
        </ListItemButton>
      </ListItem>
      <ListItem key={1} secondaryAction={<Switch {...label} />} disablePadding>
        <ListItemButton>
          <ListItemIcon className="ListIcon">
            <LanOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            id="checkbox-list-secondary-label-1"
            primary="Connect Data"
          />
        </ListItemButton>
      </ListItem>
      <ListItem key={2} secondaryAction={<Switch {...label} />} disablePadding>
        <ListItemButton>
          <ListItemIcon className="ListIcon">
            <HowToRegOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            id="checkbox-list-secondary-label-2"
            primary="Registration"
          />
        </ListItemButton>
      </ListItem>
      <ListItem key={3} secondaryAction={<Switch {...label} />} disablePadding>
        <ListItemButton>
          <ListItemIcon className="ListIcon">
            <PollOutlinedIcon />
          </ListItemIcon>
          <ListItemText id="checkbox-list-secondary-label-3" primary="Survey" />
        </ListItemButton>
      </ListItem>
      <ListItem key={4} secondaryAction={<Switch {...label} />} disablePadding>
        <ListItemButton>
          <ListItemIcon className="ListIcon">
            <NearMeOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            id="checkbox-list-secondary-label-4"
            primary="Near Me"
          />
        </ListItemButton>
      </ListItem>
      <div className="d-flex justify-content-end align-items-center mt-15px mb-10px mr-15px">
        <Button
          variant="outlined"
          className="outline-button mr-10px"
          type="submit"
        >
          Add More
        </Button>
        <Button variant="contained" className="blue-greenBtn" type="submit">
          Submit
        </Button>
      </div>
    </List>
  );
}
