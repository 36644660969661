import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PreviewPageTable from "./PreviewPageTables/previewpagetable";
import imgUrl from "../../img/imgUrl";
const BotList = () => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Vital Assistance Details
      </AccordionSummary>
      <AccordionDetails className="pt-0px">
        <Grid
          container
          spacing={2}
          className="d-flex justify-content-center align-items-center"
        >
          <Grid
            item
            xs={12}
            lg={7}
            className="d-flex justify-content-center align-items-center flex-direction-column pt-0px"
          >
            <PreviewPageTable />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            className="d-flex justify-content-center align-items-center flex-direction-column"
          >
            <img src={imgUrl.previewDetails} className="w-100"></img>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default BotList;
