import React from "react";
import BotList from "./botDetails";
import VerifyNumberSection from "./verifyNumber";

const Preview = () => {

    return (
        <>
            <BotList />
            <VerifyNumberSection />
        </>
    )
}

export default Preview