import { Navigate, Route, Routes } from 'react-router-dom';

import Home from '../pages/home';
import Signup from '../pages/signup';
import React from 'react';
import Registration from '../pages/registration';

export const preRoutes = [
  { path: '*', element: <Navigate to="/" /> },
  { path: '/', element: <Home /> },
  { path: '/signup', element: <Signup /> },

  { path: '/home', element: <Home /> },
];
