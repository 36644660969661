import { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Grid } from "@mui/material";
  import Swal from "sweetalert2";
  import { post } from "../../apiServices/api";
  import { useSelector } from "react-redux";

  import AddEditRegQuestion from "./AddEditRegQuestion";
  import RegistrationTable from "./RegistrationTable";
  import { defaultFields } from "./Constant";
  import { postDjango } from "../../apiServices/api";

const Registration = () => {
    const initVal = {
        questionName: "",
        questionType: "",
        options: [],
        mandatory: false,
        parentQuestion: null,
        dependentValues: [],
    }
    const [allQuestion, setAllQuestion] = useState([])
    const [formikInitialValues, setFormikInitialValues] = useState(initVal)
    const [formEditState, setFormEditState] = useState(false)
    const allBotList = useSelector((state) => state?.bot?.allBotDetailList);
    const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);
    const botId = useSelector((state) => state?.bot?.botDetails?.botid);
    
    const filterBotList = allBotList.filter(bots => bots.botid == botId)
    const botType = filterBotList[0].type
    useEffect(() => {
        fetchAllQuestionByBotId()
    },[])

    const convertTextToJson = (payload) => {
        // Extract the text from payload.choices[0].text
        let text = payload.choices[0].text;
      
        // Remove all backslashes (\) and newlines (\n)
        text = text.replace(/\\/g, '').replace(/\n/g, '');
      
        // Convert the cleaned text to JSON
        try {
          const json = JSON.parse(text);
          return json;
        } catch (error) {
          console.error('Error parsing JSON:', error);
          return null;
        }
      };

      const addPredefinedFields = async () => {
        let finalPaylaod = [];
        console.log("defaultFields", defaultFields);
    
        // Populate finalPaylaod with default fields
        defaultFields.forEach((field) => {
            field.data.bot_id = botId.toString();
            field.data.user_id = userId.toString();
            finalPaylaod = [...finalPaylaod, field];
            console.log("field>>", field);
        });
    
        // Get AI suggestions and add to finalPaylaod
        const getAiSuggestions = await postDjango('vitalaisuggestion/', {
            "question_type": "user registration",
            "bot_type": botType,
            "existing_fields": "age, gender and location",
            "number_of_question": "2"
        });
        const suggestionJson = convertTextToJson(getAiSuggestions.payload);
    
        console.log("getAiSuggestions", suggestionJson);
    
        suggestionJson.forEach((SuggestedField) => {
            console.log("SuggestedField", SuggestedField);
            let paylaod = {
                data: {
                    question_name: SuggestedField.label,
                    language_id: "1",
                    bot_id: botId.toString(),
                    user_id: userId.toString(),
                    mandatory: true,
                    question_type: SuggestedField.type,
                }
            };
    
            if (SuggestedField.type === "Checkbox" || SuggestedField.type === "Radio") {
                const options = SuggestedField.options.map((option) => {
                    return { "options_names": option };
                });
                paylaod.data.question_option = options;
            }
            finalPaylaod = [...finalPaylaod, paylaod];
        });
    
        // Make API calls for each payload and wait for all to finish
        try {
            const responses = await Promise.all(
                finalPaylaod.map(async (paylaod) => {
                    let payloadJson = {
                        url: `/registrations`,
                        payload: paylaod
                    };
                    return await post(`/cms/postCms`, payloadJson);
                })
            );
    
            // Once all API calls are done, fetch all questions
            if (responses) {
                fetchAllQuestionByBotId();
            }
        } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    };
    

    const fetchAllQuestionByBotId = async () => {
        
        try {
            const response = await post('cms/getCms',{url:`/registrations?populate=*&filters[bot_id][$eq]=${botId}`});
            if (response.data) {
                console.log(response.data)
                if(response.data.length == 0) {
                    addPredefinedFields()
                }
                setAllQuestion(response.data)
                setFormikInitialValues(initVal)
                setFormEditState(false)
            }
        } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    }

    return (
        <>
            <Accordion defaultExpanded>
                <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="survey-content"
                    id="survey-header"
                >
                    Registration Questions
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <AddEditRegQuestion 
                            allQuestion={allQuestion}
                            fetchAllQuestionByBotId={fetchAllQuestionByBotId}
                            formikInitialValues={formikInitialValues}
                            formEditState={formEditState}
                            setFormEditState={setFormEditState}
                        />
                        <RegistrationTable 
                            allQuestions={allQuestion} 
                            fetchAllQuestionByBotId={fetchAllQuestionByBotId}
                            setFormikInitialValues={setFormikInitialValues}
                            setFormEditState={setFormEditState}
                        />
                    </Grid>
                </AccordionDetails>

            </Accordion>
        </>
    )
}

export default Registration