import React, { useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  InputAdornment,
  MenuItem,
  CardContent,
  Grid,
  Card,
  styled,
  TextField,
  Switch,
  FormControlLabel,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useFormik, FieldArray, FormikProvider, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { post } from "../../apiServices/api";

// icons import
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5",
  },
}));

const validationSchema = Yup.object().shape({
  questionName: Yup.string().required("Question Name is required"),
});

const AddEditRegQuestion = ({
  allQuestion,
  fetchAllQuestionByBotId,
  formikInitialValues,
  formEditState,
  setFormEditState,
}) => {
  const [surveyEditValueLang, setSurveyEditValueLang] = React.useState(0);
  const [parentQuestionList, setParentQuestionList] = React.useState([]);
  const [dependentOptionList, setDependentOptionList] = React.useState([]);

  const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);
  const botId = useSelector((state) => state?.bot?.botDetails?.botid);

  const formik = useFormik({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      submitSurveyQuestion(values);
    },
  });

  useEffect(() => {
    transformParentQuestionList(allQuestion);
  }, [allQuestion]);

  useEffect(() => {
    if (formik.values.parentQuestion != null) {
      transformDependentOptionList(
        allQuestion,
        formik.values.parentQuestion.id
      );
    }
  }, [formik.values.parentQuestion]);

  const transformOptions = (options) => {
    return options.map((option) => {
      return {
        options_names: option,
      };
    });
  };

  const transformDependentOption = (options) => {
    return options.map((option) => {
      return {
        dependent_value: option.label,
      };
    });
  };

  const transformParentQuestionList = (allQuestion) => {
    const transformedData = allQuestion.map((item) => ({
      label: item.attributes.question_name,
      id: item.id,
    }));

    setParentQuestionList(transformedData);
  };

  const transformDependentOptionList = (data, targetId) => {
    const filteredItem = data.find((item) => item.id === targetId);

    // If the item with the given ID is found
    if (filteredItem) {
      // Extract the question_option array
      const options = filteredItem.attributes.question_option;

      // Transform each option
      const transformedOptions = options.map((option) => ({
        label: option.options_names,
        id: option.id,
      }));

      setDependentOptionList(transformedOptions);
    }
  };

  const submitSurveyQuestion = async (values) => {
    // console.log("values", values);
    let payload = {
      data: {
        question_name: values?.questionName,
        user_id: userId.toString(),
        bot_id: botId.toString(),
        mandatory: values?.mandatory,
      },
    };
    if (values?.questionType != "") {
      payload.data.question_type = values?.questionType;
    }
    if (values?.options.length > 0) {
      payload.data.question_option = transformOptions(values?.options);
    }
    if (values?.parentQuestion != null) {
      payload.data.map_parent = values?.parentQuestion?.id;
    }
    if (values?.dependentValues.length > 0) {
      payload.data.dependent_option_value = transformDependentOption(
        values?.dependentValues
      );
    }

    // console.log("payload", payload, values);
    try {
      let response;
      let payloadJson = {
        url: formEditState ? `/registrations/${values?.id}` : `/registrations`,
        payload: payload
      }
      formEditState
        ? (response = await post(`/cms/putCms`, payloadJson))
        : (response = await post(`/cms/postCms`, payloadJson));

      if (response) {
        formik.resetForm();
        fetchAllQuestionByBotId();
        // setConnectData(response.data)
        // setConnectDataTransformed(transformedData)
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  return (
    <>
      <Grid item xs={12} lg={5}>
        <Card className="formDiv" style={{ minHeight: "100%" }}>
          <CardContent style={{ overflow: "hidden" }}>
            <div className="modalLangTabs">
              <Typography className="formLabel mt-10px">Question</Typography>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    margin: "20px 0px 30px 0px",
                  }}
                >
                  <Tabs
                    value={surveyEditValueLang}
                    aria-label="basic tabs example"
                    className="subTabsLangModal d-flex justify-content-between"
                  >
                    <Tab label="English(US)" {...a11yProps(0)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={surveyEditValueLang} index={0}>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      {/* Question Name */}
                      <CustomTextField
                        id="surveyQuestionName"
                        name="questionName"
                        label="Question Name"
                        placeholder="Enter Question Name"
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                        value={formik.values.questionName}
                        mb={2}
                        className="mt-10px"
                        error={
                          formik.touched.questionName &&
                          Boolean(formik.errors.questionName)
                        }
                        helperText={
                          formik.touched.questionName &&
                          formik.errors.questionName
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <LockIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                      />

                      {/* Question Type and Options */}
                      <CustomTextField
                        id="outlined-surveyQuestion-type"
                        name="questionType"
                        select
                        label="Question Type"
                        placeholder="Select Question Type"
                        value={formik.values.questionType}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("options", [""]);
                        }}
                        helperText="Please select the Question type"
                        className="mt-30px"
                        mb={2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <LockIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem value="Checkbox">Checkbox</MenuItem>
                        <MenuItem value="Radio">Radio</MenuItem>
                        <MenuItem value="Text">Text</MenuItem>
                      </CustomTextField>
                      {(formik.values.questionType === "Checkbox" ||
                        formik.values.questionType === "Radio") && (
                        <FieldArray
                          name="options"
                          render={(arrayHelpers) => (
                            <Box style={{ marginTop: "7px" }}>
                              {" "}
                              {formik.values.options.map((option, index) => (
                                <div key={index}>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="d-flex justify-content-start align-items-center"
                                  >
                                    <Grid item xs={12} lg={10}>
                                      <CustomTextField
                                        id={`option-${index}`}
                                        name={`options.${index}`}
                                        label={`Option ${index + 1}`}
                                        placeholder={`Enter Option ${index +
                                          1}`}
                                        variant="outlined"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={option}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {/* <LockIcon /> */}
                                            </InputAdornment>
                                          ),
                                        }}
                                        mb={2}
                                        className="mt-20px"
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={2}>
                                      <div className="d-flex justify-content-start align-items-center mt-10px">
                                        <Button
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#5ecec1",
                                            backgroundColor: "#dcf5f2",
                                            borderRadius: "50px",
                                            width: "30px",
                                            height: "30px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ fontSize: "20px" }}
                                          ></DeleteIcon>
                                        </Button>
                                        <Button
                                          onClick={() => arrayHelpers.push("")}
                                          className="ml-5px"
                                          style={{
                                            cursor: "pointer",
                                            color: "#5ecec1",
                                            backgroundColor: "#dcf5f2",
                                            borderRadius: "50px",
                                            width: "30px",
                                            height: "30px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                          }}
                                        >
                                          <AddIcon
                                            style={{ fontSize: "20px" }}
                                          ></AddIcon>
                                        </Button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {/* <Button type="button" variant="outlined">
                                    Remove
                                  </Button> */}
                                </div>
                              ))}
                              {/* <Button type="button" variant="outlined">
                                Add Option
                              </Button> */}
                            </Box>
                          )}
                        />
                      )}

                      {/* Parent Question */}
                      <Autocomplete
                        id="parentQuestion"
                        options={parentQuestionList}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) =>
                          formik.setFieldValue("parentQuestion", value)
                        }
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            label="Parent Question"
                            placeholder="Select Parent Question"
                            variant="outlined"
                            fullWidth
                            className="mt-30px"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                borderColor: params.focused
                                  ? "#1976d2"
                                  : params.error
                                  ? "#f44336"
                                  : "#e0e0e0",
                              },
                            }}
                            mb={2}
                          />
                        )}
                        value={formik.values.parentQuestion}
                      />

                      {/* Dependent Values */}
                      <Autocomplete
                        id="dependentValues"
                        multiple
                        options={dependentOptionList}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value) =>
                          formik.setFieldValue("dependentValues", value)
                        }
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            label="Dependent Values"
                            placeholder="Select Dependent Values"
                            variant="outlined"
                            fullWidth
                            className="mt-30px"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                borderColor: params.focused
                                  ? "#1976d2"
                                  : params.error
                                  ? "#f44336"
                                  : "#e0e0e0",
                              },
                            }}
                            mb={2}
                          />
                        )}
                        value={formik.values.dependentValues}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option.label}
                              {...getTagProps({ index })}
                              className="mt-2"
                            />
                          ))
                        }
                      />

                      {/* Mandatory Switch */}
                      <FormControlLabel
                        control={
                          <Switch
                            name="mandatory"
                            checked={formik.values.mandatory}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Mandatory"
                        className="mt-10px"
                      />

                      <div className="d-flex justify-content-end align-items-center mt-30px">
                        <Button
                          variant="contained"
                          className="secondaryButton"
                          style={{ marginRight: "10px" }}
                          type="reset"
                          onClick={formik.handleReset}
                        >
                          Cancel
                        </Button>
                        <Button
                          autoFocus
                          className="blue-greenBtn"
                          type="submit"
                        >
                          {!formEditState ? "Add" : "Edit"}
                        </Button>
                      </div>
                    </Form>
                  </FormikProvider>
                </CustomTabPanel>
              </Box>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default AddEditRegQuestion;
