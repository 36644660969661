import React from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
} from "@mui/material";
import styled from "@emotion/styled/macro";

// Custom textfield UI
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

function APITab() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <CustomTextField
                name="request_type"
                id="request_type"
                select
                label="Request Type"
                placeholder="Select Request Type"
                defaultValue="1"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <EmailIcon /> */}
                    </InputAdornment>
                  ),
                }}
                mb={2}
              >
                <MenuItem value="1">Post</MenuItem>
                <MenuItem value="2">Get</MenuItem>
              </CustomTextField>
            </Grid>
            <Grid item xs={12} lg={5}>
              <div>
                <CustomTextField
                  name="url"
                  id="url"
                  label="URL"
                  variant="outlined"
                  placeholder="Enter URL"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={5}>
              <div>
                <CustomTextField
                  name="auth_token"
                  id="auth_token"
                  label="Auth Token"
                  variant="outlined"
                  placeholder="Enter Auth Token"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <PersonIcon /> */}
                      </InputAdornment>
                    ),
                  }}
                  mb={2}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mt-10px">
            <Grid item xs={12} lg={12}>
              <CustomTextField
                label=""
                placeholder="Request Body"
                multiline
                rows={2}
                variant="outlined"
                fullWidth
                className=""
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <div className="d-flex justify-content-end">
                <Button className="blue-greenBtn" variant="contained">
                  Connect
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="">
            <Grid item xs={12} lg={12}>
              <CustomTextField
                label=""
                placeholder="Response"
                multiline
                rows={2}
                variant="outlined"
                fullWidth
                className="mt-20px"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default APITab;
