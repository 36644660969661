import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import { post} from "../../apiServices/api";
import Swal from "sweetalert2";
// const rows = [
//   {
//     id: 1,

//     Name: "Jon",
//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "India",
//     State: "Maharashtra",
//   },
//   {
//     id: 2,

//     Name: "Cersei",
//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "California",
//     State: "United States",
//   },
//   {
//     id: 3,

//     Name: "Jaime",

//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "Germany",
//     State: "Bavaria",
//   },
//   {
//     id: 4,

//     Name: "Arya",

//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "Australia",
//     State: "New South",
//   },
//   {
//     id: 5,

//     Name: "Daenerys",

//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "India",
//     State: "Punjab ",
//   },
//   {
//     id: 6,

//     Name: null,

//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "South Africa",
//     State: "Gauteng",
//   },
//   {
//     id: 7,

//     Name: "Ferrara",

//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "India",
//     State: "Gujarat",
//   },
//   {
//     id: 8,

//     Name: "Rossini",

//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "India",
//     State: "Rajasthan",
//   },
//   {
//     id: 9,

//     Name: "Harvey",

//     Type: "png",
//     Latitude: "8.0883° N to 37.0841° N",
//     Longitude: "68.1941° E to 97.3956° E",
//     Country: "India",
//     State: "Tamil Nadu",
//   },
// ];

export default function CmsNearMeFileTable({allNearMes, fetchAllNearMeData,setFormikInitialValues,setFormEditState}) {
  //Coloumns 
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "Name", headerName: "Name", width: 140 },
  
    {
      field: "Type",
      headerName: "Type",
      width: 130,
    },
    {
      field: "Latitude",
      headerName: "Latitude",
      // sortable: false,
      width: 200,
      // valueGetter: (value, row) => `${row.botName || ""} ${row.TypeofBot || ""}`,
    },
    {
      field: "Longitude",
      headerName: "Longitude",
      // sortable: false,
      width: 200,
      // valueGetter: (value, row) => `${row.botName || ""} ${row.TypeofBot || ""}`,
    },
    {
      field: "Country",
      headerName: "Country",
      // sortable: false,
      width: 130,
    },
    {
      field: "State",
      headerName: "State",
      // sortable: false,
      width: 130,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 130,
      renderCell: (params) => (
        <div className="d-flex">
          <Edit
           onClick={
            ()=>{
              handleEditNearMe(params.id)
            }
            }
            style={{
              cursor: "pointer",
              marginRight: 10,
              color: "#5ecec1",
  
              backgroundColor: "#dcf5f2",
              borderRadius: "50px",
              padding: "6px",
              fontSize: "30px",
            }}
          />
          <Delete
            onClick={
            ()=>{
              handleDeleteNearMe(params.id)
            }
            }
            style={{
              cursor: "pointer",
              color: "#5ecec1",
  
              backgroundColor: "#dcf5f2",
              borderRadius: "50px",
              padding: "6px",
              fontSize: "30px",
            }}
          />
        </div>
      ),
    },
  ];
  // console.log(`All Nearmes are`,allNearMes)
  const handleEditNearMe=async(id)=>{
    const nearMeToEdit=allNearMes.filter(nearme=>nearme.id==id)[0]
    // console.log(`Entry to Edit is is `,nearMeToEdit)
    const nearMeDataFormikInitial= {
      name: nearMeToEdit.attributes.name,
      type: nearMeToEdit.attributes.type,
      latitude: nearMeToEdit.attributes.latitude,
      longitude: nearMeToEdit.attributes.longitude,
      country: nearMeToEdit.attributes.country,
      state: nearMeToEdit.attributes.state,
      id:nearMeToEdit.id,
    }
    // console.log('Formik Initial Values to Edit are ',nearMeDataFormikInitial)
    setFormEditState(true)
    setFormikInitialValues(nearMeDataFormikInitial)
    
  }
  const  handleDeleteNearMe=async (id)=>{
    try {
        const response = await post('/cms/deleteCms',{url:`/near-mes/${id}`});
        
        if(response.data!=null){
            //Reload the  all Nearn mes  list 
            fetchAllNearMeData()
            //Notify of deletion
            Swal.fire({
                
                title: "",
                text: "Near Me Deleted Successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
        }
        else {
            //   console.log("else>>>", response);
              Swal.fire("Error", response.message, "error");
            }
    } 
       catch (error) {
        Swal.fire("Error", error.message, "error");
    }
}
  const rows=allNearMes.map((nearmes)=>({
    id:nearmes.id,
    Name:nearmes.attributes.name,
    Type:nearmes.attributes.type,
    Latitude:nearmes.attributes.latitude,
    Longitude:nearmes.attributes.longitude,
    Country:nearmes.attributes.country,
    State:nearmes.attributes.state,
  }))

  return (
    <div
      style={{ width: "100%", marginTop: "20px" }}
      className="botCreationTable"
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          }}}
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={5}
       
        style={{ width: "100%" }}
        sx={{ "& .MuiDataGrid-root": { backgroundColor: "#ffffff" } }} // Override background color for DataGrid
      />
      {/* <div className="d-flex justify-content-end mt-10px">
        <Button variant="contained" className="blue-greenBtn">
          Add
        </Button>
      </div> */}
    </div>
  );
}
