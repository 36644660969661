import React, { Children, createContext, useEffect, useState } from "react";
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [loggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const loginStatus = localStorage.getItem("token") === "true";

    setIsLoggedIn(loginStatus);
  }, []);

  const isLoggedIn = () => {
    const login = localStorage.getItem("token") === "true";
    return login;
  };

  const login = () => {
    // console.log(`Login Called`);
    localStorage.setItem("token", "true");
    setIsLoggedIn(true);
  };
  const logout = () => {
    // console.log(`Login Called`);
    localStorage.setItem("token", "false");
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
