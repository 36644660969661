import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import {
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
  Switch,
  FormControlLabel,
  CardContent,
  Grid,
  Card,
} from "@mui/material";

// icons import
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";

// files import
import CmsUploadFileTable from "../../../components/EmbeddingFileUpload/cmsuploadfiletable";

import CMSSurvey from "./CMSSurvey/cmssurvey";

import CMSRegistrationTab from "../../../components/CMSRegistration/CMSRegistrationTab";
import FileUpload from "../../../components/EmbeddingFileUpload/embeddingFileUpload";
import ConnectData from "../../../components/ConnectData/connectData";
import Survey from "../../../components/Survey/survey";
import CMSNearMe from "../../../components/NearMeCMS/CMSNearMe";
import Registration from "../../../components/Registration/Registration";
import SurveyAccordian from "../../../components/SurveyAccordian/surveyAccordian";

// tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
};

// add information dialog style
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
// custom textfield style
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5", // Change this to your desired focused border color
  },
}));

export default function CMSTabs() {
  // switch
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //  language modal
  const [valueLang, setValueLang] = React.useState(0);
  const handleLangChange = (event, newValue) => {
    setValueLang(newValue);
  };

  // survey edit language modal
  const [surveyEditValueLang, setSurveyEditValueLang] = React.useState(0);
  const handleSurveyEditLangChange = (event, newValue) => {
    setSurveyEditValueLang(newValue);
  };

  // survey add language modal
  const [surveyAddValueLang, setSurveyAddValueLang] = React.useState(0);
  const handleSurveyAddLangChange = (event, newValue) => {
    setSurveyAddValueLang(newValue);
  };

  // drag and drop file
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // console.log(acceptedFiles)
      {
        /*Removed Console No Func to handle*/
      }
    },
  });

  // nearMe drag and drop file
  // modal second tab add information
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // modal second tab edit information
  const [editOpen, setEditOpen] = React.useState(false);
  const handleEditClickOpen = () => {
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };

  // modal second tab nested edit information
  const [editNestedOpen, setNestedEditOpen] = React.useState(false);
  const handleNestedEditClickOpen = () => {
    setEditOpen(true);
  };
  const handleNestedEditClose = () => {
    setEditOpen(false);
  };

  // third tab registration edit click
  // registration add click

  // fourth tab survey edit click
  // modal survey edit click
  // const [openSurveyEdit, setOpenSurveyEdit] = React.useState(false);
  // const handleSurveyEditClickOpen = () => {
  //   setOpenSurveyEdit(true);
  // };
  // const handleSurveyEditClose = () => {
  //   setOpenSurveyEdit(false);
  // };

  // third tab registration add click
  // modal registration add click
  // const [openRegAdd, setOpenRegAdd] = React.useState(false);
  // const handleRegAddClickOpen = () => {
  //   setOpenRegAdd(true);
  // };
  // const handleRegAddClose = () => {
  //   setOpenRegAdd(false);
  // };

  // fourth tab survey add click
  // modal survey add click
  // const [openSurveyAdd, setOpenSurveyAdd] = React.useState(false);
  // const handleSurveyAddClickOpen = () => {
  //   setOpenSurveyAdd(true);
  // };
  // const handleSurveyAddClose = () => {
  //   setOpenSurveyAdd(false);
  // };

  return (
    <Box sx={{ width: "100%" }} className="CmsTabsSection">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="Upload File" {...a11yProps(0)} />
          <Tab label="Registration" {...a11yProps(1)} />
          <Tab label="Information" {...a11yProps(2)} />

          <Tab label="Survey" {...a11yProps(3)} />
          <Tab label="Near Me" {...a11yProps(4)} />
        </Tabs>
      </Box>

      {/* first tab section */}
      <CustomTabPanel value={value} index={0} className="UploadCMSFile">
        <div>
          <FileUpload />
        </div>
        <div></div>
      </CustomTabPanel>
      {/* second tab section */}
      <CustomTabPanel value={value} index={1} className="registrationTabs">
        <Registration />
      </CustomTabPanel>

      {/* third tab section */}
      <CustomTabPanel value={value} index={2}>
        <ConnectData />
      </CustomTabPanel>
      {/* fourth tab section */}
      <CustomTabPanel value={value} index={3} className="registrationTabs">
        {/* <Survey /> */}
        <SurveyAccordian />
      </CustomTabPanel>
      {/* fifth tab section */}
      <CustomTabPanel value={value} index={4} className="NearMeCMSFile">
        <CMSNearMe />
      </CustomTabPanel>
    </Box>
  );
}
