import { Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import { CircularProgress } from "@mui/material";
import Pageloader from "../components/pageloader";
import { Home } from "@mui/icons-material";
import HomePage from "../pages/homepage.js";
const FormikForm = lazy(() => import("../pages/formik.js"));
//

const MobileChat = lazy(() => import("../pages/mobilechat.js"));
const Registration = lazy(() => import("../pages/registration.js"));
export const LazyLoad = ({ Component }) => {
  return (
    <Suspense fallback={<Pageloader />}>
      <Component />
    </Suspense>
  );
};
export const postRoutes = [
  { path: "/", element: <HomePage /> },
  { path: "/formik", element: <LazyLoad Component={FormikForm} /> },
  { path: "/homepage", element: <HomePage /> },
  { path: "/mobilechat", element: <LazyLoad Component={MobileChat} /> },
  {
    path: "/registration",
    element: <LazyLoad Component={Registration} />,
  },
];
