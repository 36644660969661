import { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import styled from "@emotion/styled/macro";
import {
  Box,
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  Switch,
  FormGroup,
  Tooltip,
} from "@mui/material";
import Swal from "sweetalert2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import BotCreationTable from "./botcreationtable"; // Import your BotCreationTable component
import { post } from "../../apiServices/api";
import { useSelector, useDispatch } from "react-redux";
import {
  setAllBotDetailList,
  setBotDetails,
} from "../../redux/features/create_bot/botDetails";
import imgUrl from "../../img/imgUrl";

// const channel_id = [
//   { title: "Whatsapp", value: 1 },
//   { title: "Facebook", value: 2 },
// ];
// const languageid = [
//   { title: "English", value: 2 },
//   { title: "French", value: 3 },
// ];
const countryid = [
  { title: "India", value: 1 },
  { title: "Germany", value: 2 },
];

const defaultInitValue = {
  name: "",
  type: "",
  description: "",
  channel_id: [],
  bot_icon_id: 1,
  mascot_id: "",
  message: "",
  countryid: [],
  languageid: [],
  identifier: "AMRTest",
  accesstoken: "addtoken",
  authorizationkey: "addtoken",
};

const BotCreationForm = ({ setActiveTab }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);
  const [botDetailList, setBotDetailsList] = useState([]);
  const [languageRecv, setLanguages] = useState([]);
  const [channel_idRecv, setChannelID] = useState([]);
  const [editFormData, setEditFormData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(defaultInitValue);
  const languageid = languageRecv.map((language) => ({
    title: language.languages,
    value: language.languageid,
    locale: language.locale,
  }));
  const channel_id = channel_idRecv.map((channel) => ({
    title: channel.name,
    value: channel.channelid,
  }));
  // custom textfield ui
  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4FD1C5", // Change this to your desired focused border color
    },
  }));

  useEffect(() => {
    fetchBotDetailsList();
  }, []);
  useEffect(() => {
    fetchBotLangAndChannelList();
  }, []);

  const fetchBotDetailsList = useCallback(async () => {
    if (userId) {
      try {
        const response = await post(`botconfig/getBotConfiguration`, {
          userId: userId,
        });
        if (response.status === 200) {
          setBotDetailsList(response?.data?.botConfigurationList);

          dispatch(setAllBotDetailList(response?.data?.botConfigurationList));
        } else {
          Swal.fire("Error", response.message, "error");
        }
      } catch (error) {
        Swal.fire("Error", error.message, "error");
      }
    }
  }, [userId]);
  const fetchBotLangAndChannelList = useCallback(async () => {
    try {
      const response = await post(`botconfig/botDropDownList`, {
        userId: userId,
      });
      if (response.status === 200) {
        // setBotDetailsList(response?.data?.botConfigurationList);
        // dispatch(setAllBotDetailList(response?.data?.botConfigurationList));
        setLanguages(response?.data?.languages);
        setChannelID(response?.data?.channel);
      } else {
        Swal.fire("Error", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  }, []);

  const createBot = async (botDetails) => {
    try {
      const response = await post(`botconfig/registerBot`, botDetails);
      if (response.status === 200) {
        fetchBotDetailsList();
        dispatch(setBotDetails({ botid: response?.data?.botConfigurationId }));
        Swal.fire({
          title: "",
          text: "Bot created successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        setActiveTab(1);
      } else {
        // console.log("else");
        Swal.fire("Error", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const handleSubmit = (values) => {
    const updatedValues = {
      ...values,
      ...{ userid: userId },
    };
    // console.log("values", values);
    const transformedData = transformArrayFields(updatedValues);
    // console.log("transformedData", transformedData);
    createBot(transformedData);
  };

  const handleEditSubmit = async (values) => {
    // console.log("handleEditSubmit", values, editFormData);
    let submitValue = {
      botConfigurationDataList: [
        {
          bot_id: editFormData?.botid,
          name: values?.name,
          type: values?.type,
          description: values?.description,
          accesstoken: editFormData?.accesstoken,
          authorizationkey: editFormData?.authorizationkey,
          identifier: values?.identifier,
          channel_id: values?.channel_id[0]?.value,
          bot_icon_id: values?.bot_icon_id,
          mascot_id: values?.mascot_id,
          message: values?.message,
          countryid: values?.countryid[0]?.value,
          languageid: values?.languageid[0]?.value,
          userid: editFormData?.userid,
          bot_config_id: editFormData?.bot_config_id,
        },
      ],
    };
    // console.log("handleEditSubmit>>>", submitValue);

    try {
      const response = await post(`botconfig/registerBot`, submitValue);
      if (response.status === 200) {
        fetchBotDetailsList();
        // dispatch(setBotDetails({"botid": response?.data?.botConfigurationId}))
        Swal.fire({
          title: "",
          text: "Bot updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        setEditMode(false);
        setFormData(defaultInitValue);
      } else {
        // console.log("else");
        Swal.fire("Error", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  function transformArrayFields(inputData) {
    const transformedData = { ...inputData };

    Object.keys(transformedData).forEach((key) => {
      if (
        Array.isArray(transformedData[key]) &&
        transformedData[key][0] &&
        "value" in transformedData[key][0]
      ) {
        transformedData[key] = transformedData[key][0].value;
      }
    });

    return {
      botConfigurationDataList: [transformedData],
    };
  }

  const handleFileChange = (event) => {
    // console.log(event.target.files[0]);
    {
      /*Need to Handle File Change */
    }
  };

  function updateInputWithTitles(input) {
    // // Update channel_id in input
    // const channel = channel_id.find((c) => c.value === input.channel_id);
    // if (channel) {

    //   input.channel_title = channel.title;
    // }

    // // Update languageid in input
    // const language = languageid.find((l) => l.value === input.languageid);
    // if (language) {
    //   input.language_title = language.title;
    // }

    // // Update countryid in input
    // const country = countryid.find((c) => c.value === input.countryid);
    // if (country) {
    //   input.country_title = country.title;
    // }

    // return input;

    const channel = channel_id.find((c) => c.value === input.channel_id);
    const language = languageid.find((l) => l.value === input.languageid);
    const country = countryid.find((c) => c.value === input.countryid);

    // Create a new object with the updated properties
    const updatedInput = {
      ...input,
      channel_title: channel ? channel.title : undefined,
      language_title: language ? language.title : undefined,
      country_title: country ? country.title : undefined,
    };

    return updatedInput;
  }

  const transformData = (data) => {
    let editData = {
      name: data.name,
      type: data.type,
      description: data.description,
      channel_id: channel_id.filter(
        (option) => option.value === data.channel_id
      ),
      bot_icon_id: data.bot_icon_id,
      bot_id: data.botid,
      mascot_id: data.mascot_id.toString(), // Convert to string if needed
      message: data.message,
      countryid: countryid.filter((option) => option.value === data.countryid),
      languageid: languageid.filter(
        (option) => option.value === data.languageid
      ),
      identifier: data.identifier,
      accesstoken: data.accesstoken,
      authorizationkey: data.authorizationkey,
    };
    updateInputWithTitles(data);
    setEditFormData(data);
    setEditMode(true);
    setFormData(editData);
  };

  return (
    <>
      {/* <>{console.log("formData", formData)}</> */}
      <Formik
        key={formData.bot_id || "default-key"}
        initialValues={formData}
        onSubmit={editMode ? handleEditSubmit : handleSubmit}
      >
        {/* {console.log("initialValues", initialValues)} */}
        {({ setFieldValue, values }) => (
          <Form>
            <Box className="container-setTabs botCreationTab" mx="auto">
              <Grid container spacing={2} className="">
                <Grid item xs={12} lg={4} className="mb-10px">
                  <Grid
                    container
                    spacing={1}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Grid item xs={11}>
                      {" "}
                      <Field
                        name="name"
                        as={CustomTextField}
                        id="name"
                        label="Name"
                        variant="outlined"
                        placeholder="Enter Name"
                        fullWidth
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <PersonIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        mb={2}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip
                        title="Enter a unique display name for the chatbot"
                        arrow
                      >
                        <InfoIcon className="toolTipIcon"></InfoIcon>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4} className="mb-10px">
                  <Field
                    name="type"
                    as={CustomTextField}
                    id="type"
                    label="Type"
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Enter Type"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <EmailIcon /> */}
                        </InputAdornment>
                      ),
                    }}
                    mb={2}
                  />
                </Grid>
                <Grid item xs={12} lg={4} className="mb-10px">
                  <Field
                    name="description"
                    as={CustomTextField}
                    id="description"
                    label="Description"
                    placeholder="Enter Description"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <LockIcon /> */}
                        </InputAdornment>
                      ),
                    }}
                    mb={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} className="mt-5px">
                <Grid item xs={12} lg={4} className="mb-10px">
                  <Field
                    name="channel_id"
                    component={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={channel_id}
                        getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        onChange={(event, value) =>
                          setFieldValue("channel_id", value)
                        }
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            id="channel_id"
                            label="Channel Type"
                            variant="outlined"
                            fullWidth
                            placeholder="Select Channel Type"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                borderColor: params.focused
                                  ? "#1976d2"
                                  : params.error
                                  ? "#f44336"
                                  : "#e0e0e0",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={4} className="mb-10px">
                  <div className="d-flex justify-content-between align-items-center uploadField pl-0px">
                    <div className="d-flex w-100">
                      <Field
                        name="bot_icon_id"
                        as={CustomTextField}
                        id="bot_icon_id"
                        label="Bot Icon"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <EmailIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                        mb={2}
                        className="botField"
                      />
                      <input
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          handleFileChange(event);
                          setFieldValue(
                            "botIcon",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <label htmlFor="file-upload">
                        <Button
                          variant="outlined"
                          component="span"
                          className="uploadFileBtn"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload File
                        </Button>
                      </label>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={4} className="mb-10px">
                  <Field
                    name="mascot_id"
                    as={CustomTextField}
                    id="mascot_id"
                    select
                    label="Mascot Selection"
                    placeholder="Select Mascot Selection"
                    defaultValue=""
                    helperText="Please select the mascot"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <EmailIcon /> */}
                        </InputAdornment>
                      ),
                    }}
                    mb={2}
                  >
                    <MenuItem value="1">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="mascot1"
                            control={<Radio />}
                            label={
                              <>
                                <img
                                  src={imgUrl.ojoIcon}
                                  alt="ojo"
                                  style={{
                                    width: 50,
                                    height: 50,
                                    marginRight: 10,
                                  }}
                                />
                                <img
                                  src={imgUrl.simiIcon}
                                  alt="second image"
                                  style={{ width: 50, height: 50 }}
                                />
                              </>
                            }
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </MenuItem>
                    <MenuItem value="2">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="mascot2"
                            control={<Radio />}
                            label={
                              <>
                                <img
                                  src={imgUrl.dhruvIcon}
                                  alt="dhruv"
                                  style={{
                                    width: 50,
                                    height: 50,
                                    marginRight: 10,
                                  }}
                                />
                                <img
                                  src={imgUrl.dhristiIcon}
                                  alt="dhristi"
                                  style={{ width: 50, height: 50 }}
                                />
                              </>
                            }
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </MenuItem>
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mt-5px">
                <Grid item xs={12} lg={4} className="mb-10px">
                  <Field
                    name="message"
                    as={CustomTextField}
                    id="message"
                    label="Prompt Message"
                    placeholder="Enter Prompt Message"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <LockIcon /> */}
                        </InputAdornment>
                      ),
                    }}
                    mb={2}
                  />
                </Grid>
                <Grid item xs={12} lg={4} className="mb-10px">
                  <Field
                    name="countryid"
                    component={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={countryid}
                        getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        value={field.value} // Ensure that the selected value is controlled
                        onChange={(event, value) => {
                          if (value.length > 1) {
                            value = value.slice(1); // Keep only the last selected item
                          }
                          setFieldValue("countryid", value);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            id="countryid"
                            label="Country"
                            variant="outlined"
                            fullWidth
                            placeholder="Select the Country"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                borderColor: params.focused
                                  ? "#1976d2"
                                  : params.error
                                  ? "#f44336"
                                  : "#e0e0e0",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={4} className="mb-10px">
                  <Field
                    name="languageid"
                    component={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={languageid}
                        getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        onChange={(event, value) =>
                          setFieldValue("languageid", value)
                        }
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            id="languageid"
                            label="Language Type"
                            variant="outlined"
                            fullWidth
                            placeholder="Select Language Type"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                borderColor: params.focused
                                  ? "#1976d2"
                                  : params.error
                                  ? "#f44336"
                                  : "#e0e0e0",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mt-5px">
                <Grid item xs={12} lg={4} className="mb-10px">
                  {/* <Field
                    name="reaction_required"
                    as={CustomTextField}
                    id="reaction_required"
                    select
                    label="Reaction Required"
                    placeholder="Select Reaction Required"
                    defaultValue="1"
                   
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                       
                        </InputAdornment>
                      ),
                    }}
                    mb={2}
                  >
                    <MenuItem value="1" disabled className="placeholderItem">
                      <span className="placeholderItem">
                        Please select if reaction are required
                      </span>
                    </MenuItem>
                    <MenuItem value="2">Yes</MenuItem>
                    <MenuItem value="3">No</MenuItem>
                  </Field> */}
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      label="Enable Reaction"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4} className="mb-10px">
                  {/* <Field
                    name="emojis"
                    as={CustomTextField}
                    id="emojis"
                    select
                    label="Emojis"
                    placeholder="Select Emojis Required"
                    defaultValue="1"
                   
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          
                        </InputAdornment>
                      ),
                    }}
                    mb={2}
                  >
                    <MenuItem value="1" disabled className="placeholderItem">
                      <span className="placeholderItem">
                        Please select if emojis are required
                      </span>
                    </MenuItem>
                    <MenuItem value="2">Yes</MenuItem>
                    <MenuItem value="3">No</MenuItem>
                  </Field> */}
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      label="Enable Emojis"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4} className="mb-10px">
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      label="Enable Personalization"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <div className="d-flex justify-content-end mt-10px">
                <Button
                  variant="contained"
                  className="blue-greenBtn"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
              <BotCreationTable
                botDetailList={botDetailList}
                fetchBotDetailsList={fetchBotDetailsList}
                setFormData={(data) => {
                  transformData(data);
                }}
                setActiveTab={setActiveTab}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BotCreationForm;
