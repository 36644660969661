import React, {useState} from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Dialog, styled } from "@mui/material";
import ConnectDataDialog from "./connectDataDialog";

import AddIcon from "@mui/icons-material/Add";
import ConnectDataAccordian from "./connectDataAccordian";
import {connectDataUtils} from "./connectDataUtils";
import { post, postDjango } from "../../apiServices/api";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { InformationData } from "./constant";
import Loader from "../Loader/Loader";
// tabs
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    // p: 4,
};

// add information dialog style
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
// custom textfield style
const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4FD1C5", // Change this to your desired focused border color
    },
}));
export const FunctionContext = React.createContext({
    handleFunction: () => { },
});
const ConnectData = () => {
    
    const [open, setOpen] = React.useState(false);
    const [valueLang, setValueLang] = React.useState(0);
    
    const botId = useSelector((state) => state?.bot?.botDetails?.botid);
    const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);

    const allBotList = useSelector((state) => state?.bot?.allBotDetailList);
    const filterBotList = allBotList.filter(bots => bots.botid == botId)
    const botType = filterBotList[0].type

    const [connectData, setConnectData] = useState([])
    const [headerData, setHeaderData] = useState([])
    const [connectDataTransformed, setConnectDataTransformed] = useState([])
    const [showLoader, setShowLoader] = useState(false);

    const handleLangChange = (event, newValue) => {
        setValueLang(newValue);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        fetchHeaderData()
    }, [])

    React.useEffect(() => {
        if(connectData.length > 0) {
            const filterHeaderData = connectData.filter((data) => data.attributes.is_header == true)
            setHeaderData(filterHeaderData)
        }
        
    }, [connectData])

    const generateAiSurvey = async (botType) => {
        setShowLoader(true)

        const getAiSuggestions = await postDjango('vitalaiinformation/', {
            "question_type": "Informative",
            "bot_type": botType,
            "number_of_question": "3"
        });

        // console.log("AI Suggestions are ",getAiSuggestions)
        const suggestionJson = convertTextToJson(getAiSuggestions.payload);
        // console.log("suggestionJson", suggestionJson)
        const informationJson = transformInformationJson(suggestionJson)
        // console.log("InformationJson", informationJson)

        try {
            
            await processSurveyData(informationJson);
            fetchHeaderData()
            setShowLoader(false)
          } catch (error) {
            console.error('Error processing survey data:', error);
            setShowLoader(false)
          }
    }

    const processSurveyData = async (surveyData) => {
        for (const survey of surveyData) {
            // Extract survey header excluding 'sections'
            const { sections, ...surveyHeader } = survey;
            surveyHeader.bot_id = survey.bot_id; // Ensure bot_id is included
            surveyHeader.user_id = survey.user_id; // Ensure user_id is included
    
            // Log surveyHeader to debug
            console.log('Posting survey header:', surveyHeader);
    
            const surveyResponse = await post('/cms/postCms', {
                url: '/connect-datas',
                payload: { data: surveyHeader }
            });
            console.log("Posting response", surveyResponse);
            const surveyId = surveyResponse?.data?.id;
    
            for (const section of sections) {
                // Extract section header excluding 'questions'
                const { questions, ...sectionHeader } = section;
                sectionHeader.map_parent_topic = surveyId; // Ensure map_parent_topic is included
                sectionHeader.bot_id = section.bot_id; // Ensure bot_id is included
                sectionHeader.user_id = section.user_id; // Ensure user_id is included
    
                // Log sectionHeader to debug
                console.log('Posting section header:', sectionHeader);
    
                const sectionResponse = await post('/cms/postCms', {
                    url: '/connect-datas',
                    payload: { data: sectionHeader }
                });
                const sectionId = sectionResponse?.data?.id;
    
                for (const question of questions) {
                    // Create questionPayload with map_parent_section
                    const questionPayload = {
                        ...question,
                        map_parent_topic: sectionId // Ensure map_parent_section is included
                    };
                    questionPayload.bot_id = question.bot_id; // Ensure bot_id is included
                    questionPayload.user_id = question.user_id; // Ensure user_id is included
    
                    // Log questionPayload to debug
                    console.log('Posting question payload:', questionPayload);
    
                    await post('/cms/postCms', {
                        url: '/connect-datas',
                        payload: { data: questionPayload }
                    });
                }
            }
        }
    };
    

    const transformInformationJson = (data) => {
        console.log("transformInformationJson", data)

        return data.map(info => ({
            title: info['Information Name'],
            summary: info['Summary'],
            is_active: true,
            is_header: true,
            bot_id: botId.toString(),
            user_id: userId.toString(),
            sections: info['Information Sections'].map(section => ({
                title: section['Information Section'],
                summary: section['Information description'],
                is_active: true,
                bot_id: botId.toString(),
                user_id: userId.toString(),
                questions: section['Information Questions'].map(question => ({
                    title: question['Information Question'],
                    summary: question['Information question description'],
                    is_active: true,
                    bot_id: botId.toString(),
                    user_id: userId.toString(),
                }))
            }))
        }));
    }

    const convertTextToJson = (payload) => {
        // Extract the text from payload.choices[0].text
        let text = payload.choices[0].text;
      
        // Remove all backslashes (\) and newlines (\n)
        text = text.replace(/\\/g, '').replace(/\n/g, '');
      
        // Convert the cleaned text to JSON
        try {
          const json = JSON.parse(text);
          return json;
        } catch (error) {
          console.error('Error parsing JSON:', error);
          return null;
        }
      };

    const fetchHeaderData = async () => {
        //&filters[is_header][$eq]=true
        try {
            const response = await post('cms/getCms',{url:`/connect-datas?pagination[limit]=-1&populate=*&filters[bot_id][$eq]=${botId}`});
            if (response.data) {
                if (response.data.length == 0) {
                    generateAiSurvey(botType)
                }

                const transformedData = connectDataUtils.transformData(response.data)
                setConnectData(response.data)
                setConnectDataTransformed(transformedData)
            }
        } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    }

    return (
        <>
        <React.Fragment>
        <div className="d-flex justify-content-end align-items-center modalInfo mb-10px">
            <Button
                variant="outlined"
                className="outline-button"
                onClick={handleClickOpen}
            >
                <AddIcon></AddIcon>
                Add Information
            </Button>
            <ConnectDataDialog 
                open = {open}
                setOpen = {setOpen}
                isHeader = {true}
                fetchHeaderData= {fetchHeaderData}
                isActive={true}
                generateAiSurvey={generateAiSurvey}
            />
        </div>
        </React.Fragment>
        <React.Fragment>
        <FunctionContext.Provider value={{ handleFunction: fetchHeaderData, setShowLoader }}>
                <ConnectDataAccordian 
                    connectDataTransformed={connectDataTransformed}
                    fetchHeaderData= {fetchHeaderData}
                />
            </FunctionContext.Provider>
            
        </React.Fragment>
        <Loader loadingState={showLoader}/>
        </>
        
    )
}

export default ConnectData