import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Button,
  Paper,
  Box,
  Fab,
  Tooltip,
} from "@mui/material";

// icons import
import SendIcon from "@mui/icons-material/Send";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";
import botIcon from "../../img/newimages/botchat.svg";

// files import
import imgUrl from "../../img/imgUrl";

const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const handleToggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  // customtextfield ui
  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4FD1C5", // Change this to your desired focused border color
    },
  }));

  return (
    <Box sx={{ position: "relative", backgroundColor: "#f0f0f0" }}>
      {isChatOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 70,
            right: 20,
            width: 350,
            height: 500,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#4a148c",
            borderRadius: "10px 10px 0 0",
            boxShadow: 3,
            zIndex: 999,
          }}
        >
          <AppBar
            position="static"
            sx={{ backgroundColor: "#4a148c", borderRadius: "10px 10px 0 0" }}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                Vital Assistance
              </Typography>
              <IconButton edge="end" color="inherit" onClick={handleToggleChat}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            sx={{ flex: 1, p: 2, overflowY: "auto", backgroundColor: "#fff" }}
          >
            <Paper
              sx={{
                padding: 2,
                backgroundColor: "#e0f7fa",
                display: "inline-block",
                borderRadius: 2,
              }}
              className="w-100"
            >
              <Typography variant="body1" color="primary">
                Welcome Here 👋!
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: 2,
                backgroundColor: "#e0f7fa",
                display: "inline-block",
                borderRadius: 2,
                marginTop: 1,
              }}
              className="w-100"
            >
              <Typography variant="body1" color="primary">
                Let's get you started below 😊!
              </Typography>
            </Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 1,
              backgroundColor: "#fff",
            }}
          >
            <IconButton>
              <AttachmentIcon />
            </IconButton>
            <CustomTextField
              variant="outlined"
              placeholder="Type your message..."
              fullWidth
              size="small"
              sx={{ marginX: 1 }}
            />
            <IconButton>
              <EmojiEmotionsIcon />
            </IconButton>
            <IconButton color="primary">
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      <Tooltip title="Hey Hi,For any queries chat with us" placement="left">
        <Fab
          color="primary"
          className="botImage"
          aria-label="chat"
          onClick={handleToggleChat}
          sx={{ position: "fixed", bottom: 10, right: 10 }}
        >
          {/* <ChatIcon /> */}
          <img src={botIcon} className="chatBotIcon"></img>
        </Fab>
      </Tooltip>
    </Box>
  );
};
export default ChatBot;
