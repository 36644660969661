import React from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const EmbeddingDetails = ({ embeddingDetails }) => {
  const columnWidths = [50, 100, 180, 120, 100, 150, 100, 180, 180, 120];
  const API_BASE_URL = "http://127.0.0.1:8000";
  const AUTH_TOKEN = "7cddf608e4805303cd1e601ac5d1a5d97a1a4832";

  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Token ${AUTH_TOKEN}`,
    },
  });

  const handleRemove = async (detail) => {
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/deleteuploadedentry/`,
        { id: detail.pk }
      );
      {
        /*Insert Swal Alert Delete Success */
      }
      // Handle success, reset form, etc.
    } catch (error) {
      {
        /*Insert Swal Alert Delete Upload Failed Success */
      }
      // Handle error
    }
  };

  return (
    <div className="embedding-details">
      <Typography variant="h5" gutterBottom component="div">
        Embedding Details
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="embedding details table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#002d67" }}>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[0] }}
              >
                File Name
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[1] }}
              >
                Description
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[2] }}
              >
                Title
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[3] }}
              >
                File Type
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[4] }}
              >
                Job ID
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[5] }}
              >
                Theme
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[6] }}
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[7] }}
              >
                Created At
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[8] }}
              >
                Updated At
              </StyledTableCell>
              <StyledTableCell
                style={{ color: "#fff", width: columnWidths[9] }}
              >
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {embeddingDetails.map((detail, index) => (
              <TableRow key={detail.pk}>
                <StyledTableCell>{detail.fields.file_name}</StyledTableCell>
                <StyledTableCell>{detail.fields.description}</StyledTableCell>
                <StyledTableCell>{detail.fields.title}</StyledTableCell>
                <StyledTableCell>{detail.fields.file_type}</StyledTableCell>
                <StyledTableCell>{detail.fields.job_id}</StyledTableCell>
                <StyledTableCell>{detail.fields.theme}</StyledTableCell>
                <StyledTableCell>{detail.fields.status}</StyledTableCell>
                <StyledTableCell>
                  {new Date(detail.fields.created_at).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell>
                  {new Date(detail.fields.updated_at).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemove(detail)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// Custom styled TableCell component
const StyledTableCell = ({ children, ...rest }) => {
  return (
    <TableCell
      {...rest}
      sx={{
        borderBottom: "1px solid #ddd",
        padding: "12px",
        "&:not(:last-child)": {
          borderRight: "1px solid #ddd",
        },
        ...rest.sx, // Allows for custom styling from props
      }}
    >
      {children}
    </TableCell>
  );
};

export default EmbeddingDetails;
