import React, { useEffect, useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import imgUrl from "../../img/imgUrl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PreviewSecondTable from "./verifyNumberTable";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { post } from "../../apiServices/api";
import Swal from "sweetalert2";

const VerifyNumberSection = () => {
  const [qrUrl, setQrUrl] = useState(null)
  const selectedBotIDPreview = useSelector(
    (state) => state?.bot?.previewBotIDSelected
  );

  useEffect(() => {
    // console.log("Bot ID is ",selectedBotIDPreview)
    if(selectedBotIDPreview != null) {
      fetchQR(selectedBotIDPreview)
    }
  },[selectedBotIDPreview])

  const fetchQR = async (botID) => {
    
    try {
      const response = await post(`botconfig/getQRCodeforBot`, {
        "botId": botID,
        "sandboxId":1
      });
      if (response.status === 200) {
        if(response.data != null) {
          setQrUrl(response.data)
        }
      } else {
        Swal.fire("Error", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
  }
  }

  return (
    <Accordion defaultExpanded className="mt-20px">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        Verify Number
      </AccordionSummary>
      <AccordionDetails className="pt-0px">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={5}
            className="d-flex justify-content-center align-items-center flex-direction-column"
          >
            <img
              src={imgUrl.vitalAI}
              className="w-100"
              style={{ maxWidth: "358px" }}
            ></img>

            <div className="d-flex justify-content-center align-items-center border-QR">
              {/* <img src={imgUrl.qrCode} className="max-width-100"></img> */}
              {/*QR Code With URL For Now Test Value Hi */}
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 64,
                  width: "100%",
                }}
              >
                <QRCode
                  size={100}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrUrl != null ? qrUrl : 'No sandbox mapped'}
                  viewBox={`0 0 100 100`}
                />
              </div>
              {/*Till Here */}
              <div className="">
                Please scan the QR code or tap the bot icon to experience the
                Vital Assistance.
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={7}>
            <PreviewSecondTable fetchQR={fetchQR}/>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default VerifyNumberSection;
