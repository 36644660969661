import React from "react";
import { Card, CardContent, Grid ,Box,Tabs,Tab} from "@mui/material";
import ExcelDataTab from "../../../components/DatabaseManagement/ExcelData/ExcelDataTab";
import PropTypes from "prop-types";
import APITab from "../../../components/DatabaseManagement/APITab/APITab";
import DatabaseSteps from "../../../components/DatabaseManagement/DatabaseSteps/DatabaseSteps";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function DataManagementTabs() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="dataManagementTabSection">
      <Grid container spacing={2}>
        {/* first section tab  */}
        <Grid item xs={12}>
          <Card className="">
            <CardContent>
              <h4 class="dataHeading mt-0px">Connect to datasource</h4>
              <div>
                {/* <DatabaseTabs></DatabaseTabs> */}
                <Box 
    sx={{ width: "100%" }}>
      <Box sx={{}} className="databaseTabs">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="basic tabs example"
        >
          <Tab label="Excel File" {...a11yProps(0)} />
          <Tab  disabled label="Database Table" {...a11yProps(1)} />
          <Tab disabled label="API" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <ExcelDataTab/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="stepperSection">
          <DatabaseSteps></DatabaseSteps>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div className="mt-30px">
          <APITab></APITab>
        </div>
      </CustomTabPanel>
    </Box>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
   
    </div>
  );
}

export default DataManagementTabs;
