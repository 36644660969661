import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Dialog, styled } from "@mui/material";
import ConnectDataDialog from "./connectDataDialog";

import AddIcon from "@mui/icons-material/Add";
import ConnectDataAccordian from "./connectDataAccordian";
import { connectDataUtils } from "./connectDataUtils";
import { post, postDjango } from "../../apiServices/api";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { SampleJson } from "./constant";
import Loader from "../Loader/Loader";
// tabs
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    // p: 4,
};

// add information dialog style
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
// custom textfield style
const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4FD1C5", // Change this to your desired focused border color
    },
}));
export const FunctionContext = React.createContext({
    handleFunction: () => { },
    allQuestion: []
});
const SurveyAccordian = () => {
    const initVal = {
        questionName: "",
        questionType: "",
        options: [],
        mandatory: false,
        aiSuggestions:true,
        section: false,
       
        parentQuestion: null,
        dependentValues: [],
    }

    const [allQuestion, setAllQuestion] = useState([])
    const [formikInitialValues, setFormikInitialValues] = useState(initVal)
    const [formEditState, setFormEditState] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [valueLang, setValueLang] = React.useState(0);

    const botId = useSelector((state) => state?.bot?.botDetails?.botid);
    const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);

    const allBotList = useSelector((state) => state?.bot?.allBotDetailList);
    const filterBotList = allBotList.filter(bots => bots.botid == botId)
    const botType = filterBotList[0].type
    const [aiSurveySwitch,setAISurveySwitch]=useState(true);
    const [connectData, setConnectData] = useState([])
    const [headerData, setHeaderData] = useState([])
    const [connectDataTransformed, setConnectDataTransformed] = useState([])
    const [showLoader, setShowLoader] = useState(false);


    const handleLangChange = (event, newValue) => {
        setValueLang(newValue);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        fetchHeaderData()
    }, [])

    React.useEffect(() => {
        if (connectData.length > 0) {
            const filterHeaderData = connectData.filter((data) => data.attributes.is_header == true)
            setHeaderData(filterHeaderData)
        }

    }, [connectData])



    const generateAiSurvey = async (botType) => {
        setShowLoader(true)
        console.log(SampleJson)
        // Get AI suggestions and add to finalPaylaod
        const getAiSuggestions = await postDjango('vitalaisurvey/', {
            "question_type": "Survey",
            "bot_type": botType,
            "number_of_question": "3"
        });
        console.log("AI Suggestions are ",getAiSuggestions)
        const suggestionJson = convertTextToJson(getAiSuggestions.payload);
        console.log("suggestionJson", suggestionJson)
        const surveyJson = transformSurveyData(suggestionJson)
        console.log("surveyJson", surveyJson)

        try {
            
            await processSurveyData(surveyJson);
            fetchHeaderData()
            setShowLoader(false)
          } catch (error) {
            console.error('Error processing survey data:', error);
            setShowLoader(false)
          }
    }

    const convertTextToJson = (payload) => {
        // Extract the text from payload.choices[0].text
        let text = payload.choices[0].text;
      
        // Remove all backslashes (\) and newlines (\n)
        text = text.replace(/\\/g, '').replace(/\n/g, '');
      
        // Convert the cleaned text to JSON
        try {
          const json = JSON.parse(text);
          return json;
        } catch (error) {
          console.error('Error parsing JSON:', error);
          return null;
        }
      };

    
// Function to process surveys, sections, and questions
const processSurveyData = async (surveyData) => {
    for (const survey of surveyData) {
        // Extract survey header excluding 'sections'
        const { sections, ...surveyHeader } = survey.survey_header;
        
        // Log surveyHeader to debug
        console.log('Posting survey header:', surveyHeader);

        const surveyResponse = await post('/cms/postCms', {
            url: '/surveys',
            payload: { data: surveyHeader }
        });
        console.log("Posting response", surveyResponse)
        const surveyId = surveyResponse?.data?.id;

        for (const section of sections) {
            // Extract section header excluding 'questions'
            const { questions, ...sectionHeader } = section.section_header;
            sectionHeader.map_parent_survey = surveyId; // Ensure map_parent_survey is included
            
            // Log sectionHeader to debug
            console.log('Posting section header:', sectionHeader);

            const sectionResponse = await post('/cms/postCms', {
                url: '/surveys',
                payload: { data: sectionHeader }
            });
            const sectionId = sectionResponse?.data?.id;

            for (const question of section.questions) {
                // Create questionPayload with map_parent_survey
                const questionPayload = {
                    ...question,
                    map_parent_survey: sectionId // Ensure map_parent_survey is included
                };
                
                // Log questionPayload to debug
                console.log('Posting question payload:', questionPayload);

                await post('/cms/postCms', {
                    url: '/surveys',
                    payload: { data: questionPayload }
                });
            }
        }
    }
};
    const transformSurveyData = (data) => {
        return data.map((survey) => ({
          survey_header: {
            question_name: survey["Survey Name"],
            is_section: false,
            is_survey_header: true,
            mandatory: false,

            is_active: true,
            bot_id: botId.toString(),
            user_id: userId.toString(),
            sections: survey["Survey Sections"].map((section, sectionIndex) => ({
              section_header: {
                question_name: section['Survey Section'],
                is_section: true,
                is_survey_header: false,
                mandatory: false,
                is_active: true,
                bot_id: botId.toString(),
                user_id: userId.toString(),
              },
              questions: section["Survey Questions"].map((question, questionIndex) => ({
                question_name: question?.question,
                is_section: false,
                is_survey_header: false,
                mandatory: false,
                is_active: true,
                bot_id: botId.toString(),
                user_id: userId.toString(),
                question_type: question["type"],
                question_option: question["options"]
                  ? question["options"].map((option, optIndex) => ({
                      options_names: `op${optIndex + 1}`
                    }))
                  : []
              }))
            }))
          }
        }));
      };

    const fetchHeaderData = async () => {
        //&filters[is_header][$eq]=true
        try {
            const response = await post('cms/getCms', { url: `/surveys?pagination[limit]=-1&populate=*&filters[bot_id][$eq]=${botId}` });
            if (response.data) {
                if (response.data.length == 0) {
                    generateAiSurvey(botType)
                }
                const transformedData = connectDataUtils.transformData(response.data)
                setConnectData(response.data)
                setConnectDataTransformed(transformedData)

                setAllQuestion(response.data)
                setFormikInitialValues(initVal)
                setFormEditState(false)
            }
        } catch (error) {
            Swal.fire("Error", error.message, "error");
        }
    }

    return (
        <>
       
            <React.Fragment>
                <div className="d-flex justify-content-end align-items-center modalInfo mb-10px">
                    <Button
                        variant="outlined"
                        className="outline-button"
                        onClick={handleClickOpen}
                    >
                        <AddIcon></AddIcon>
                        Add Survey
                    </Button>
                    <ConnectDataDialog
                        
                        open={open}
                        setOpen={setOpen}
                        aiSurveySwitch={aiSurveySwitch}
                        setAISurveySwitch={setAISurveySwitch}
                        isHeader={true}
                        fetchHeaderData={fetchHeaderData}
                        isActive={true}
                        allQuestion={allQuestion}
                        formikInitialValues={formikInitialValues}
                        generateAiSurvey={generateAiSurvey}
                    />
                </div>
            </React.Fragment>
            <React.Fragment>
                <FunctionContext.Provider value={{ handleFunction: fetchHeaderData, allQuestion, setShowLoader }}>
                    <ConnectDataAccordian
                        connectDataTransformed={connectDataTransformed}
                        fetchHeaderData={fetchHeaderData}
                        formikInitialValues={formikInitialValues}
                        allQuestion={allQuestion}
                    />
                </FunctionContext.Provider>

            </React.Fragment>
            <Loader loadingState={showLoader}/>
        </>
        
    )
}

export default SurveyAccordian