import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useContext } from "react";
import {
  Tabs,
  Tab,
  Box,
  Button,
  InputAdornment,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Switch,
  FormControlLabel,
  IconButton as MuiIconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

import {post, postFormData} from "../../apiServices/api";

// icons import
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FunctionContext } from "./connectData";
import Loader from "../Loader/Loader";
// Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Dialog styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// Custom TextField styles
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4FD1C5",
  },
}));

const ConnectDataDialog = ({
  open,
  setOpen,
  isHeader = false,
  parentId,
  fetchHeaderData,
  editData = [],
  isActive,
  setItemData,
  generateAiSurvey
}) => {
  const [valueLang, setValueLang] = useState(0);
  const [iSHeader, setIsHeader] = useState(isHeader);
  const [initialValues, setInitialValues] = useState({
    title: "",
    aiSuggestions: iSHeader ? true: false,
    summary: "",
    mediaType: "",
    files: [],
  });
  const handleFunction = useContext(FunctionContext);
  
  const [editParentId, setEditParentId] = useState("");
  const [acceptTypes, setAcceptTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const userId = useSelector((state) => state?.user?.userDetails?.user?.userid);
  const botId = useSelector((state) => state?.bot?.botDetails?.botid);

  const MAX_FILES = 20;
  const MAX_FILE_SIZE_MB = 50;

  // Formik setup
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
    }),
    onSubmit: (values) => {
      addConnectDataHeader(values);
      handleClose();
    },
  });

  useEffect(() => {
    console.log("editData", editData)
    if (editData.objectForFormik != undefined) {
      
      setIsHeader(editData?.item?.attributes?.is_header);
      setInitialValues(editData.objectForFormik);
      setEditParentId(editData?.item?.attributes?.map_parent_topic?.data?.id);
    }
    
    if(editData.length == 0) {
      setInitialValues({
        title: "",
        aiSuggestions: iSHeader ? true : false,
        summary: "",
        mediaType: "",
        files: [],
      })
      setEditParentId("")
      setIsHeader(isHeader)
    }
  }, [editData.objectForFormik]);

  useEffect(() => {
    if (formik.values.mediaType != "") {
      if (formik.values.mediaType == "Photos") {
        setAcceptTypes([
          "image/jpeg", // JPEG images
          "image/png", // PNG images
          "image/gif", // GIF images
          "image/bmp", // BMP images
          "image/webp", // WebP images
          "image/tiff", // TIFF images
          "image/svg+xml", // SVG images
        ]);
      }

      if (formik.values.mediaType == "Videos") {
        setAcceptTypes([
          "video/mp4", // MP4 videos
          "video/quicktime", // MOV videos
          "video/x-msvideo", // AVI videos
          "video/x-ms-wmv", // WMV videos
          "video/webm", // WebM videos
          "video/mpeg", // MPEG videos
          "video/ogg", // OGG videos
          "video/3gpp", // 3GP videos
          "video/x-flv", // FLV videos
          "video/x-matroska", // MKV videos
        ]);
      }

      if (formik.values.mediaType == "Pdf") {
        setAcceptTypes("application/pdf");
      }
    }
  }, [formik.values.mediaType]);

  const handleLangChange = (event, newValue) => {
    setValueLang(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const addConnectDataHeader = async (values) => {
    if(values?.aiSuggestions) {
      generateAiSurvey(values?.title)
      handleClose()
      return
    }
    setShowLoader(true)
    let payload = {
      data: {
        title: values?.title,
        summary: values?.summary,
        user_id: userId.toString(),
        bot_id: botId.toString(),
        is_active: true
      },
    };

    if (values?.mediaType) {
      payload = {
        ...payload,
        data: {
          ...payload.data,
          media_type: values?.mediaType,
        },
      };
    }

    if (!iSHeader) {
      payload = {
        ...payload,
        data: {
          ...payload.data,
          map_parent_topic: parentId == "" ? editParentId : parentId,
        },
      };
    } else {
      payload = {
        ...payload,
        data: {
          ...payload.data,
          is_header: true,
        },
      };
    }

    if (values?.files.length > 0) {
      let uplaodFiles = [];
      let files = values?.files;
      try {
        for (const file of files) {
          if (file?.path != undefined) {
            console.log("file", file)
            const formData = new FormData();
            formData.append("files", file); // Check if 'files' is the correct key
            formData.append("url", `/upload`)
            const jsonPayload = {
              url: `/upload`,
              files: formData
            }
            let response = await postFormData(`/cms/postFileCms`, formData);

            if (response) {
              uplaodFiles.push(response[0].id);
            }
          } else {
            const fileContent = JSON.stringify(file, null, 2);
            const fileBlob = new Blob([fileContent], {
              type: file.type || "application/octet-stream",
            });
            const newFile = new File([fileBlob], file.name, {
              type: file.type || "application/octet-stream",
            });
            const formData = new FormData();
            formData.append("files", newFile);
            formData.append("url", `/upload`)
            const jsonPayload = {
              url: `/upload`,
              files: formData
            }
            let response = await postFormData(`/cms/postFileCms`, formData);

            if (response) {
              uplaodFiles.push(response[0].id);
            }
          }
        }
      } catch (error) {
        Swal.fire("Error", error.message, "error");
      }

      if (editData.item != undefined) {
        payload = {
          ...payload,
          data: {
            ...payload.data,
            upload_media:
              uplaodFiles.length > 0
                ? uplaodFiles
                : editData?.item?.upload_media,
          },
        };
      } else {
        payload = {
          ...payload,
          data: {
            ...payload.data,
            upload_media: uplaodFiles,
          },
        };
      }
    }

    try {
      let response;
      let payloadJson = {
        url: editData.item != undefined ? `/connect-datas/${editData.item.id}` : `/connect-datas`,
        payload: payload
      }

      editData.item != undefined
        ? (response = await post(`/cms/putCms`, payloadJson))
        : (response = await post(`/cms/postCms`, payloadJson));

      if (response) {
        fetchHeaderData();
        setShowLoader(false)
        
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
      setShowLoader(false)
    }
  };

  const onDrop = (acceptedFiles) => {
    const totalFiles = formik.values.files.length + acceptedFiles.length;

    if (totalFiles > MAX_FILES) {
      // Swal.fire(
      //     "Warning",
      //     `Cannot upload more than ${MAX_FILES} files.`,
      //     "warning"
      // );
      return;
    }

    const duplicateFiles = acceptedFiles.filter((newFile) =>
      formik.values.files.some(
        (existingFile) => existingFile.name === newFile.name
      )
    );

    if (duplicateFiles.length > 0) {
      const duplicateFileNames = duplicateFiles
        .map((file) => file.name)
        .join(", ");
      // Swal.fire(
      //     "Warning",
      //     `The following file(s) are duplicates and were not added: ${duplicateFileNames}`,
      //     "warning"
      // );
      return;
    }

    const validFiles = acceptedFiles.filter((file) => {
      const validTypes = acceptTypes;
      const fileType = file.type;

      if (!validTypes.includes(fileType)) {
        // Swal.fire(
        //     "Warning",
        //     `File "${file.name}" is not a valid type and was not added.`,
        //     "warning"
        // );
        return false;
      }

      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        // Swal.fire(
        //     "Warning",
        //     `File "${file.name}" exceeds the size limit of ${MAX_FILE_SIZE_MB}MB and was not added.`,
        //     "warning"
        // );
        return false;
      }

      return true;
    });

    formik.setFieldValue("files", [...formik.values.files, ...validFiles]);
  };

  // Dropzone setup
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: MAX_FILES,
  });

  // Remove file handler
  const removeFile = (fileName) => {
    const filteredFiles = formik.values.files.filter(
      (file) => file.name !== fileName
    );
    formik.setFieldValue("files", filteredFiles);
  };

  return (
    <>
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Add Information
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="p-0">
        <div className="modalLangTabs">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                margin: "20px 20px 10px 20px",
              }}
            >
              <Tabs
                value={valueLang}
                onChange={handleLangChange}
                aria-label="basic tabs example"
                className="subTabsLangModal d-flex justify-content-between"
              >
                <Tab label="English(US)" {...a11yProps(0)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueLang} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <CustomTextField
                  id="title"
                  name="title"
                  label="Title"
                  placeholder="Enter Title"
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  mb={2}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
                {iSHeader && <FormControlLabel
                    control={
                        <Switch
                          name="aiSuggestions"
                          checked={formik.values.aiSuggestions}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Use AI Assistance"
                      className="mt-10px"
                    />}
                {!formik?.values?.aiSuggestions && <CustomTextField
                  id="summary"
                  name="summary"
                  label="Summary"
                  placeholder="Enter Summary"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  mb={2}
                  className="mt-20px"
                  value={formik.values.summary}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.summary && Boolean(formik.errors.summary)
                  }
                  helperText={formik.touched.summary && formik.errors.summary}
                />}
                {!formik?.values?.aiSuggestions && <CustomTextField
                  id="mediaType"
                  name="mediaType"
                  select
                  label="Media Type"
                  placeholder="Select Media Type"
                  helperText="Please select the media type"
                  className="mt-20px"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  mb={2}
                  value={formik.values.mediaType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.mediaType && Boolean(formik.errors.mediaType)
                  }
                  helperText={
                    formik.touched.mediaType && formik.errors.mediaType
                  }
                >
                  <MenuItem value="Photos">Photos</MenuItem>
                  <MenuItem value="Videos">Videos</MenuItem>
                  <MenuItem value="Pdf">Pdf</MenuItem>
                </CustomTextField>}
                {formik.values.mediaType != "" && formik.values.mediaType != null && (
                  <div className="dropzone mt-20px" {...getRootProps()}>
                    <Box
                      {...getRootProps()}
                      sx={{
                        border: "2px dashed grey",
                        padding: "13px 20px 20px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      className="dragSection mediaUploadSection mb-10px"
                    >
                      <input {...getInputProps()} />
                      <CloudUploadIcon
                        sx={{
                          fontSize: 50,
                          color: "grey",
                        }}
                      />
                      <Typography variant="h6" className="typographyDiv">
                        Drag 'n' drop some files here, or click to select files
                      </Typography>
                    </Box>
                  </div>
                )}
                <List className="mediaListSection">
                  {(formik?.values?.files || []).map((file) => (
                    <ListItem key={file.name}>
                      <ListItemText primary={file.name} className="fileName" />
                      <ListItemSecondaryAction className="pl-0px">
                        <MuiIconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeFile(file.name)}
                          className="iconButton"
                        >
                          <DeleteIcon />
                        </MuiIconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <DialogActions className="pr-0px">
                  <Button
                    variant="contained"
                    className="secondaryButton"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    autoFocus
                    type="submit"
                    className="blue-greenBtn"
                    onClick={formik.handleSubmit}
                  >
                    Add
                  </Button>
                </DialogActions>
              </form>
            </CustomTabPanel>
          </Box>
        </div>
      </DialogContent>
    </BootstrapDialog>
    <Loader loadingState={showLoader} />
    </>
  );
};

export default ConnectDataDialog;
